export function replaceSpecialChars(str) {
  return str?.toLowerCase()?.replace(/[^A-Z0-9]+/gi, '-')
}

export function replaceSpecialCharsForCategoryUrl(str) {
  return str
    ?.toLowerCase()
    ?.replace('&', 'and')
    .replace(/[^A-Z0-9]+/gi, '-')
}

export function replaceSpecialCharsForProductName(productName) {
  return encodeURIComponent(productName?.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-'))
}
