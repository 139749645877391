export default function DoubleArrowLeft(props) {
  return (
    <svg
      width='18.87'
      height='16.881'
      viewBox='0 0 18.87 16.881'
      fill='var(--primary-color)'
      {...props}
    >
      <g transform='translate(18.87 16.881) rotate(180)'>
        <path
          d='M6.928,8.44.438,2.543A1.435,1.435,0,0,1,0,1.491,1.435,1.435,0,0,1,.438.438,1.435,1.435,0,0,1,1.491,0,1.435,1.435,0,0,1,2.543.438L10.107,7.3a1.4,1.4,0,0,1,.329.526,1.492,1.492,0,0,1,.088.614,1.492,1.492,0,0,1-.088.614,1.4,1.4,0,0,1-.329.526L2.543,16.442a1.435,1.435,0,0,1-1.052.438,1.435,1.435,0,0,1-1.052-.438,1.482,1.482,0,0,1,0-2.1Z'
          transform='translate(8.343)'
        />
        <path
          d='M6.928,8.44.438,2.543A1.435,1.435,0,0,1,0,1.491,1.435,1.435,0,0,1,.438.438,1.435,1.435,0,0,1,1.491,0,1.435,1.435,0,0,1,2.543.438L10.107,7.3a1.4,1.4,0,0,1,.329.526,1.492,1.492,0,0,1,.088.614,1.492,1.492,0,0,1-.088.614,1.4,1.4,0,0,1-.329.526L2.543,16.442a1.435,1.435,0,0,1-1.052.438,1.435,1.435,0,0,1-1.052-.438,1.482,1.482,0,0,1,0-2.1Z'
          transform='translate(0)'
        />
      </g>
    </svg>
  )
}
