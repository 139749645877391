export default function ArrowBlackUp(props) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='-4 -8 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props} 
    >
      <path d='M4 0L7.4641 4.5H0.535898L4 0Z' fill='#505050' />
    </svg>
  )
}
