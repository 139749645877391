import { getDataLayerScript } from '../../api-client/gtm/get-data-layer-script'
import { useEffect } from 'react'

const DataLayerScript = () => {
  useEffect(() => {
    appendGtmScript()
  }, [])

  async function appendGtmScript() {
    const existingScript = document.getElementById('gtmScript')
    if (!existingScript) {
      const scriptString = await getDataLayerScript()

      if (scriptString) {
        const scriptElement = document.createElement('script')
        scriptElement.id = 'gtmScript'

        const formattedScriptTag = scriptString
          .match(/<script>[\s\S]*?<\/script>/g)
          .map((script) => script.replace(/<\/?script>/g, ''))
          .join('')

        scriptElement.innerHTML = formattedScriptTag

        document.head.appendChild(scriptElement)
      }
    }
  }
}
export default DataLayerScript
