import React from 'react'
import Modal from '../../components/modal'
import SwitchProfile from '.'

export default function SwitchProfileModal({ onClose, profiles, token = null }) {
  return (
    <>
      <Modal onClose={onClose} closeOnClickOutside={false} top='4rem' token={token}>
        <div className='wrapper'>
          <div className='topRedLine'></div>
          <SwitchProfile onClose={onClose} profiles={profiles} token={token} />
        </div>
      </Modal>
      <style jsx>{`
        :global(.modal) {
          font-size: 1.3rem;
        }
        .wrapper {
          padding: 3rem;
          border-top: 4px solid #ccc;
          position: relative;

          .topRedLine {
            height: 4px;
            position: absolute;
            left: 0;
            top: -4px;
            width: 12rem;
            background: var(--wolseleyRed);
          }
        }

        @media (max-width: 768px) {
          :global(.region, .account-number, .profile-role, .tag) {
            font-size: 1.1rem;
          }
          :global(.modal) {
            transform: unset !important;
            
          }
          .wrapper {
            padding: 3rem 1rem;
          }
        }
      `}</style>
    </>
  )
}
