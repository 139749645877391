import client from '../client'

export async function signInUser({ email, password, rememberMe }) {
  let is_guest = false
  let username = email
  let is_persistent = rememberMe
  return client(`${process.env.NEXT_PUBLIC_DOMAIN}/Authentication/GetToken`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ is_guest, username, email, password, is_persistent }),
  })
}
