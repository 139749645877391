import LinkAnchor from '../button/link-anchor'

const LanguageSelector = ({ onLanguageChange, selectedLanguage }) => {
  return (
    <>
      <div className='language-selector'>
        <LinkAnchor
          href='#'
          className={`${selectedLanguage == 'en' ? 'active' : ''}`}
          disabled={true}
          onClick={() => (selectedLanguage == 'en' ? {} : onLanguageChange('en'))}
        >
          EN
        </LinkAnchor>

        <span>&nbsp;|&nbsp;</span>

        <LinkAnchor
          href='#'
          className={`${selectedLanguage == 'fr' ? 'active' : ''}`}
          disabled={true}
          onClick={() => (selectedLanguage == 'fr' ? {} : onLanguageChange('fr'))}
        >
          FR
        </LinkAnchor>
      </div>
      <style jsx>{`
        .language-selector {
          color: #fff;
          font-size: 1.4rem;
          font-weight: bold;
          :global(a) {
            color: #fff;
            border: 0;
          }
          :global(.active),:global(a):hover {            
            text-decoration: underline;
            text-decoration-thickness: 3px;
          }
        }
      `}</style>
    </>
  )
}

export default LanguageSelector
