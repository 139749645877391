import classnames from 'classnames'
import DoubleArrowLeft from '../svg/double-arrow-left'
import DoubleArrowRight from '../svg/double-arrow-right'
import { useTranslation } from 'react-i18next'

export default function Paging({
  pageIndex,
  totalPages,
  pageSize = 8,
  skip = 0,
  onFirst,
  onPrevious,
  onNext,
  onLast,
  onChangePageNumber,
  showFirstAndLast = false,
  showPageInfo = false,
}) {
  const { t } = useTranslation('header')
  const getPageNumbers = () => {
    const remainder = (skip + pageSize) % 3
    const end = totalPages > 2 ? (skip + pageSize) / pageSize + remainder : totalPages

    let content = []
    if (end > 3) {
      content.push(
        <button
          className='flatBtn pageNumber'
          key='ellipsis-start'
          onClick={() => onChangePageNumber(end - 6)}
        >
          ...
        </button>,
      )
    }

    for (let i = totalPages > 2 ? end - 3 : 0; i < end && i < totalPages; i++) {
      content.push(
        <button
          className={classnames('flatBtn pageNumber', { active: i === pageIndex })}
          key={`page-${i}`}
          onClick={() => onChangePageNumber(i)}
        >
          {i + 1}
        </button>,
      )
    }
    if (totalPages > 3 && totalPages > (skip + pageSize) / pageSize) {
      content.push(
        <button
          disabled={pageIndex + 1 === totalPages}
          className='flatBtn pageNumber'
          key='ellipsis-end'
          onClick={() => onChangePageNumber(end)}
        >
          ...
        </button>,
      )
    }
    return content
  }

  return (
    <div className='paging flex alignCenter justifyEnd'>
      {showPageInfo && (
        <div className='info flex alignCenter justifyCenter'>
          <span className='pageIndex'>{pageIndex + 1}</span>
          <span className='separator'>{t('wca.of')}</span>
          <span className='totalPages'>{totalPages}</span>
        </div>
      )}

      {showFirstAndLast && (
        <button
          disabled={pageIndex === 0}
          aria-label='first page'
          className='first'
          onClick={onFirst}
        >
          <DoubleArrowLeft fill='#fff' />
        </button>
      )}

      <button disabled={pageIndex === 0} className='flatBtn prev' onClick={onPrevious}>
        <span>{'<'}</span> {t('pager.previous')}
      </button>

      {totalPages > 0 && getPageNumbers()}

      <button disabled={pageIndex + 1 === totalPages} className='flatBtn next' onClick={onNext}>
        {t('pager.next')} <span>{'>'}</span>
      </button>

      {showFirstAndLast && (
        <button
          disabled={pageIndex + 1 === totalPages}
          aria-label='last page'
          className='last'
          onClick={onLast}
        >
          <DoubleArrowRight fill='#fff' />
        </button>
      )}

      <style jsx>{`
        .paging {
          padding-top: 1rem;
          width: 100%;
          font-size: 1.4rem;
          color: var(--wolseleyGrey);

          .prev,
          .next,
          :global(.pageNumber) {
            padding: 5px 6px;
            background-color: #fff;
            border: 1px solid #fff;
            border-radius: 6px;
            color: var(--wolseleyGrey);

            span {
              font-weight: 900;
              font-size: 1.1rem;
              padding: 2px;
              color: var(--primary-color);
            }
          }

          :global(.pageNumber) {
            margin-left: 2px;
            margin-right: 2px;
          }

          :global(.active) {
            border: 1px solid #f2f2f2;
            background: #f2f2f2;
          }

          .prev:hover,
          .next:hover,
          :global(.pageNumber):hover {
            border: 1px solid #f2f2f2;
            background: #f2f2f2;
          }

          .prev:disabled,
          .next:disabled {
            color: var(--primary-color);
          }

          :global(.pageNumber) {
            padding: 5px 10px;
          }
        }

        .first,
        .last {
          width: 4.2rem;
          height: 4.2rem;
          border-radius: 50%;
          background: var(--primary-button-bg);

          :global(svg) {
            position: relative;
            top: 0.2rem;
          }
        }

        .info {
          min-width: 14rem;
          margin: 0 4rem;
          color: var(--secondary-text-color);
          font-weight: 700;
          font-size: 1.8rem;

          .pageIndex,
          .totalPages {
            margin: 0 1rem;
            color: var(--primary-color);
          }
        }

        .first {
          margin: 0 0.9rem 0 0;
        }
        .last {
          margin: 0 0 0 0.9rem;
        }

        @media (max-width: 1024px) {
          .paging {
            :global(.prev),
            :global(.next) {
              width: 6.6rem;
            }
          }

          .first,
          .last {
            width: 3.5rem;
            height: 3.5rem;

            :global(svg) {
              transform: scale(0.7);
            }
          }

          .info {
            min-width: 9rem;
            margin: 0 1rem;
            font-size: 1.4rem;
          }
        }
      `}</style>
    </div>
  )
}
