import client from '../client'

export async function switchAccount({ selectedAccount}) {
  const params = new URLSearchParams({ selectedAccount })
  const headers = { 'Content-Type': 'application/json' }

  return client(`${process.env.NEXT_PUBLIC_DOMAIN}/CustomerDetails/SwitchAccount?${params.toString()}`, {
    headers: { ...headers },
    method: 'POST',
  })
}
