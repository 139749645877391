import PropTypes from 'prop-types'

const colorMap = {
  primary: 'var(--primary-color)',
  secondary: 'var(--secondary-text-color)',
  alert: 'var(--alert-color)',
  success: 'var(--success-color)',
  error: 'var(--error-color)',
  accessible: 'var(--ui-accessible-blue)',
  wolseleyDarkGrey: 'var(--wolseleyDarkGrey)',
}

const TextButton = ({ children, color = 'primary', startIcon, endIcon, ...rest }) => {
  return (
    <button type='button' className='flatBtn' {...rest}>
      {startIcon && <div className='startIcon'>{startIcon}</div>}
      {children}
      {endIcon && <div className='endIcon'>{endIcon}</div>}

      <style jsx>{`
        button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          transition: opacity 0.2s;
          color: ${colorMap[color] || 'var(--primary-color)'};
          font-size: 1.4rem;

          .startIcon {
            display: inherit;
            margin-right: 8px;
          }

          .endIcon {
            display: inherit;
            margin-left: 5px;
          }
        }
      `}</style>
    </button>
  )
}

export default TextButton
