import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import getMenuIcon from '@/utils/get-menu-icon'
import { mainSite } from '@/constants'
import { useSideNav } from '@/contexts/side-nav'
import { MenuItemType } from './constants'
import TickMarkIcon from '../svg/tickmark'
import classNames from 'classnames'

const MenuItemL2 = ({ item, markSelected = false, isPopup = false }) => {
  const { t } = useTranslation('sideNav')
  const { locale } = useRouter()
  const { handleNavClick, activeNavItem } = useSideNav()

  const isLink = item.type === MenuItemType.link || item.type === MenuItemType.linkAction

  const navigateToMenu = () => {
    if (item.id === activeNavItem?.id) return
    if (item.type === MenuItemType.route || item.type === MenuItemType.link) {
      window.location.href = `${mainSite}${locale}${item.route}`
    } else if (item.type === MenuItemType.route_NewWindow) {
      window.open(`${mainSite}${locale}${item.route}`, '_blank', 'noopener,noreferrer')
    }

    handleNavClick(item)
  }

  return (
    <>
      <div
        className={classNames(`menu-item-l2 cursor-pointer flex justifyBetween alignStart`, {
          active: activeNavItem?.id === item.id && !!!item.avoidActiveState,
          link: isLink,
          'popup-menu-item': isPopup,
        })}
        onClick={navigateToMenu}
      >
        <div className='title-section flex alignCenter'>
          <span className='flex'>{t(item.title)}</span>
          {!!item.icon && <span className='title-icon flex alignSelfCenter alignCenter justifyCenter'>{getMenuIcon(item.icon)}</span>}
        </div>
        {markSelected && activeNavItem?.id === item.id && (
          <span className='l2-icon flex alignSelfCenter alignCenter justifyCenter'>
            <TickMarkIcon />
          </span>
        )}
      </div>
      <style jsx>{`
        .menu-item-l2 {
          font-size: 1.4rem;
          line-height: 1.61rem;
          padding: 0.8rem 2.7rem;
          padding-right: 0;
          color: var(--wolseleyGrey);
          border-radius: 0.7rem;

          &:not(.active):not(.link) {
            &:hover {
              background: #00000012;
            }

            &:active {
              background: #0000001c;
            }
          }

          .title-section {
            .title-icon {
              width: 3.2rem;

              svg {
                flex-shrink: 0;
              }
            }
          }

          &.link {
            color: var(--ui-accessible-blue);
            text-decoration: underline;

            .title-section {
              .title {
                color: inherit;
              }
            }

            &:hover {
              color: #006bff;
            }
          }

          &.active:not(.link) {
            background: var(--wolseleyDarkGrey);
            color: #ffffff;
            cursor: default;

            .title-section {
              .title {
                color: inherit;
              }
            }
          }

          &.popup-menu-item {
            padding: 0.4rem;
            padding-left: 1rem;
            line-height: 2.4rem;
            color: #000000;
            border-radius: 0.7rem;

            &.active:not(.link) {
              background: #eaeaea;
              color: #000000;
              cursor: default;
            }

            &.link {
              color: var(--ui-accessible-blue);
              text-decoration: underline;

              &:hover {
                color: #006bff;
              }
            }

            &:not(.active):not(.link) {
              &:hover {
                background: #00000012;
              }

              &:active {
                background: #0000001c;
              }
            }

            .title-section {
              .title-icon {
                width: 3.2rem;

                svg {
                  flex-shrink: 0;
                }
              }
            }

            .l2-icon {
              width: 3.2rem;

              svg {
                flex-shrink: 0;
              }
            }
          }
        }

        @media (max-width: 767px) {
          .menu-item-l2 {
            padding: 0 0.8rem;
          }
        }

        @media (hover: none), (pointer: coarse) {
          .menu-item-l2 {
            &:not(.active) {
              &:hover {
                background: inherit;
              }
            }

            &.link {
              &:hover {
                color: var(--ui-accessible-blue);
              }
            }

            &.popup-menu-item {
              &:not(.active):not(.link) {
                &:hover,
                &:active {
                  background: inherit;
                }
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(MenuItemL2), { ssr: false })
