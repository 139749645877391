import { useState, createContext, useContext, memo, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  getAllFeatureToggles,
  getAllFeatureTogglesKey,
} from '../api-client/feature-toggle/get-all-feature-toggles'

const FeatureToggleContext = createContext()
const FeatureToggleDataContext = createContext()

function FeatureToggleProviderComponent({ children }) {
  const [featureToggles, setFeatureToggles] = useState({})

  const { refetch: refetchFeatureToggles, isFetching } = useQuery({
    queryKey: [getAllFeatureTogglesKey],
    queryFn: getAllFeatureToggles,
    onSuccess: (res) => {
      setFeatureToggles(res)
    },
  })

  return (
    <FeatureToggleContext.Provider
      value={useMemo(
        () => ({
          refetchFeatureToggles,
        }),
        [refetchFeatureToggles],
      )}
    >
      <FeatureToggleDataContext.Provider
        value={useMemo(
          () => ({
            featureToggles,
            isFetching,
          }),
          [featureToggles, isFetching],
        )}
      >
        {children}
      </FeatureToggleDataContext.Provider>
    </FeatureToggleContext.Provider>
  )
}

const useFeatureToggle = () => [
  useContext(FeatureToggleDataContext),
  useContext(FeatureToggleContext),
]

const FeatureToggleProvider = memo(FeatureToggleProviderComponent)

export { FeatureToggleProvider, useFeatureToggle }
