import { useMutation } from '@tanstack/react-query'
import { saveLanguage } from '../api-client/language/set-language'
import Cookies from 'universal-cookie'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { CookiesKey } from '../constants'
export default function useLanuageChange() {
  const router = useRouter()
  const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

  const { mutate: mutateLanguage } = useMutation(saveLanguage, {
    onSuccess: (data) => {
      window.location.href = `${data.redirectUrl}`
    },
  })
  useEffect(() => {
    const cookie = new Cookies()
    const cookies = new Cookies()
    const languageCookie = cookie.get('.Nop.Culture') || 'en-CA'
    let locale = languageCookie.includes('fr-CA') ? 'fr' : 'en'
    // We needs to add locale value to the URL when .Nop.Culture cookie value and router.locale are the same.
    if (window.location.href.indexOf('/en') === -1 && window.location.href.indexOf('/fr') === -1 && router.locale === locale) {
      window.location.href = `${window.location.origin}/${locale}${window.location.pathname}${window.location?.hash}`
      return
    }
    const region = cookie.get(CookiesKey.SELECTED_REGION_ID, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    })
    // when region is not 1 or 5 and url contains /fr then we need to get new en url and set cookies to en. Also, we need to decodeURI because 3.8 send encoded url.
    if (!!region && ![1, 5].includes(region)) {
      if (window.location.href.indexOf('/fr') !== -1) {
        const newLanguage = 'en-CA'
        router.locale = 'en'
        cookies.set('.Nop.Culture', newLanguage, { path: '/' })
        console.log('.Nop.Culture After', cookie.get('.Nop.Culture'))
        mutateLanguage({
          languageId: 1,
          url: window.location.href,
        })
      }
    }    
    // if url does not contains En or FR and we have locale value then we need to redirect to that locale page.
    if (!!locale && window.location.href === mainSite) {
      window.location.href = `${window.location.origin}/${locale}`
      return
    }
    //when language change in the URL then below code will apply and update the cookie
    if (router.locale !== locale) {
      const newLanguage = router.locale == 'en' ? 'en-CA' : 'fr-CA'
      locale = router.locale == 'en' ? 'en' : 'fr'
      cookies.set('.Nop.Culture', newLanguage, { path: '/' })
      mutateLanguage({
        languageId: router.locale == 'fr' ? 2 : 1,
        url: window.location.href,
      })
    }
  }, [])
}
