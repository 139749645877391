export default function TickMarkIcon(props) {
  return (
    <svg
      width='10'
      height='8'
      viewBox='0 0 10 8'
      fill='black'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9.70621 0.505927C10.0826 0.861438 10.0996 1.45479 9.74407 1.83121L4.43157 7.45621C4.25766 7.64035 4.01665 7.74629 3.76339 7.74991C3.51013 7.75352 3.26619 7.65451 3.08709 7.47541L0.274587 4.66291C-0.0915291 4.2968 -0.0915291 3.70321 0.274587 3.33709C0.640704 2.97097 1.2343 2.97097 1.60041 3.33709L3.73079 5.46746L8.38092 0.543793C8.73643 0.167369 9.32978 0.150417 9.70621 0.505927Z' />
    </svg>
  )
}
