const MyOrder = (props) => {
  return (
    <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.25 2.5C0.25 1.11929 1.36929 0 2.75 0H9.75C11.1307 0 12.25 1.11929 12.25 2.5V15.5C12.25 15.6881 12.1445 15.8602 11.9769 15.9456C11.8093 16.0309 11.608 16.015 11.4559 15.9044L6.25 12.1182L1.04409 15.9044C0.891993 16.015 0.690696 16.0309 0.523111 15.9456C0.355526 15.8602 0.25 15.6881 0.25 15.5V2.5ZM2.75 1C1.92157 1 1.25 1.67157 1.25 2.5V14.5181L5.95592 11.0956C6.13124 10.9681 6.36876 10.9681 6.54409 11.0956L11.25 14.5181V2.5C11.25 1.67157 10.5784 1 9.75 1H2.75Z'
        fill='#212121'
      />
    </svg>
  )
}
export default MyOrder
