import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'
import LinkAnchor from '../button/link-anchor'
import logo from './../../../public/images/main_logo.png'
import TopRibbon from './top-ribbon'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

const CustomHeader = () => {
  const { locale } = useRouter()

  return (
    <>
      <header>
        <div className={`flex justifyBetween flexWrapNone content alignCenter`}>
          <div className='logo'>
            <LinkAnchor href={`${mainSite}${locale}`}>
              <Image src={logo} alt='Wolseley Logo' quality={100} />
            </LinkAnchor>
          </div>
          <div>
            <TopRibbon showLanguageOnly={true} />
          </div>
        </div>
      </header>
      <style jsx>{`
        header {
          background-color: #ffffff;
          border-bottom: 1px solid #ddd;
          padding: 1.4rem 0;

          .content {
            width: 100%;
            max-width: 90.7rem;
            margin: 0 auto;

            .logo {
              max-width: 10rem;
              max-height: 5.2rem;
            }
          }
        }

        @media (max-width: 1024px) {
          header {
            .content {
              max-width: 76.8rem;
              padding: 0 1rem;
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(CustomHeader), {
  ssr: false,
})
