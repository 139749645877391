import { useTranslation } from 'react-i18next'
import LinkAnchor from '../button/link-anchor'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from 'next/link'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN
const footer = 'Footer'

const CustomFooter = () => {
  const { t } = useTranslation('footer')
  const { locale } = useRouter()

  return (
    <>
      <footer>
        <div className='footer-topband'>
          <div className='wrapper flex flexWrap'>
            <strong>{t('wca.gotquestions')}</strong>
            <Link href={`${mainSite}${locale}/ContactUs/GetContactUsPage/`} passHref>
              <a target='_blank' rel='noopener noreferrer' className='blue-link'>
                {t('pagetitle.contactus')}
              </a>
            </Link>
            <span>
              {t('wca.or')} {t('wca.call')}
            </span>
            <a href='tel:1-888-290-6318' className='blue-text'>
              1-888-290-6318
            </a>
            <span className='color75'>{t('wca.footer.hours')}</span>
          </div>
        </div>
        <div className='content flex flexWrapNone'>
          <div className='wrapper flex flexWrap'>
            <LinkAnchor
              disabled={false}
              href={`${mainSite}${locale}/termsandconditions/`}
              aria-label='Terms and Conditions'
              navigationEvent={{ elementName: 'Terms and Conditions', elementLocation: footer }}
              target='_blank'
            >
              {t('wca.termscondition')}
            </LinkAnchor>

            <LinkAnchor
              disabled={false}
              href={`${mainSite}${locale}/privacy-policy/`}
              target='_blank'
              aria-label='Privacy Policy'
              navigationEvent={{ elementName: 'Privacy Policy', elementLocation: footer }}
            >
              {t('wca.privacypolicy')}
            </LinkAnchor>

            <LinkAnchor
              disabled={false}
              href={`${mainSite}${locale}/TermsOfUse/GetTermsOfUsePage/`}
              aria-label='Terms of Use'
              target='_blank'
              navigationEvent={{ elementName: 'Terms of Use', elementLocation: footer }}
            >
              {t('wca.termsofuse')}
            </LinkAnchor>
          </div>
        </div>
      </footer>

      <style jsx>
        {`
          footer {
            background: #3b3b3b;
            height: 19.6rem;

            .footer-topband {
              background: #f7f7f7;
              border: 1px solid #ddd;
              width: 100%;
              padding: 1rem;

              .wrapper {
                font-size: 1.4rem;
                width: 100%;
                max-width: 90.7rem;
                margin: 0 auto;

                span,
                strong,
                a {
                  padding-right: 1rem;
                  line-height: 2.6rem;
                }

                .color75 {
                  color: var(--primary-text-color);
                }
              }
            }

            .blue-link,
            .blue-text {
              color: var(--ui-accessible-blue);
              white-space: nowrap;
            }

            .content {
              width: 100%;
              max-width: 90.7rem;
              margin: 0 auto;
              padding: 1.5rem 0;

              .wrapper {
                color: #cccccc;
                font-size: 1.4rem;

                :global(a) {
                  padding-right: 4.2rem;
                  line-height: 3.6rem;
                }
              }
            }
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            footer {
              .footer-topband {
                padding: 0;
                .wrapper {
                  width: 76.8rem;
                  margin: 0 auto;
                  padding: 1rem 2rem;
                }
              }

              .content {
                padding: 0;
                .wrapper {
                  width: 76.8rem;
                  margin: 0 auto;
                  padding: 1.5rem 2rem;
                }
              }
            }
          }

          @media (max-width: 767px) {
            footer {
              height: 23.6rem;

              .content {
                padding: 1.5rem 1rem;
                .wrapper {
                  flex-direction: column;
                }
              }
            }
          }
        `}
      </style>
    </>
  )
}

export default dynamic(() => Promise.resolve(CustomFooter), {
  ssr: false,
})
