import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getCartCount, getCartCountKey } from '../api-client/common/get-cart-count'
import { useAuth } from '../contexts/auth'

export default function useCartCount() {
  const [{ isAuthenticated }] = useAuth()
  const [cartCount, setCartCount] = useState(0)

  const { refetch: refetchCartCount } = useQuery({
    queryKey: [getCartCountKey],
    queryFn: getCartCount,
    enabled: isAuthenticated,
    onSuccess: (data) => setCartCount(data),
  })

  return [
    cartCount,
    useMemo(
      () => ({
        refetchCartCount: refetchCartCount,
      }),
      [refetchCartCount],
    ),
  ]
}
