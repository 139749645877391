import Cookies from 'universal-cookie'
import { CookiesKey, mainSite } from '../constants'

const cookie = new Cookies()

export default async function client(endpoint, init = {}, config = {}) {
  const safeToken = config.token || cookie.get(CookiesKey.TOKEN_KEY, { path: '/', domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN })
  const headers = {}
  if (safeToken) {
    headers.Authorization = `Bearer ${safeToken}`
  }

  const fancyInit = {
    //mode: 'no-cors',
    credentials: 'include', // include, *same-origin, omit // this prop is used to send/receive cookies from server
    ...init,
    headers: {
      ...headers,
      ...init.headers,
    },
  }

  return fetch(endpoint, fancyInit).then(async (response) => {
    if (response.status === 401) {
      cookie.remove(CookiesKey.TOKEN_KEY, { path: '/' })

      if (typeof config.onUnauthorized === 'function') config.onUnauthorized(response)
      //if (typeof window !== 'undefined') window.location.assign('/')

      console.error(new Error('API Response is Unauthorized'), response)
      return Promise.reject(response)
    }

    if (response.status === 500) {
      console.error(new Error('API Response status is 500'), response)

      if (typeof window !== 'undefined' && typeof process !== 'undefined') {
        if (process.env.NEXT_PUBLIC_SHOW_500_ERRORS === 'true') {
          window.location.href = `${mainSite}errorpage.htm`
        }
      }

      return Promise.reject(response)
    }

    try {
      const result = await response.json()

      if (response.ok) {
        if (result) return Promise.resolve(result.data)

        console.error(new Error('API Response data is not OK'), response)
        return Promise.resolve(response)
      }

      console.error(new Error('API Response status not OK'), response)
      if (typeof window === 'undefined') return { error: true, statusCode: 500 }

      return Promise.reject(response)
    } catch (err) {
      console.error(err, response)
      return Promise.reject(err)
    }
  })
}
