import Script from 'next/script'
import { useFeatureToggle } from '../../contexts/feature-toggle'
import { useEffect } from 'react'

const Medallia = () => {
  const [{ featureToggles }] = useFeatureToggle()

  useEffect(() => {
    var feedbackElement = document.querySelector('.kampyle_vertical_button')
    if (feedbackElement) {
      feedbackElement.style.zIndex = '99'
    }
  }, [])
  
  if (!featureToggles?.IsMedalliaSurveyEnabled) return null

  return (
    <>
      <Script
        type='text/javascript'
        src='https://nebula-cdn.kampyle.com/us/wu/960418/onsite/embed.js'
        async
      ></Script>
    </>
  )
}

export default Medallia
