const Hamburger = (props) => (
  <svg width='22.969' height='16.057' viewBox='0 0 22.969 16.057' fill='#cd2e31' {...props}>
    <path
      id='e97d'
      d='M21.812,6.885H1.157a1.155,1.155,0,0,0-.834.309A1.084,1.084,0,0,0,0,8.015a1.118,1.118,0,0,0,.323.847,1.155,1.155,0,0,0,.834.309H21.812a1.155,1.155,0,0,0,.834-.309,1.118,1.118,0,0,0,.323-.847,1.084,1.084,0,0,0-.323-.82A1.155,1.155,0,0,0,21.812,6.885ZM1.157,2.286H21.812a1.155,1.155,0,0,0,.834-.309,1.118,1.118,0,0,0,.323-.847,1.084,1.084,0,0,0-.323-.82A1.155,1.155,0,0,0,21.812,0H1.157A1.155,1.155,0,0,0,.323.309,1.084,1.084,0,0,0,0,1.13a1.118,1.118,0,0,0,.323.847A1.155,1.155,0,0,0,1.157,2.286ZM21.812,13.77H1.157a1.155,1.155,0,0,0-.834.309A1.084,1.084,0,0,0,0,14.9a1.118,1.118,0,0,0,.323.847,1.155,1.155,0,0,0,.834.309H21.812a1.155,1.155,0,0,0,.834-.309,1.118,1.118,0,0,0,.323-.847,1.084,1.084,0,0,0-.323-.82A1.155,1.155,0,0,0,21.812,13.77Z'
    />
  </svg>
)

export default Hamburger
