import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import classNames from 'classnames'
import FlatX from '../svg/flat-x'
import Hamburger from '../svg/hamburger'
import { useAuth } from '../../contexts/auth'
import CartHeader from '../cart-header'
import SwitchAccountModal from '../../containers/switch-account/switch-account-modal'
import { useRouter } from 'next/router'
import { REGIONS } from '../../constants'
import LinkAnchor from '../button/link-anchor'
import { useQuery } from '@tanstack/react-query'
import logo from './../../../public/images/main_logo.png'
import ForgotPasswordModal from './../../containers/forgot-password/forgot-password-modal'
import { getActiveProfileCount, getActiveProfileCountKey } from '../../api-client/profile/get-active-profile-count'
import SignInMenu from './signin-menu'
import HardAlert from '../svg/icon-hard-alert'
import { DesktopTablet, Mobile } from '../../components/media'
import SearchBoxWrapper from '../search-box/search-box-wrapper'
import { useRegion } from '@/contexts/region-context'

const CategoryNavigation = dynamic(() => import('../category-navigation/index'), {})

const CategoryNavigationMobile = dynamic(() => import('../category-navigation/mobile-menu'), {})

const UserMenu = dynamic(() => import('./user-menu'), {})

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

const Header = () => {
  const { locale } = useRouter()
  const { t } = useTranslation(['header'])
  const [{ isAuthenticated, activeProfile, permissions }, { setOpenSwitchProfileModal }] = useAuth()
  const { selectedRegionId, openRegionModal } = useRegion()

  const [open, setOpen] = useState(false)

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [openSwitchAccountModal, setOpenSwitchAccountModal] = useState(false)
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false)
  const [siginginDropdownIsOpen, setsiginginDropdownIsOpen] = useState(false)

  const { data: profileCountData } = useQuery({
    queryKey: [getActiveProfileCountKey],
    queryFn: () => getActiveProfileCount(),
    enabled: isAuthenticated,
  })

  const toggleDropdown = useCallback(() => {
    setDropdownIsOpen((prev) => !prev)
  }, [])

  const handleSwitchProfileClick = useCallback(() => {
    setOpenSwitchProfileModal({ open: true, profiles: null, token: null })
    toggleDropdown()
  }, [setOpenSwitchProfileModal, toggleDropdown])

  const handleSwitchAccountClick = useCallback(() => {
    setOpenSwitchAccountModal(true)
    toggleDropdown()
  }, [toggleDropdown])

  const handleForgotPasswordModal = useCallback(() => {
    setOpenForgotPasswordModal(true)
    toggleDropdown()
  }, [toggleDropdown])

  const handleForgotPasswordClose = useCallback(() => {
    setOpenForgotPasswordModal(false)
    const elementBody = document.querySelector('body')
    elementBody?.classList?.remove('bodyFreeze')
  })

  const toggleSiginginDropdownIsOpen = () => {
    setsiginginDropdownIsOpen((prev) => !prev)
  }

  const returnClassName = () => {
    if (isAuthenticated) return 'alignStart'
    else return 'alignCenter'
  }
  const handleMobileMenuToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.querySelector('#backdrop')?.classList?.add('backdrop-menu')
        document.querySelector('body')?.classList?.add('bodyFreeze')
      }, 0)
    } else {
      setTimeout(() => {
        document.querySelector('#backdrop')?.classList?.remove('backdrop-menu')
        document.querySelector('body')?.classList?.remove('bodyFreeze')
      }, 0)
    }
  }, [open])

  return (
    <>
      <header className='navbar'>
        <div className={`navbarDiv flex ${returnClassName()}`}>
          <DesktopTablet>
            <div className='logo flex'>
              <LinkAnchor href={`${mainSite}${locale}`}>
                <Image src={logo} alt='Wolseley Logo' quality={100} />
              </LinkAnchor>
            </div>
          </DesktopTablet>
          <Mobile>
            <div className='flex' onClick={() => (window.location.href = `${mainSite}${locale}`)}>
              <div className='logo-mobile'>
                <LinkAnchor href={`${mainSite}${locale}`} />
              </div>
            </div>
          </Mobile>
          <div className='searchbox'>
            <SearchBoxWrapper />
          </div>
          <div className='flex'>
            <div className='userContainerDiv'>
              <div className='userContainer'>
                {isAuthenticated && (
                  <div className='alignStart'>
                    <UserMenu
                      handleSwitchAccountClick={handleSwitchAccountClick}
                      handleSwitchProfileClick={handleSwitchProfileClick}
                      dropdownIsOpen={dropdownIsOpen}
                      toggleDropdown={toggleDropdown}
                      permissions={permissions}
                      profileCount={profileCountData}
                      locale={locale}
                    />
                  </div>
                )}

                {!isAuthenticated && (
                  <div className='alignCenter signin-register'>
                    <SignInMenu handleForgotPasswordModal={handleForgotPasswordModal} siginginDropdownIsOpen={siginginDropdownIsOpen} toggleSiginginDropdownIsOpen={toggleSiginginDropdownIsOpen} />
                  </div>
                )}
              </div>
            </div>
            <DesktopTablet>
              <div className='felx justifyEnd cartIconContainer'>{permissions?.cart && <CartHeader />}</div>
            </DesktopTablet>
          </div>
          <Mobile>
            <div className='flex justifyEnd'>
              <div className='cartIconContainer'>{permissions?.cart && <CartHeader />}</div>
            </div>
          </Mobile>
        </div>
        {!isAuthenticated && (
          <div className='flex alignCenter selectARegionDiv' onClick={() => openRegionModal()}>
            <span className='selectARegionIcon'>
              <Image src='/images/location-pin.jpg' alt={t('wca.selectaregion')} width={13} height={20} />
            </span>
            <span className='selectARegionLabel'>
              {!!selectedRegionId
                ? selectedRegionId == 3
                  ? t('wca.atlantic')
                  : selectedRegionId == 4
                  ? t('wca.west')
                  : locale === 'en'
                  ? REGIONS.find((item) => item.id == selectedRegionId)?.name
                  : REGIONS.find((item) => item.id == selectedRegionId)?.nameFR
                : t('wca.selectaregion', { ns: 'header' })}
            </span>
          </div>
        )}
        <div className='flex alignCenter mobile'>
          <button aria-label={open ? 'close menu' : 'open menu'} className='flatBtn burgerBtn' onClick={handleMobileMenuToggle}>
            {open ? <FlatX /> : <Hamburger />}
          </button>
        </div>

        <div className='flex borderBottom justifyBetween'>
          <div className={classNames('asideNavigation', { open })}>
            <DesktopTablet>
              <CategoryNavigation permissions={permissions} />
            </DesktopTablet>
            {open && (
              <Mobile>
                <CategoryNavigationMobile permissions={permissions} />
              </Mobile>
            )}
          </div>
          {isAuthenticated && activeProfile?.creditStatus && <div className='headerIcon'>{activeProfile?.creditStatus?.toLowerCase() !== 'a' && <HardAlert />}</div>}
        </div>

        {isAuthenticated && openSwitchAccountModal && <SwitchAccountModal onClose={() => setOpenSwitchAccountModal(false)} />}
        {openForgotPasswordModal && <ForgotPasswordModal onClose={handleForgotPasswordClose} />}
      </header>
      <style jsx>{`
        header {
          background-color: #ffffff;
          .navbarDiv {
            position: relative;
            justify-content: space-between;
            padding-right: 1rem;
            position: relative;
            padding-top: 2rem;
            padding-bottom: 1rem;
          }
          .logo {
            max-width: 20.2rem;
            max-height: 5.2rem;
            margin-right: 2rem;
          }
          .logo-mobile {
            float: left;
            background: url(${logo.src}) no-repeat center;
            background-size: contain;
            height: 47px;
            text-indent: -9999px;
          }

          .rightSection {
            width: 25%;
            min-height: 4rem;
          }
          .signin-register {
            padding: 10px;
            border-bottom: 4px solid transparent;
            width: 252px;
            flex-wrap: wrap !important;
          }
          .signin-register:hover {
            border-bottom: 4px solid #cd2e31;
            cursor: pointer;
          }
          .selectARegionDiv {
            width: fit-content;
          }
          .selectARegionIcon {
            cursor: pointer;
          }
          .selectARegionLabel {
            font-weight: 600;
            font-size: 1.3rem;
            margin-left: 0.5rem;
            cursor: pointer;
            color: #333;
          }
          .cartIconContainer {
            margin-top: 1rem;
          }

          .searchbox {
            width: 50%;
            position: relative;
            padding-right: 2rem;
          }

          .borderBottom {
            border-bottom: 1px dashed #ccc;
          }
        }

        @media (max-width: 1280px) {
          header {
            padding: 1rem 1rem 0;
          }
        }
        @media (max-width: 846px) {
          :global(.mainItem) {
            margin: 0px 2rem 0px 0px !important;
          }
          :global(.loaderSvg) {
            left: 10rem;
          }
        }
        @media (min-width: 768px) {
          .mobile {
            display: none;
          }
        }
        @media (max-width: 767px) {
          header {
            .navbarDiv {
              justify-content: unset;
              padding-right: unset;
            }
            .userContainerDiv {
              max-width: 23.5rem;
            }

            .burgerBtn {
              width: 5.2rem;
              height: 4.6rem;
              padding: 1rem;
              :global(svg) {
                width: 100%;
                height: 100%;
              }
            }

            .logo {
              width: 40%;
            }
            .logo-mobile {
              width: 100px;
            }

            .rightSection {
              width: 60%;
            }

            .searchbox {
              width: 84%;
              position: absolute;
              right: 0;
              top: ${isAuthenticated ? '7.6rem' : '11.8rem'};
              padding-right: 0rem;
            }

            .asideNavigation {
              display: none;
              min-width: 30rem;
              max-width: 100%;
              z-index: 9999;
              background-color: #fff;
              border: 1px solid #e7e7e7;
              box-shadow: 0px 5px 10px 0px rgb(128 128 128 / 20%);
              position: absolute;
              left: 0;
              margin-top: 1rem;
              overflow: hidden !important;
              &.open {
                display: block;
                height: 100vh;
              }
            }

            .borderBottom {
              border-bottom: 1px dashed #ccc;
              padding-bottom: 1rem;
            }
            .signin-register {
              width: auto;
            }
            .userContainer {
              max-width: 23.5rem !important;
            }
          }
          .selectARegionDiv {
            padding-bottom: 2rem;
          }
          .mobile {
            display: flex;
          }
        }
        @media (max-width: 400px) {
          header {
            .userContainer {
              max-width: 23.5rem !important;
            }
          }
        }
        @media (max-width: 360px) {
          header {
            .userContainer {
              max-width: 16.5rem !important;
            }

            .searchbox {
              width: 83%;
              position: absolute;
              right: 0;
              top: ${isAuthenticated ? '8rem' : '12.4rem'};
              padding-right: 0;
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(Header), {
  ssr: false,
})
