import { useEffect, memo } from 'react'
import classNames from 'classnames'
import { zIndex } from '@/constants'
import { CloseButton } from '@/components/button/close-button'
import { elementIsVisibleInViewport } from '@/utils/element-is-visible-in-viewport'

const SlideInModal = ({ hideCloseBtn = false, onClose, children, headerTitle, icon, open = false, ...rest }) => {
  useEffect(() => {
    const escapeListener = (event) => {
      if (event.key === 'Escape') onClose()
    }

    document.addEventListener('keydown', escapeListener)

    return () => document.removeEventListener('keydown', escapeListener)
  }, [onClose])

  useEffect(() => {
    const elementBody = document.querySelector('body')
    if (open) elementBody?.classList?.add('bodyFreeze')
    else elementBody?.classList?.remove('bodyFreeze')

    const el = document.querySelector('.quantity-box')
    const addToCartFixedElement = document.querySelector('.addToCartFixedElement')
    if (el && addToCartFixedElement && open) {
      el.classList.remove('fixed')
      addToCartFixedElement?.classList.remove('active')
    } else {
      if (el && addToCartFixedElement) {
        const isVisible = elementIsVisibleInViewport(el)
        if (!isVisible) {
          el?.classList?.add('fixed')
          addToCartFixedElement?.classList?.add('active')
        }
      }
    }
  }, [open])

  return (
    <>
      <div
        className={classNames('slideInModal modal', {
          open: open,
        })}
        aria-modal='true'
      >
        <div className='modal-header flex flexDirectionColumn justifyCenter alignCenter'>
          <div className='modal-title flex alignCenter justifyBetween'>
            <div className='flex alignCenter justifyBetween'>
              {icon && icon}
              <div className='modal-div-title'>{headerTitle}</div>
            </div>
            {!hideCloseBtn && <CloseButton onClose={onClose} />}
          </div>
        </div>
        <div className='modal-body'>{children}</div>
      </div>
      <div className={classNames('backdrop', { open: open })} onClick={onClose}></div>
      <style jsx>{`
        .slideInModal {
          z-index: 99999999 !important;
        }
        .backdrop {
          background-color: rgba(0, 0, 0, 0.55);
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
          display: none;
          z-index: ${zIndex.Modal};

          &.open {
            display: block;
          }
        }

        .modal {
          position: fixed;
          top: 0;
          right: -38rem;
          height: calc(max(100%, 100vh));
          background-color: #fff;
          width: 38rem;
          border-top-left-radius: 13px;
          border-bottom-left-radius: 13px;
          box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
          transition: all 0.3s ease-out;
          z-index: ${zIndex.Modal + 1};
          overflow-y: auto;
          &.open {
            right: 0;
          }

          .modal-header {
            padding: 1rem 1.2rem 1rem 1.5rem;
            background: ${rest.background ?? '#FFFFFF'};
            border-bottom: 1px solid ${rest.border ?? '#DDDDDD'};

            .modal-title {
              width: 100%;
              font-weight: 700;
              font-size: 1.6rem;

              > div {
                gap: 0.5rem;
              }

              :global(.checkmarkicon) {
                margin-right: 0.5rem;
              }
            }
          }

          .modal-body {
            padding: 1.5rem 1.5rem 3.5rem 1.5rem;
          }

          .modal-div-title {
            color: var(--wolseley-dark-grey);
          }
        }

        @media (max-width: 480px) {
          .modal {
            &.open {
              width: 100%;

              .modal-header {
                width: 100%;
              }
            }
          }
        }

        @media (max-width: 38rem) {
          .modal {
            border-radius: 0px;
          }
        }
      `}</style>
    </>
  )
}

export default memo(SlideInModal)
