import { useMemo } from 'react'

export default function useHorizontalScrollGradient() {
  const handleScrollGradients = (theme = 'dark', element, styleElement) => {
    styleElement = styleElement ?? element
    const themeName = theme === 'light' ? '_light' : '_dark'
    if (!!!styleElement) return
    if (styleElement) {
      if (element.scrollLeft > 0 && element.offsetLeft + element.scrollLeft + element.offsetWidth < element.scrollWidth) {
        styleElement.classList.add(`lrGradient${themeName}`)
        styleElement.classList.remove(`rGradient${themeName}`)
        styleElement.classList.remove(`lGradient${themeName}`)
      } else if (element.scrollLeft > 0) {
        styleElement.classList.add(`lGradient${themeName}`)
        styleElement.classList.remove(`lrGradient${themeName}`)
        styleElement.classList.remove(`rGradient${themeName}`)
      } else if (element.offsetLeft + element.scrollLeft + element.offsetWidth < element.scrollWidth) {
        styleElement.classList.add(`rGradient${themeName}`)
        styleElement.classList.remove(`lrGradient${themeName}`)
        styleElement.classList.remove(`lGradient${themeName}`)
      } else {
        styleElement.classList.remove(`rGradient${themeName}`)
        styleElement.classList.remove(`lrGradient${themeName}`)
        styleElement.classList.remove(`lGradient${themeName}`)
      }
    }
  }

  return useMemo(
    () => ({
      handleScrollGradients,
    }),
    [handleScrollGradients],
  )
}
