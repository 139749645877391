import React, { createContext, useContext, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { AlertModal } from '@/components/modal/alert-modal'

const AlertModalContext = createContext()

const initModal = {
  content: null,
  props: {},
  isOpen: false,
}

export const AlertModalProvider = ({ children }) => {
  const [modal, setModal] = useState(initModal)

  const openModal = (content, props = { shouldCloseOnOutsideClick: true }) => {
    if (isOpen) {
      closeModal()
      setTimeout(() => {
        setModal({
          content,
          props,
          isOpen: true,
        })
      }, props.animationDelay ?? 0)
    } else {
      setModal({
        content,
        props,
        isOpen: true,
      })
    }
  }

  const closeModal = () => {
    setModal(initModal)
  }

  const contextValue = useMemo(() => {
    return { openModal, closeModal }
  }, [openModal, closeModal])

  const { content, props, isOpen } = modal

  return (
    <AlertModalContext.Provider value={contextValue}>
      {children}
      {isOpen &&
        createPortal(
          <AlertModal
            onClose={closeModal}
            shouldCloseOnOutsideClick={props.shouldCloseOnOutsideClick}
          >
            {content}
          </AlertModal>,
          document.body,
        )}
    </AlertModalContext.Provider>
  )
}

export const useAlertModal = () => {
  const context = useContext(AlertModalContext)

  if (context === undefined) {
    throw new Error('useAlertModal must be used within a AlertContextProvider')
  }

  return context
}
