import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { Tooltip } from 'react-tooltip'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import { useSideNav } from '@/contexts/side-nav'
import MenuItemL1 from './menu-item-l1'
import { SideNavCollapse } from '../svg/sidenav-collapse'
import { SideNavExpand } from '../svg/sidenav-expand'

const SideNav = ({ menuItems }) => {
  const { deviceTypeBasedOnWidth } = useDeviceDetect()
  const { showToggle, sideNavRef, sideNavExpanded, setMenuExpandedState, applyMenuGradients } = useSideNav()
  const { t } = useTranslation('sidenav')

  const [expanded, setExpanded] = useState(false)

  const toggleMenuView = () => {
    setMenuExpandedState(!expanded)
  }

  useEffect(() => {
    if (sideNavExpanded) {
      setTimeout(() => {
        setExpanded(sideNavExpanded)
      }, 400)
    } else {
      setExpanded(false)
    }
  }, [sideNavExpanded])

  useEffect(() => {
    if (!!menuItems?.length && deviceTypeBasedOnWidth.mobile) {
      setTimeout(() => {
        applyMenuGradients()
      }, 0)
    }
  }, [menuItems?.length])

  return (
    <>
      <div className={classNames('side-nav-wrapper', { expanded: sideNavExpanded })}>
        <div ref={sideNavRef} className={classNames('side-nav flex', { flexDirectionColumn: !deviceTypeBasedOnWidth.mobile })}>
          {!!menuItems.length && (
            <div className={classNames('menu-wrapper flex', { flexDirectionColumn: !deviceTypeBasedOnWidth.mobile })}>
              {showToggle && (
                <div className='toggle-wrapper'>
                  <button className='toggle' onClick={toggleMenuView}>
                    <span className='hide'>sideNav</span>
                    {expanded ? <SideNavCollapse /> : <SideNavExpand />}
                  </button>
                  <Tooltip
                    anchorSelect={`.toggle-wrapper .toggle`}
                    opacity={1}
                    noArrow={true}
                    place='right'
                    style={{
                      maxWidth: '30rem',
                      fontSize: '1.4rem',
                      padding: '0.5rem 1rem',
                      background: '#3B3B3B',
                      boxShadow: '0 1.5rem 1.5rem -0.6rem #00000040',
                      textTransform: 'none',
                      fontWeight: '400',
                      lineHeight: '1.61rem',
                      borderRadius: '0.7rem',
                      textAlign: 'left',
                    }}
                  >
                    {sideNavExpanded ? t('wca.hideNav') : t('wca.expandNav')}
                  </Tooltip>
                </div>
              )}
              {menuItems.map((menuItem) => (
                <MenuItemL1 key={menuItem.l1?.id} item={menuItem} />
              ))}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .side-nav-wrapper {
          width: 4.8rem;
          transition: width 0.4s ease;
          height: fit-content;
          flex-shrink: 0;
          position: sticky;
          top: 0;
          background: #fafafa;

          .side-nav {
            width: 100%;

            .toggle-wrapper {
              height: 3.2rem;
              width: 3.2rem;
              background: #ffffff;
              position: absolute;
              right: 0.8rem;
              top: -1.6rem;
              border-radius: 0.6rem;

              .toggle {
                height: 100%;
                width: 100%;
                border-radius: 0.6rem;
                border: 0.2rem solid transparent;
                background: linear-gradient(white, white) padding-box, linear-gradient(180deg, #fafafa, #f2f2f2) border-box;
                color: var(--wolseleyGrey);

                &:hover {
                  background: #00000012;
                  border: 1px solid #d9d9d9;
                }

                &:active {
                  background: #0000001c;
                  border: 1px solid #d9d9d9;
                }
                .hide {
                  display: none;
                }
              }
            }

            .menu-wrapper {
              background: #ffffff;
              position: relative;
              margin-top: 1.6rem;
              border-radius: 0.7rem;
              padding: 2.8rem 0.8rem 0.8rem 0.8rem;

              &.flexDirectionColumn {
                gap: 0.8rem;
              }
            }
          }

          &.expanded {
            width: 27.1rem;
            position: relative;

            .side-nav {
              .toggle-wrapper {
                right: 1.3rem;
              }

              .menu-wrapper {
                padding: 1rem;
                gap: 0;
              }
            }
          }
        }

        @media (max-width: 1279px) and (min-width: 768px) {
          .side-nav-wrapper {
            .side-nav {
              .menu-wrapper {
                margin-top: 0;
                padding: 0.8rem;
              }
            }
          }
        }

        @media (max-width: 767px) {
          .side-nav-wrapper {
            height: 6rem;
            margin-left: -1rem;
            width: calc(100% + 2rem);
            padding: 1rem;
            padding-bottom: 0.5rem;

            .side-nav {
              overflow-x: auto;

              .menu-wrapper {
                margin-top: 0;
                padding: 0.4rem;
                border: 0.1rem solid #dddddd;
                flex-grow: 1;
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(SideNav), { ssr: false })
