import client from '../client'

export async function ssoSignInUser({ email,rememberMe }) {
  const params = new URLSearchParams({ email, rememberMe })
  return client(
    `${process.env.NEXT_PUBLIC_DOMAIN}/Authentication/GetTokenForSSOUsers?${params.toString()}`,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    },
  )
}
