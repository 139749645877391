import React from 'react'
import SlideInModal from '../../../components/modal/slideInModal'
import AddToCart from '.'
import { CARTMODELPRODUCTSCOUNT } from '../../../constants'
import CheckMarkIcon from '@/components/svg/checkmark'

export default function AddToCartModal({ onClose, open, headerTitle, products, displayWarningMessage = false }) {
  return (
    <SlideInModal
      onClose={onClose}
      closeOnClickOutside={false}
      headerTitle={headerTitle}
      open={open}
      icon={<CheckMarkIcon fill="#12A244" />}
      background='#F2FFF4'
      border='#12A244'
    >
      {open && (
        <AddToCart
          onClose={onClose}
          products={products}
          open={open}
          displayMoreButton={products.length > CARTMODELPRODUCTSCOUNT ? true : false}
          displayWarningMessage={displayWarningMessage}
        />
      )}
    </SlideInModal>
  )
}
