import Link from 'next/link'
import { navigationEvent } from '../../utils/ga-events/gtm-events-common'

export default function LinkAnchor({ children, disabled, onClick, ...linkProps }) {
  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault()
      event.stopPropagation()
    }
    if (!!onClick && typeof onClick == 'function') {
      //event.preventDefault()
      onClick(event)
    }
    if (!!linkProps?.navigationEvent) {
      navigationEvent(linkProps.navigationEvent.elementName, linkProps.navigationEvent.elementLocation)
    }
  }

  return (
    <Link {...linkProps}>
      <a target={linkProps.target} onClick={handleClick} name={linkProps.name} className={linkProps.className}>
        {children}
      </a>
    </Link>
  )
}
