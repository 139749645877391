// components/ErrorBoundary.js

import { Component } from 'react'

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    console.error('Client-side error:', error, errorInfo)

    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'https://www.wolseleyexpress.com/errorpage.htm?aspxerrorpath=/en/'
    }
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
