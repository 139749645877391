const HardAlert = (props) => (
  <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='68' height='48' {...props}>
    <path
      d='M0 0 C1.98 0 3.96 0 6 0 C6.3717334 0.66829834 6.7434668 1.33659668 7.12646484 2.02514648 C8.87388326 5.16376844 10.62431985 8.30069627 12.375 11.4375 C13.00680176 12.57227783 13.63860352 13.70705566 14.28955078 14.8762207 C17.17550235 20.04003609 20.07569948 25.18370495 23.15625 30.234375 C23.6198291 30.99693604 24.0834082 31.75949707 24.56103516 32.54516602 C25.41381687 33.93557098 26.27749808 35.31937126 27.15380859 36.69506836 C29.82904522 41.06318382 30 42.51922668 30 48 C12.18 48 -5.64 48 -24 48 C-25 45 -25 45 -23.91796875 42.2421875 C-23.34691406 41.13101563 -22.77585937 40.01984375 -22.1875 38.875 C-21.87417725 38.26462891 -21.56085449 37.65425781 -21.23803711 37.02539062 C-17.51848776 29.89333654 -13.37042616 23.00925208 -9.24755859 16.10595703 C-7.25237191 12.73795697 -5.31113858 9.33969132 -3.375 5.9375 C-2.72273438 4.79152344 -2.07046875 3.64554687 -1.3984375 2.46484375 C-0.93695312 1.65144531 -0.47546875 0.83804687 0 0 Z M2 16 C0.58065858 17.19190201 0.58065858 17.19190201 0.875 20.625 C0.91695535 24.49887722 1.36360941 28.18165644 2 32 C2.66 32 3.32 32 4 32 C5.03590199 26.94997778 5.10251672 22.12583611 5 17 C4.01 16.67 3.02 16.34 2 16 Z M1 36 C1.33 37.32 1.66 38.64 2 40 C2.99 39.67 3.98 39.34 5 39 C5 38.01 5 37.02 5 36 C3.68 36 2.36 36 1 36 Z '
      fill='#F7AA0F'
      transform='translate(31,0)'
    />
  </svg>
)

export default HardAlert
