import { useReducer, useState } from 'react'
import FormField from '../../components/inputs/form-field'
import Button from '../../components/button/button'
import { useMutation } from '@tanstack/react-query'
import { forgotPassword } from '../../api-client/identity/forgot-password'
import PhoneIcon from '../../components/svg/phone'
import useValidation from '../../hooks/use-validation'
import { useTranslation } from 'react-i18next'
import { logInFormSubmissionEvent } from '../../utils/ga-events/gtm-events-common'
import { validateEmail } from '../../utils/validate-email'

const isEmpty = (entry) => (entry.value === '' ? true : false)

const initialState = {
  email: { value: '' },
  lastname: { value: '' },
}

const FormFields = {
  Email: 'email',
  Lastname: 'lastname',
}

const reducer = (state, action) => ({
  ...state,
  [action.payload.field]: { value: action.payload.value },
})

export default function ForgotPassword({ onClose }) {
  const { t } = useTranslation('header')
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showErrors, { isFormValid, errors }] = useValidation(state, validate)
  const [subTitleText, setSubTitleText] = useState(t('Wca.PleaseTypeYour'))
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [formValidationError, setFormValidationError] = useState({})

  const handleChange = ({ target: { name, value } }) => {
    dispatch({ payload: { field: name, value } })
  }

  const { mutate } = useMutation(forgotPassword, {
    onSuccess: (data) => {
      if (data.Message.includes('email')) {
        setSubTitleText(t('wca.anemailhas'))
        setIsSubmitted(true)
        logInFormSubmissionEvent('successfully submitted')
      } else {
        const error = {}
        let formError = ''
        if (data.Message.includes('SSOAlert')) {
          error.email = t('wca.ssoalertforgetpassword')
          formError = 'We are unable to reset your Microsoft password from Wolseley Express'
        } else {
          error.email = t('wca.theinformationyou')
          formError =
            'The information you entered does not belong to any account. Make sure it is typed correctly'
        }
        setFormValidationError(error)
        showErrors()
        setIsSubmitted(false)
        logInFormSubmissionEvent('not submitted', formError)
      }
    },
  })

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault()
    const isEmailValid = validateEmail(state.email.value)

    if (isFormValid && isEmailValid) {
      setFormValidationError('')
      mutate({ customerLastName: state.lastname.value, customerEmail: state.email.value })
    } else {
      showErrors()
      const error = {}
      let formError = ''

      if (!state.lastname.value.trim()) {
        error.lastname = t('Account.PasswordRecovery.Wca.LastName')
        formError = 'Enter your last name'
      }

      if (!state.email.value.trim()) {
        error.email = t('wca.enteravalidemail')

        if (formError != '') {
          formError += ', Enter a valid email address'
        } else {
          formError = 'Enter a valid email address'
        }
      } else if (!isEmailValid) {
        error.email = t('wca.enteravalidemail')
        if (formError != '') {
          formError += ', ' + t('wca.enteravalidemail')
        } else {
          formError = t('wca.enteravalidemail')
        }
      }

      logInFormSubmissionEvent('not submitted', formError)
      setFormValidationError(error)
    }
  }

  return (
    <form className='wrapper' onSubmit={handleForgotPasswordSubmit}>
      <div className='flex alignCenter justifyBetween pb-2'>
        <div className='title'>{t('Wca.ForgotYourPassword')}</div>
      </div>
      <div className='subtitle flex'>
        <p>{subTitleText}</p>
      </div>
      <div className={isSubmitted ? 'formFieldItem hidden' : 'formFieldItem '}>
        <label className='forgotPasswordLastName' htmlFor='LastName'>
          {t('Account.PasswordRecovery.Wca.LastName')}
        </label>
        <FormField
          className='forgotPasswordInputs'
          type='text'
          name={FormFields.Lastname}
          error={errors.lastname}
          value={state.lastname.value}
          onChange={handleChange}
        />
        {formValidationError.lastname && (
          <span className='field-validation-error'>{formValidationError.lastname}</span>
        )}
      </div>

      <div
        className={
          isSubmitted
            ? 'formFieldItem formFieldItemEmail hidden'
            : 'formFieldItem formFieldItemEmail '
        }
      >
        <label className='forgotPasswordEmail' htmlFor='Email'>
          {t('Wca.EnterYourEmail')}
        </label>
        <FormField
          className='forgotPasswordInputs'
          type='text'
          name={FormFields.Email}
          error={errors.email}
          value={state.email.value}
          onChange={handleChange}
        />
        {formValidationError.email && (
          <span className='field-validation-error'>{formValidationError.email}</span>
        )}
      </div>
      <div
        className={
          isSubmitted
            ? 'formFieldItem formFieldItemButton hidden'
            : 'formFieldItem formFieldItemButton '
        }
      >
        <Button type='submit' className='submit'>
          {t('wca.send')}
        </Button>
      </div>
      <div
        className={
          isSubmitted
            ? 'formFieldItem formFieldItemButton hidden'
            : 'formFieldItem formFieldItemButton '
        }
      >
        <Button
          type='cancel'
          className='cancel'
          onClick={() => {
            setIsSubmitted(false)
            setSubTitleText(t('Wca.PleaseTypeYour'))
            onClose()
          }}
        >
          {t('wca.cancel')}
        </Button>
      </div>
      <div
        className={
          !isSubmitted
            ? 'formFieldItem formFieldItemButton hidden'
            : 'formFieldItem formFieldItemButton '
        }
      >
        <Button
          type='cancel'
          className='closegp'
          onClick={() => {
            setIsSubmitted(false)
            setSubTitleText(t('Wca.PleaseTypeYour'))
            onClose()
          }}
        >
          {t('wca.close')}
        </Button>
      </div>
      <div className='flex'>
        <div className='callTollFree'>
          <PhoneIcon width='1.4rem' height='1.4rem' fill='#2E5193'></PhoneIcon>
          <p>{t('wca.youcanalso')}</p>
        </div>
      </div>

      <style jsx>{`
        .field-validation-error {
          display: block;
          text-align: center;
          font-size: 13px;
          color: var(--wolseleyRed);
          margin-top: 5px;
          max-width: 98%;
          text-align: center;
        }
        .hidden {
          display: none !important;
        }
        .formFieldItemEmail {
          padding-bottom: 2rem !important;
        }

        :global(input.forgotPasswordInputs) {
          border: 1px solid #ff0000 !important;
          background-color: #efe0e0 !important;
          box-shadow: none !important;
        }
        .wrapper {
          position: relative;
          padding: 1rem 0.6rem;
        }

        .close {
          font-size: 1.8rem;
          font-weight: 400;
          background: none;
        }

        .pb-2 {
          padding-bottom: 2rem;
        }

        .mbtm-20 {
          margin-bottom: 2rem;
        }
        .title,
        .subtitle,
        .formFieldItem label {
          color: var(--wolseleyGrey);
        }
        .title,
        .formFieldItem label {
          font-weight: 700;
        }
        .title {
          font-size: 2.8rem;
          margin: 0;
          line-height: 1.4285;
        }
        .subtitle {
          font-size: 1.4rem;
          margin: 0;
          max-width: 98%;
          line-height: 1.9rem;
        }
        .formFieldItem label {
          font-size: 1.3rem;
        }
        :global(button.cancel, button.submit, button.closegp) {
          width: 100% !important;
        }
        :global(button.cancel) {
          background-color: var(--wolseleyGrey);
        }
        :global(button.closegp) {
          margin-top: 3em;
          margin-bottom: 1em;
        }
        .formFieldItemButton {
          min-width: 10rem;
          width: fit-content;
          display: inline-block;
          margin-right: 0.5rem;
          button {
            width: 100% !important;
          }
        }
        .callTollFree {
          background-color: #f2f2f2;
          margin-top: 2rem;
          padding: 1rem;
          padding-top: 2.3rem;
          min-height: 6rem;
          min-width: 100%;
          p {
            color: var(--wolseleyGrey);
            text-align: left;
            padding: 0 0 0 2.5rem;
            font-size: 1.3rem;
            margin-top: -2.9rem;
          }
        }
        .signUpInstead {
          padding: 0 0 2.3rem;
          line-height: 2.1rem;
          font-size: 1.6rem;

          button {
            color: var(--primary-color);
          }
        }

        .signInErrorText {
          padding: 2.5rem 0 0;
          color: var(--alert-color);
          line-height: 2.1rem;
          font-size: 1.6rem;
        }

        .formFieldItem {
          padding-bottom: 1rem;

          label {
            font-weight: 600;
            font-size: 1.3rem;
            padding: 1rem 0;
            display: block;
          }

          .checkbox {
            -webkit-appearance: auto;
            cursor: unset;
            margin-right: 0.8rem;
          }
        }

        :global(.btnForgotPassword) {
          font-weight: 600;
          font-size: 1.3rem !important;
        }

        .separator {
          border-top: 1px solid #8b8787;
          padding: 2rem 0 0;
          margin-top: 1rem;
        }

        .txt {
          font-size: 1.3rem;
        }

        .resetPassword {
          text-align: center;
          padding: 2.5rem 0 1.4rem;
          line-height: 1.9rem;
          font-size: 1.4rem;

          button {
            color: var(--primary-color);
          }
        }

        .forgotPasswordLastName,
        .forgotPasswordEmail {
          color: #3b3b3b !important;
        }

        @media (max-width: 786px) {
          .wrapper {
            width: 100%;
            padding: 1.6rem 2rem 4.1rem;
          }

          .title {
            padding: 0 0 0.5rem;
            line-height: 2.4rem;
            font-size: 1.8rem;
          }
        }
      `}</style>
    </form>
  )
}

function validate(state) {
  const errors = {}
  let isValid = true

  for (const field of Object.keys(state)) {
    if (isEmpty(state[field])) {
      errors[field] = true
      isValid = false
    }
  }

  return { isValid, errors }
}
