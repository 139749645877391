import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import Cookies from 'universal-cookie'
import RegionModal from '@/containers/region/region-modal'
import { CookiesKey } from '@/constants'
import { useMutation } from '@tanstack/react-query'
import { saveRegion } from '@/api-client/profile/save-region'
import { useAuth } from './auth'

const cookie = new Cookies()
const RegionContext = createContext()

export const RegionProvider = ({ children }) => {
  const [regionModal, setRegionModal] = useState({ open: false })
  const [selectedRegionId, setRegion] = useState(0)
  const [isRegionLoading, setIsRegionLoading] = useState(true)

  const [{ isAuthenticated, activeProfile }] = useAuth()
  const updateRegion = (selectedRegionId) => {
    setRegion(selectedRegionId)
    cookie.set(CookiesKey.SELECTED_REGION_ID, selectedRegionId, { path: '/' })
    mutate({
      regionId: selectedRegionId,
    })
    setIsRegionLoading(false)
  }

  const openRegionModal = () => setRegionModal({ open: true })

  const closeRegionModal = () => setRegionModal({ open: false })

  const { mutate } = useMutation(saveRegion)

  const contextValue = useMemo(() => {
    return { selectedRegionId, isRegionLoading, updateRegion, openRegionModal, closeRegionModal }
  }, [selectedRegionId, isRegionLoading, updateRegion, openRegionModal, closeRegionModal])

  useEffect(() => {
    const regionId = cookie.get(CookiesKey.SELECTED_REGION_ID)
    if (!!regionId) {
      updateRegion(regionId)
    }
    else if (!!!regionId && !!!selectedRegionId){
       setIsRegionLoading(false)
    }
  }, [isAuthenticated, activeProfile])

  return (
    <RegionContext.Provider value={contextValue}>
      {children}
      {regionModal.open && <RegionModal onClose={closeRegionModal} />}
    </RegionContext.Provider>
  )
}

export const useRegion = () => {
  const context = useContext(RegionContext)
  return context
}
