import { useMutation } from '@tanstack/react-query'
import { signInUser } from './../../../api-client/identity/sign-in'
import Login from '../views/login'
import { useAuth } from './../../../contexts/auth'
import Loader from './../../../components/loader'
import { logInCompleteEvent } from '../../../utils/ga-events/gtm-events-common'
import { msalInstance } from '../../../services/sso/auth-sso'
import { ssoSignInUser } from '../../../api-client/identity/sso-sign-in'
import { useState } from 'react'
import { logInFailureEvent } from '../../../utils/ga-events/gtm-events-common'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'
import { CookiesKey } from '@/constants'

const cookie = new Cookies()

export default function AuthModal({ onClose, handleForgotPasswordModal }) {
  const [, { login, setOpenSwitchProfileModal }] = useAuth()
  const [validationMessage, setValidationMessage] = useState('')
  const [rememberMeValue, setRememberMeValue] = useState(false)
  const loginRequest = {
    scopes: ['user.read'],
    prompt: 'select_account',
  }
  const { t } = useTranslation('header')
  const router = useRouter()
  const { locale, pathname } = router
  const languageId = /en/i.test(locale) ? 1 : 2
  const returnClassName = () => {
    if (locale == 'fr') return 'r0'
    else return ''
  }

  const handleLoginSuccess = ({ token, validationMessage, selectedProfileId, profileModel }) => {
    if (!!!validationMessage) {
      if (selectedProfileId > 0) {
        updateProfile(selectedProfileId)
        if (profileModel?.length > 0) {
          if (!!profileModel[0]?.region) {
            updateRegion(profileModel[0]?.region)
          }
        }
        login({ token })
        logInCompleteEvent()
        if (pathname?.includes('/search') || pathname?.includes('/category/')) {
          router.reload()
        } else onClose()
      } else {
        sessionStorage.setItem('isLogin', true)
        onClose()
        setOpenSwitchProfileModal({ open: true, profiles: { ...profileModel }, token: token })
      }
    } else {
      setValidationMessage(mapServerValidationError(t, validationMessage))
    }
  }
  const updateProfile = (selectedProfileId) => {
    cookie.set(CookiesKey.SELECTED_PROFILE_ID, selectedProfileId, { path: '/' })
  }
  const { mutate: loginMutation, isLoading: signingUserIn } = useMutation(signInUser, {
    onSuccess: handleLoginSuccess,
  })
  const updateRegion = (selectedRegionId) => {
    cookie.set(CookiesKey.SELECTED_REGION_ID, selectedRegionId, { path: '/' })
  }

  const handleLoginSubmit = (form) => {
    loginMutation(form)
  }

  const onSSoLogin = () => {
    if (languageId === 2) {
      loginRequest.extraQueryParameters = { lc: '1036' }
    }
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        mutateLogin({ email: response.account.userName, rememberMe: rememberMeValue })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const { mutate: mutateLogin } = useMutation(ssoSignInUser, {
    onSuccess: handleLoginSuccess,
  })

  const handleRememberMeCallBack = (rem) => {
    setRememberMeValue(rem)
  }
  const mapServerValidationError = (t, error) => {
    let errorMessage = ''
    if (!error) return {}

    if (error.startsWith('LockedOut - ')) {
      return t('Wca.LockedOutFor1') + ' ' + error.split('LockedOut - ')[1] + ' ' + t('Wca.LockedOutFor2')
    }
    switch (error) {
      case 'CustomerNotExist':
        errorMessage = t('account.login.wrongcredentials.customernotexist')
        logInFailureEvent(errorMessage)
        return errorMessage
      case 'WrongPassword':
        errorMessage = t('account.login.wrongcredentials')
        logInFailureEvent(errorMessage)
        return errorMessage
      case 'WrongCredentials':
        errorMessage = t('account.login.wrongcredentials')
        logInFailureEvent(errorMessage)
        return errorMessage
      case 'NotActive':
        errorMessage = t('account.login.wrongcredentials.notactive')
        logInFailureEvent(errorMessage)
        return errorMessage
      case 'Deleted':
        errorMessage = t('account.login.wrongcredentials.deleted')
        logInFailureEvent(errorMessage)
        return errorMessage
      case 'NotRegistered':
        errorMessage = t('account.login.wrongcredentials.notregistered')
        logInFailureEvent(errorMessage)
        return errorMessage
      case 'LockedOutInitial':
        errorMessage = t('Wca.LockedOutInitial')
        logInFailureEvent(errorMessage)
        return errorMessage
      default:
        return t('account.login.wrongcredentials')
    }
  }
  return (
    <>
      <div className='wrapper'>
        <ul className={`dropdown-menu signin-dropdown ${returnClassName()}`}>
          <li>
            <div className='flex alignCenter justifyBetween'>
              <Login
                onSubmit={handleLoginSubmit}
                disabled={signingUserIn}
                onClose={onClose}
                handleSSOLogin={onSSoLogin}
                handleForgotPasswordModal={handleForgotPasswordModal}
                validationMessage={validationMessage}
                rememberMeCallBack={handleRememberMeCallBack}
              ></Login>
            </div>
          </li>
        </ul>
      </div>

      {signingUserIn && <Loader delay={300} fullPage />}

      <style jsx>{`
        .wrapper {
          position: relative;
        }
        .signin-dropdown {
          position: absolute;
          background-color: #fff;
          max-height: 70rem;
          width: 38rem;
          left: auto;
          right: -55px;
          top: 2rem;
          border-radius: 0;
          padding: 0;
          font-weight: normal;
          font-size: 1.4rem;
          border: 1px solid #ccc;
          color: #444;
          overflow: inherit;
          padding: 1.5rem 1.5rem 0rem 1.5rem;
          box-shadow: 0 20px 10px rgba(0, 0, 0, 0.15);
        }

        .signin-dropdown:before {
          content: '';
          position: absolute;
          border-color: rgba(194, 225, 245, 0);
          border: solid transparent;
          border-bottom-color: white;
          border-width: 12px;
          margin-left: -11px;
          top: -21px;
          left: 199px;
          z-index: 1;
        }

        .signin-dropdown:after {
          content: '';
          position: absolute;
          left: 190px;
          top: -21px;
          width: 0;
          height: 0;
          border: solid transparent;
          border-width: 10px;
          border-bottom-color: #ccc;
          z-index: 0;
        }

        @media (max-width: 768px) {
          .signin-dropdown {
            position: absolute;
            width: 310px;
            min-width: 30rem;
            overflow: visible;
            top: 0rem;
            padding: 0;
            animation-name: slideInFromTopLeft;
            animation-duration: 0.2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
            animation-direction: alternate;
            z-index: 1005 !important;
          }
          .r0 {
            right: 0;
          }
          .signin-dropdown:before {
            content: '';
            position: absolute;
            border-color: rgba(194, 225, 245, 0);
            border: solid transparent;
            border-bottom-color: white;
            border-width: 12px;
            margin-left: -11px;
            top: -21px;
            left: 159px;
            z-index: 1;
          }

          .signin-dropdown:after {
            content: '';
            position: absolute;
            left: 150px;
            top: -21px;
            width: 0;
            height: 0;
            border: solid transparent;
            border-width: 10px;
            border-bottom-color: #ccc;
            z-index: 0;
          }
        }
        @keyframes slideInFromTopLeft {
          0% {
            transform: translateX(-100%) translateY(-50%);
          }
          100% {
            transform: translateX(0%) translateY(0%);
          }
        }
      `}</style>
    </>
  )
}
