import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { getAllProfiles, getAllProfilesKey } from '../../api-client/profile/get-all-profiles'
import { setProfile } from '../../api-client/profile/set-profile'
import { useAuth } from '../../contexts/auth'
import { logInCompleteEvent, navigationEvent } from '../../utils/ga-events/gtm-events-common'
import { useRouter } from 'next/router'
import { useUrl } from 'nextjs-current-url'
import Paging from '../../components/paging'
import ArrowBlackUp from '../../components/svg/arrow-black-up'
import ArrowBlackDown from '../../components/svg/arrow-black-down'
import { setCustomParameters } from '../../utils/set-custom-parameters'
import { useTranslation } from 'react-i18next'
import TableRows from '../../components/table/table-rows'
import { DesktopTablet, Mobile } from '../../components/media'
import { saveLanguage } from '@/api-client/language/set-language'
import { CookiesKey } from '@/constants'
import Cookies from 'universal-cookie'

const cookie = new Cookies()
const sortByOption = {
  none: 0,
  account: 1,
  region: 2,
}
const sortByDirectionOption = {
  ascending: 0,
  descending: 1,
}

export default function SwitchProfile({ onClose, profiles, token = null }) {
  const { t } = useTranslation('switchProfile')
  const [{ isAuthenticated }, { login, logout, refetchActiveProfile }] = useAuth()
  const router = useRouter()
  const { href } = useUrl() ?? {}

  const pageSize = 8
  const [pageIndex, setPageIndex] = useState(0)
  const [skip, setSkip] = useState(0)

  const [sortBy, setSortBy] = useState(sortByOption.region)
  const [sortByDirection, setSortByDirection] = useState(sortByDirectionOption.ascending)
  const [profileData, setProfileData] = useState([])
  const [originalProfileData, setOriginalProfileData] = useState(() => {
    if (profiles) return Object.keys(profiles).map((key) => profiles[key])
    else return []
  })
  const { mutate: mutateLanguage } = useMutation(saveLanguage, {
    onSuccess: (data) => {
      window.location.href = `${data.redirectUrl}`
    },
  })
  const { mutate: switchProfile } = useMutation(setProfile, {
    onSuccess: (data, variables) => {
      if (data && data < 1) logout()
      else {
        if (token) login({ token })
        else {
          refetchActiveProfile()
        }
        updateRegion(variables.selectedRegionId)
        updateProfile(variables.profileId)
        onClose()
        setCustomParameters()
        const { locale } = router
        // The logic below should only be applied when an unauthenticated user is on the FR PLP page. If the user logs in and selects English, then we need to redirect them to the English page.
        if (!(variables.selectedRegionId == 1 || variables.selectedRegionId == 5) && locale?.toLowerCase() == 'fr' && window.location.href.indexOf('/categorie/') !== -1) {
          mutateLanguage({ languageId: 1, url: window.location.href })
        } else {
          if (isAuthenticated && process.env.NODE_ENV !== 'development') {
            if (!(variables.selectedRegionId == 1 || variables.selectedRegionId == 5) && locale?.toLowerCase() == 'fr') {
              window.location.href = '/en/MyWolseley/home'
            } else {
              window.location.href = `/${locale?.toLowerCase()}/MyWolseley/Home`
            }
          } else {
            if (!(variables.selectedRegionId == 1 || variables.selectedRegionId == 5) && locale?.toLowerCase() == 'fr') {
              window.location.href = `${href.replace('/fr', '/en').replace('/produit', '/product')}`
            } else router.reload()
          }
        }
      }
    },
    onError: () => {
      logout()
    },
  })

  const { isFetching } = useQuery({
    queryKey: [getAllProfilesKey],
    cacheTime: 1000 * 60 * 60 * 24,
    queryFn: getAllProfiles,
    enabled: !!!profiles,
    onSuccess: (data) => {
      setProfileData(data)
      setOriginalProfileData(data)
    },
  })

  const handleProfileClick = useCallback(
    (profile) => {
      switchProfile({
        profileId: profile.id,
        selectedRegionId: profile.region,
        token: token,
      })
      const isLogin = sessionStorage.getItem('isLogin')
      if (isLogin == 'true') {
        logInCompleteEvent()
        sessionStorage.removeItem('isLogin')
      }
      navigationEvent('Switch Profile', 'Header')
    },
    [switchProfile, token],
  )

  const handlePageChange = (page) => {
    setPageIndex(page)
    setSkip(page * pageSize)
  }
  const updateProfile = (selectedProfileId) => {
    cookie.set(CookiesKey.SELECTED_PROFILE_ID, selectedProfileId, { path: '/' })
  }
  const updateRegion = (selectedRegionId) => {
    cookie.set(CookiesKey.SELECTED_REGION_ID, selectedRegionId, { path: '/' })   
  }
  useEffect(() => {
    const profiles = [...originalProfileData]
    switch (sortBy) {
      case sortByOption.account:
        profiles.sort((a, b) => {
          return (a.customerNumber ?? '').localeCompare(b.customerNumber ?? '', undefined, {
            sensitivity: 'base',
          })
        })
        break
      case sortByOption.region:
        profiles.sort((a, b) => {
          return (a.regionName ?? '').localeCompare(b.regionName ?? '', undefined, {
            sensitivity: 'base',
          })
        })
        break
      default: {
        profiles.sort((a, b) => {
          return (a.regionName ?? '').localeCompare(b.regionName ?? '', undefined, {
            sensitivity: 'base',
          })
        })
      }
    }

    if (sortByDirection == sortByDirectionOption.descending) {
      profiles.reverse()
    }

    setProfileData(profiles)
  }, [sortBy, sortByDirection])

  const totalCount = profileData?.length
  const totalPages = totalCount ? Math.ceil(totalCount / pageSize) : 1

  return (
    <>
      <div className='wrapper'>
        <h4 className='title'>{token ? t('wca.selectaprofile') : t('wca.switchprofile')}</h4>
        <table className='grid'>
          <thead>
            <tr>
              <th className='account-number'>
                <label
                  className='account-number-col flex alignCenter'
                  onClick={() => {
                    setSortBy(sortByOption.account)
                    setSortByDirection(
                      sortBy == sortByOption.region
                        ? sortByDirectionOption.ascending
                        : sortByDirection == sortByDirectionOption.ascending
                        ? sortByDirectionOption.descending
                        : sortByDirectionOption.ascending,
                    )
                  }}
                >
                  <DesktopTablet>{t('wca.accountnumber').toUpperCase()}</DesktopTablet>
                  <Mobile>{t('wca.customernumber').toUpperCase()}</Mobile>
                  {sortBy == sortByOption.account && <span>{sortByDirection == sortByDirectionOption.descending ? <ArrowBlackDown /> : <ArrowBlackUp />}</span>}
                </label>
              </th>
              <th className='region'>
                <label
                  className='region-col flex alignCenter'
                  onClick={() => {
                    setSortBy(sortByOption.region)
                    setSortByDirection(sortByDirection == sortByDirectionOption.ascending ? sortByDirectionOption.descending : sortByDirectionOption.ascending)
                  }}
                >
                  {t('wca.region').toUpperCase()}
                  {sortBy == sortByOption.region && <span>{sortByDirection == sortByDirectionOption.descending ? <ArrowBlackDown /> : <ArrowBlackUp />}</span>}
                </label>
              </th>
              <th className='profile-role'>
                <label>{t('wca.profilerole').toUpperCase()}</label>
              </th>
              <th className='tag'>
                <label>{t('wca.tag').toUpperCase()}</label>
              </th>
            </tr>
          </thead>
          <tbody>{!isFetching && !!profileData?.length && <TableRows data={profileData} pageIndex={pageIndex} pageSize={pageSize} handleProfileClick={handleProfileClick} />}</tbody>
        </table>

        {totalPages > 1 && (
          <Paging
            onFirst={() => setPageIndex(0)}
            onLast={() => setPageIndex(totalPages - 1)}
            onPrevious={() => handlePageChange(pageIndex - 1)}
            onNext={() => handlePageChange(pageIndex + 1)}
            onChangePageNumber={handlePageChange}
            pageIndex={pageIndex}
            totalPages={totalPages}
            skip={skip}
            pageSize={pageSize}
          />
        )}
      </div>
      <style jsx>{`
        .title {
          font-size: 2.4rem;
          font-weight: bold;
          color: var(--wolseleyGrey);
          margin-bottom: 3rem;
        }

        .grid {
          width: 100%;
          border-spacing: 0;
          border-bottom: 2px solid #ccc;

          tr {
            th {
              width: 25%;
              font-weight: 600;
              border-bottom: 2px solid #ccc;
            }
            td {
              border-top: 1px dashed #ccc;

              :global(.customerNumber) {
                color: var(--wolseleyRed);
                text-decoration: none;
              }
            }
            td,
            th {
              text-align: left;
              color: var(--wolseleyGrey);
              padding: 1.5rem 1rem;
            }
          }

          .account-number-col,
          .region-col {
            cursor: pointer;
          }
        }

        @media (min-width: 769px) {
          .grid {
            min-width: 70rem;

            td,
            th {
              padding: 2rem;
              font-size: 1.3rem;
            }
          }
        }
      `}</style>
    </>
  )
}
