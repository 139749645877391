import React from 'react'
import Modal from '../../components/modal'
import Message from '.'

export default function MessageModal({ onClose }) {
  return (
    <>
      <Modal onClose={onClose} closeOnClickOutside={false}>
        <div className='wrapper'>
          <div className='topRedLine'></div>
          <Message onClose={onClose} />
        </div>
      </Modal>
      <style jsx>{`
        :global(.content) {
          top: 0px;
        }
        .wrapper {
          padding: 3rem;
          border-top: 4px solid #ccc;
          position: relative;
          width: 80rem;

          .topRedLine {
            height: 4px;
            position: absolute;
            left: 0;
            top: -4px;
            width: 12rem;
            background: var(--wolseleyRed);
          }
        }

        @media (max-width: 768px) {
          .wrapper {
            width: 100%;
          }
        }
      `}</style>
    </>
  )
}
