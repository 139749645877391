import client from '../client'

export async function saveLanguage({ languageId, url }) {
  const params = new URLSearchParams({ languageId, url })
  return client(
    `${process.env.NEXT_PUBLIC_DOMAIN}/Language/SetLanguage?${params.toString()}`,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    },
  )
}
