import { getTrackingScript } from '../../api-client/gtm/get-tracking-script'
import { useEffect } from 'react'

const TrackingScript = () => {
  useEffect(() => {
    appendGtmTrackingScript()
  }, [])

  async function appendGtmTrackingScript() {
    if (
      document.getElementById('gtmScriptTracking') == null ||
      document.getElementById('gtmScriptTracking') == undefined
    ) {
      const scriptString = await getTrackingScript()

      if (scriptString) {
        // Create a temporary container element
        let tempContainer = document.createElement('div')
        tempContainer.innerHTML = scriptString.replaceAll('\\', '').replaceAll('\r\n', '')

        // Extract script and noscript elements from the container
        let scriptElements = Array.from(tempContainer.getElementsByTagName('script'))
        let noscriptElements = Array.from(tempContainer.getElementsByTagName('noscript'))

        // Append script elements to the head
        scriptElements.forEach((script) => {
          let newScript = document.createElement('script')
          newScript.async = script.async
          newScript.src = script.src
          document.head.appendChild(newScript)
        })

        // Append noscript elements to the head
        noscriptElements.forEach((noscript) => {
          let newNoscript = document.createElement('noscript')
          newNoscript.innerHTML = noscript.innerHTML
          document.head.appendChild(newNoscript)
        })
      }
    }
  }
}

export default TrackingScript
