export default function LinkIcon(props) {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='#1362D0' stroke='#1362D0' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.28124 1.83008C2.40104 1.83008 1.68749 2.54362 1.68749 3.42383V10.8613C1.68749 11.7415 2.40104 12.4551 3.28124 12.4551H10.7187C11.5989 12.4551 12.3124 11.7415 12.3124 10.8613V8.49114C12.3124 8.19773 12.5503 7.95989 12.8437 7.95989C13.1371 7.95989 13.3749 8.19773 13.3749 8.49114V10.8613C13.3749 12.3283 12.1857 13.5176 10.7187 13.5176H3.28124C1.81424 13.5176 0.625 12.3283 0.625 10.8613V3.42383C0.625 1.95682 1.81424 0.767578 3.28124 0.767578H5.65142C5.94482 0.767578 6.18267 1.00543 6.18267 1.29883C6.18267 1.59223 5.94482 1.83008 5.65142 1.83008H3.28124ZM7.81733 1.29883C7.81733 1.00543 8.05518 0.767578 8.34858 0.767578H12.8438C13.1372 0.767578 13.375 1.00543 13.375 1.29883V5.79402C13.375 6.08742 13.1372 6.32527 12.8438 6.32527C12.5504 6.32527 12.3125 6.08742 12.3125 5.79402V2.58138L8.72423 6.16967C8.51676 6.37714 8.1804 6.37714 7.97293 6.16967C7.76547 5.96221 7.76547 5.62584 7.97293 5.41837L11.5612 1.83008H8.34858C8.05518 1.83008 7.81733 1.59223 7.81733 1.29883Z'
        strokeWidth='0.53125'
        strokeLinecap='round'
      />
    </svg>
  )
}
