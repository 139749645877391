import { CartItemSourceTypes, CookiesKey, mainSite, ProductConstants } from '../../constants'
import { useMutation } from '@tanstack/react-query'
import Cookies from 'universal-cookie'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useCartCount from '../../hooks/use-cart-count'
import { addProductToCart } from '@/api-client/cart/add-product-to-cart'
import AddToCartModal from '@/containers/product/add-to-cart/add-to-cart-modal'
import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'
import { useAuth } from '@/contexts/auth'
import { useTranslation } from 'react-i18next'
import { addToCartGAEvent } from '../../utils/ga-events/gtm-events-pdp'
import { getProductRoute } from '@/utils/route'
import { replaceSpecialChars } from '@/utils/replace-special-chars'
import { useRouter } from 'next/router'

const cookie = new Cookies()

const DynamicYieldAdd2Cart = () => {
  const deviceType = useDeviceDetect()
  const [{}, { refetchCartCount }] = useCartCount()
  const [{ isAuthenticated }] = useAuth()
  const { t } = useTranslation(['common'])
  const { locale } = useRouter()

  const [dyProduct, setdyProduct] = useState(false)
  const [openAddToCartModal, setOpenAddToCartModal] = useState(ProductConstants.initialAddToCartModalOptions)

  const { mutate: mutateAddToCart } = useMutation(addProductToCart, {
    onSuccess: (data) => {
      if (!!data.success) {
        if (isAuthenticated) {
          refetchCartCount()
          setOpenAddToCartModal({
            open: true,
            title: `1 ${t('wca.itemaddedtocart', { ns: 'common' })}`,
            products: [
              {
                picture: dyProduct?.imageUrl,
                qty: dyProduct?.quantity,
                prodDesc: dyProduct?.shortDescription,
                unitOfMeasure: dyProduct?.unitOfMeasure,
                price: dyProduct?.price,
                subTotal: dyProduct?.price * dyProduct?.quantity,
              },
            ],
          })
          addToCartGAEvent(dyProduct, String(dyProduct?.price), dyProduct?.quantity)
        } else {
          sessionStorage.setItem('addToCartQty', dyProduct?.quantity)
          const returnPdp = `${getProductRoute(locale)}/${replaceSpecialChars(dyProduct?.shortDescription)}/${dyProduct.sku}`
          window.location.href = `${mainSite}login?ReturnUrl=${returnPdp}`
        }
      }
    },
  })
  const handleAddToCartDY = (product) => {
    const profileId = cookie.get(CookiesKey.SELECTED_PROFILE_ID, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    })
    setdyProduct(product)
    mutateAddToCart({
      productId: product?.id,
      orderedQuantity: product?.quantity,
      profileId: isAuthenticated ? profileId : -1,
      deviceType: deviceType.isMobile ? 'Mobile' : 'Desktop',
      cartSource: CartItemSourceTypes.ThirdParty,
    })
  }

  useEffect(() => {
    const handleAddToCartDYEvent = (event) => {
      handleAddToCartDY(event.detail)
    }
    window.addEventListener('addToCartDY', handleAddToCartDYEvent)
    return () => {
      window.removeEventListener('addToCartDY', handleAddToCartDYEvent)
    }
  }, [])
  return (
    <>
      <AddToCartModal
        onClose={() => setOpenAddToCartModal(ProductConstants.initialAddToCartModalOptions)}
        open={openAddToCartModal.open}
        headerTitle={openAddToCartModal.title}
        products={openAddToCartModal.products}
      />
    </>
  )
}

export default dynamic(() => Promise.resolve(DynamicYieldAdd2Cart), {
  ssr: false,
})
