import { AccountOverview } from '@/components/svg/account-overview'
import InvoiceIcon from '@/components/svg/invoice'
import LinkIcon from '@/components/svg/link'
import { ManageAccount } from '@/components/svg/manageaccount'
import { MyBusiness } from '@/components/svg/my-business'
import { MyAccountConstants } from '@/containers/myaccount/constants'
import { Orders } from '@/components/svg/orders'
import { QuickTools } from '@/components/svg/quickTools'
import { Quotes } from '@/components/svg/quotes'
import { Resources } from '@/components/svg/resources'
import { Lists } from '@/components/svg/lists'
import MyOrder from '@/components/svg/my-order'

export default function getMenuIcon(iconName) {
  switch (iconName) {
    case MyAccountConstants.menuIcons.quickTools:
      return <QuickTools />
    case MyAccountConstants.menuIcons.accountOverview:
      return <AccountOverview />
    case MyAccountConstants.menuIcons.quotes:
      return <Quotes />
    case MyAccountConstants.menuIcons.billing:
      return <InvoiceIcon fill='currentColor' />
    case MyAccountConstants.menuIcons.invoiceGateway:
      return <LinkIcon fill='currentColor' stroke='currentColor' />
    case MyAccountConstants.menuIcons.orders:
      return <Orders />
    case MyAccountConstants.menuIcons.manageAccount:
      return <ManageAccount />
    case MyAccountConstants.menuIcons.myBusiness:
      return <MyBusiness />
    case MyAccountConstants.menuIcons.resources:
      return <Resources />
    case MyAccountConstants.menuIcons.lists:
      return <Lists />
    case MyAccountConstants.menuIcons.myorder:
      return <MyOrder />
  }

  return
}
