import { useIdleTimer } from 'react-idle-timer'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../contexts/auth'
import Modal from '../modal'
import Button from '../button/button'
import InfoIconBlue from '../svg/info-icon-blue'
import { useUrl } from 'nextjs-current-url'
import { mainSite } from '../../constants'
import { getSessionTimeout, getSessionTimeoutKey } from '@/api-client/common/session-timeout'
import { useState } from 'react'

const SessionTimeoutModal = ({}) => {
  const { t } = useTranslation('header')
  const [{ isAuthenticated }, { sessionTimeoutMutate }] = useAuth()
  const { pathname } = useUrl() ?? {}

  const [openSessionTimeoutModal, setOpenSessionTimeoutModal] = useState(false)

  const { data: timeoutDuration } = useQuery({
    queryKey: [getSessionTimeoutKey],
    queryFn: getSessionTimeout,
    enabled: isAuthenticated,
  })

  const onIdle = () => {
    if (isAuthenticated && !!timeoutDuration) {
      sessionTimeoutMutate({ token: null })
      setOpenSessionTimeoutModal(true)
    }
  }

  const onClose = () => {
    if (pathname?.toLowerCase().startsWith('/en')) window.location.href = `${mainSite}en`
    else window.location.href = `${mainSite}fr`
  }

  useIdleTimer({
    timeout: timeoutDuration * 60 * 1000,
    onIdle,
  })

  if (!openSessionTimeoutModal) return null

  return (
    <div>
      {openSessionTimeoutModal && (
        <Modal onClose={onClose} closeOnClickOutside={false} top='6rem'>
          <div className='wrapper'>
            <div className='topRedLine'></div>
            <div className='flex'>
              <div>
                <InfoIconBlue />
              </div>
              <div className='content'>
                <h4 className='title'>{t('wca.sessiontimeout')}</h4>
                <p className='message'>{t('wca.duetoan')}</p>
              </div>
            </div>
            <div className='flex flexDirectionColumn alignCenter'>
              <Button onClick={onClose}>{t('wca.ok')}</Button>
            </div>
          </div>
        </Modal>
      )}
      <style jsx>{`
        .wrapper {
          padding: 3rem;
          border-top: 4px solid #ccc;
          position: relative;

          .topRedLine {
            height: 4px;
            position: absolute;
            left: 0;
            top: -4px;
            width: 12rem;
            background: var(--wolseleyRed);
          }

          .content {
            width: 85%;
            min-height: 20rem;
            padding-left: 2rem;

            .title {
              padding: 1rem 0;
              font-size: 2.4rem;
              font-weight: 600;
              color: #0c64b4;
              border-bottom: 1px dotted #d8d8d8;
            }

            .message {
              padding-top: 2rem;
              font-size: 1.8rem;
              line-height: 2.5rem;
              color: var(--wolseleyGrey);
            }
          }

          :global(button) {
            width: 8.4rem;
          }
        }
      `}</style>
    </div>
  )
}

export default SessionTimeoutModal
