import { useRouter } from 'next/router'
import { REGIONS } from '../../constants'
import { useTranslation } from 'react-i18next'
import { useRegion } from '@/contexts/region-context'

export default function Regions({ onClose }) {
  const { updateRegion } = useRegion()
  const { locale, reload } = useRouter()
  const { t } = useTranslation('header')

  const handleRegionClick = (region) => {
    updateRegion(region.id)
    onClose()
    reload()
  }

  return (
    <>
      <div className='wrapper'>
        <h4 className='title'>{t('wca.pleaseselectyourregion')}</h4>
        {REGIONS?.map((region) => {
          return (
            <div className='card' key={region.id}>
              <a className='region' onClick={() => handleRegionClick(region)}>
                {locale === 'en' ? region.name : region.nameFR}
              </a>
            </div>
          )
        })}
      </div>
      <style jsx>{`
        .wrapper {
          min-width: 100%;

          .title {
            font-size: 2.8rem;
            font-weight: bold;
            color: var(--wolseleyGrey);
            padding-bottom: 3rem;
            width: 92%;
          }
          .card {
            margin: 1rem 0;
            border-bottom: 2px solid #ccc;
            background-color: transparent;
            padding: 1rem 2rem;

            .region {
              font-size: 1.82rem;
              font-weight: bold;
              color: #8c8c8c;
            }
            .region:hover {
              color: var(--wolseleyRed);
              cursor: pointer;
            }
          }
        }

        @media (max-width: 768px) {
          .card {
            width: 99%;
          }
          .title {
            font-size: 2rem !important;
          }
        }

        @media (max-width: 430px) {
          .wrapper {
            .title {
              width: 80% !important;
            }
            .card {
              width: 100%;
              .region {
                font-size: 1.3rem;
                color: #8c8c8c;
              }
            }
          }
          :global(.close) {
            top: 2rem !important;
          }
        }
      `}</style>
    </>
  )
}
