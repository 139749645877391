import padStart from '../../utils/pad-start'
import LinkAnchor from '../button/link-anchor'

const TableRows = ({ data, pageIndex, pageSize, handleProfileClick }) => {
  return (
    <>
      {!!data?.length &&
        data
          ?.slice(pageIndex * pageSize)
          .slice(0, pageSize)
          ?.map((profile) => {
            return (
              <tr key={`profile_${profile.id}`}>
                <td>
                  <LinkAnchor
                    href=''
                    disabled={true}
                    className='customerNumber'
                    onClick={() => handleProfileClick(profile)}
                  >
                    {padStart(profile?.customerNumber)}
                  </LinkAnchor>
                </td>
                <td>{profile?.regionName}</td>
                <td>{profile?.roleName}</td>
                <td>{profile?.tag}</td>
              </tr>
            )
          })}
      <style jsx>{`
        tr {
          td {
            border-top: 1px dashed #ccc;
            text-align: left;
            color: var(--wolseleyGrey);
            padding: 1.5rem 1rem;

            :global(.customerNumber) {
              color: var(--wolseleyRed);
              text-decoration: none;
            }
          }
        }

        @media (min-width: 769px) {
          td {
            padding: 2rem;
            font-size: 1.3rem;
          }
        }
      `}</style>
    </>
  )
}

export default TableRows
