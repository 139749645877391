export const LOCALE = {
  EN: 'en',
  FR: 'fr',
}

export const INVOICE_IMAGE_PATH = `${process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN}Content/Images/`

export const ROUTE_NAME = {
  PDP: 'product',
  PDP_FR: 'produit',
  PLP: 'category',
  PLP_FR: 'categorie',
}

export const ROUTES = {
  Home: '/',
  ProductDetails: '/product',
  ProductDetailsFR: '/produit',
  ShoppingCart: '/cart',
  Checkout: '/checkout',
  OldCheckout: '/checkout/cart/',
  WebQuote: '/WebQuote/GetWebQuote',
  MyWolseley: '/MyWolseley/Home',
  MyOrders: '/OrderHistory/MyOrderHistory',
  DraftOrders: '/DraftOrder/MyDraftOrder',
  AllWebQuote: '/WebQuote/GetAllWebQuote',
  InvoiceGateway: '/billTrustRedirect',
  SpendTracker: '/MyWolseley/AccountSummaryInfo',
  UploadSpreadsheet: '/MyWolseley/Upload',
  Mysettings: '/MySettings',
  Paymentmethods: '/ManagePayment/Preferences',
  Usermanagement: '/AccountManagement/GetAccountInformation',
  WebsiteTutorials: '/website-tutorials',
  QuickReferenceGuide: '/uploaded/Useful%20Links/Wolseley%20Express%20Quick%20Tips%20booklet%20final%20ENG.pdf',
  Support: '/SupportPage/GetSupportPage/',
  MyOrderedProductsTop100: '/List/MyList#MyOrderedProducts',
  MyLists: '/List/MyList',
  ExpressLists: '/List/MyList#ExpressListTab',
  CreateQuote: '/Quotation/QuoteList',
  OrderDetails: '/orderhistory/orderdetails?Id=',
}

export const DATE_FORMATS = {
  'yyyy-MM-dd': 'yyyy-MM-dd',
  'MMM dd, yyyy': 'MMM dd, yyyy',
  'dd MMM, yyyy': 'dd MMM, yyyy',
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'M/d/yyyy': 'M/d/yyyy',
}

export const MONTH_BILINGUAL_TEXTS = [
  {
    EN: 'Jan',
    FR: 'JAN',
  },
  {
    EN: 'Feb',
    FR: 'FÉV',
  },
  {
    EN: 'Mar',
    FR: 'MAR',
  },
  {
    EN: 'Apr',
    FR: 'AVR',
  },
  {
    EN: 'May',
    FR: 'MAI',
  },
  {
    EN: 'Jun',
    FR: 'JUN',
  },
  {
    EN: 'Jul',
    FR: 'JUL',
  },
  {
    EN: 'Aug',
    FR: 'AOÛ',
  },
  {
    EN: 'Sep',
    FR: 'SEP',
  },
  {
    EN: 'Oct',
    FR: 'OCT',
  },
  {
    EN: 'Nov',
    FR: 'NOV',
  },
  {
    EN: 'Dec',
    FR: 'DÉC',
  },
]

export const ERRORS = {
  GENERAL_SERVER_ERROR: 'Something went wrong. Please try again.',
}

export const zIndex = {
  Negative: -10000,
  Ground: 0,
  Layout: 10000,
  Absolute: 20000,
  Fixed: 30000,
  Modal: 40000,
  zMax: 90000,
}

export const UNITS = {}

export const CookiesKey = {
  RECENT_QUERIES: 'we-recent-queries',
  STANDALONE_SEARCH_Box: 'standalone_data',
  SELECTED_REGION_ID: 'NopUpgrade.RegionId',
  SELECTED_PROFILE_ID: 'NopUpgrade.ProfileId',
  COVEO_TOKEN: 'Coveo.Token',
  TOKEN_KEY: 'wca.NopToken',
  INTRO_COUNT_KEY_PRODUCT: 'introjs-count-PRODUCT',
  INTRO_DONOTSHOW_KEY_PRODUCT: 'introjs-dontShowAgain-PRODUCT',
  INTRO_COUNT_KEY_PLPSRP: 'introjs-count-PLPSRP',
  INTRO_DONOTSHOW_KEY_PLPSRP: 'introjs-dontShowAgain-PLPSRP',
  INTRO_COUNT_KEY_CART: 'introjs-count-CART',
  INTRO_DONOTSHOW_KEY_CART: 'introjs-dontShowAgain-CART',
  INTRO_COUNT_KEY_CHECKOUT: 'introjs-count-CHECKOUT',
  INTRO_DONOTSHOW_KEY_CHECKOUT: 'introjs-dontShowAgain-CHECKOUT',
  INTRO_COUNT_KEY_ORDERDETAILS: 'introjs-count-ORDERDETAILS',
  INTRO_DONOTSHOW_KEY_ORDERDETAILS: 'introjs-dontShowAgain-ORDERDETAILS',
  INTRO_COUNT_KEY_WEBQUOTEDETAILS: 'introjs-count-WEBQUOTEDETAILS',
  INTRO_DONOTSHOW_KEY_WEBQUOTEDETAILS: 'introjs-dontShowAgain-WEBQUOTEDETAILS',
  NOP_PROMOCODE: 'nop-promocode',
  NOP_REMOVED_PROMOCODE: 'nop-removed-promocode',
}

export const TOPICS = {
  CookieTermsAndConditions: 'FooterCookieBannerText',
  PageNotFound: 'PageNotFound',
}

export const REGIONS = [
  {
    id: 2,
    name: 'Ontario',
    nameFR: 'Ontario',
    division: 'WX',
  },
  {
    id: 3,
    name: 'Atlantic Canada',
    nameFR: 'Atlantic Canada',
    division: 'WX',
  },
  {
    id: 1,
    name: 'Quebec',
    nameFR: 'Québec',
    division: 'QP',
  },
  {
    id: 4,
    name: 'Western Canada',
    nameFR: 'Ouest Canadien',
    division: 'WZ',
  },
  {
    id: 5,
    name: 'Industrial Division PVF (National)',
    nameFR: 'Division industriel PVF (national)',
    division: 'MG',
  },
]

export const PERMISSIONS = {
  AccessAdminPanel: 'AccessAdminPanel',
}

export const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

export const ProductConstants = {
  initialIndex: 0,
  initialAccordionsState: {
    description: {
      name: 'description',
      open: true,
    },
    specifications: {
      name: 'specifications',
      open: false,
    },
    documents: {
      name: 'documents',
      open: false,
    },
  },
  initialStockAvailableModalOptions: {
    open: false,
    icon: true,
    title: '',
    border: '',
    background: '',
    type: 1,
  },
  initialAddToCartModalOptions: {
    open: false,
    title: '',
    products: [
      {
        qty: '',
        prodDesc: '',
        picture: '',
        unitOfMeasure: '',
        price: '',
      },
    ],
  },
}

export const CARTMODELPRODUCTSCOUNT = 3

export const CategoryConstants = {
  initialFilterModalOptions: {
    open: false,
    filterBy: 0,
  },
  filterOptions: {
    category: 1,
    brand: 2,
    size: 3,
    materials: 4,
    productType: 5,
    sortBy: 6,
    all: 7,
  },
}

export const DeviceTypeBasedOnWidth = {
  mobile: false,
  tablet: false,
  desktop: false,
}

export const CommonPermissionsMap = {
  selectAccount: 'selectAccount',
  cart: 'cart',
  orderStatus: 'orderStatus',
  invoiceGateway: 'invoiceGateway',
  myWolseley: 'myWolseley',
  reports: 'reports',
  internalCustomerDraft: 'internalCustomerDraft',
  user: 'user',
  myBusiness: 'myBusiness',
  prices: 'prices',
  webQuote: 'webQuote',
  viewStockQuantities: 'viewStockQuantities',
  stockIcon: 'stockIcon',
  creditCard: 'creditCard',
  expressLists: 'ExpressLists',
}

export const CommonPermissions = [
  CommonPermissionsMap.selectAccount,
  CommonPermissionsMap.cart,
  CommonPermissionsMap.orderStatus,
  CommonPermissionsMap.invoiceGateway,
  CommonPermissionsMap.myWolseley,
  CommonPermissionsMap.reports,
  CommonPermissionsMap.internalCustomerDraft,
  CommonPermissionsMap.user,
  CommonPermissionsMap.myBusiness,
  CommonPermissionsMap.prices,
  CommonPermissionsMap.webQuote,
  CommonPermissionsMap.viewStockQuantities,
  CommonPermissionsMap.stockIcon,
  CommonPermissionsMap.creditCard,
  CommonPermissionsMap.expressLists,
]

export const DiscontinuedConstants = {
  Types: {
    NO_REPLACEMENT_TYPE: 0,
    SUGGESTED_REPLACEMENT_TYPE: 1,
    SUPERSEDE_TYPE: 3,
  },
  Sources: {
    cart: 'cart',
    orderDetails: 'myorder',
    listDetails: 'listdetails',
    draftOrder: 'mydraft',
    listImport: 'listImport',
    cartImport: 'cartImport',
    sharedList: 'sharedlist',
    webquoteDetails: 'webquote',
  },
  SourceLocaleKeyMap: {
    cart: {
      productsNoLongerAvailable: 'wca.productsnolongeravailable',
      updateWithReplacement: 'wca.updatecartwithreplacement',
      itemsNoLongerAvailable: 'wca.itemsnolongeravailable',
      suggestReplacementConfirmationYes: 'wca.yesupdatecart',
      suggestReplacementConfirmationNo: 'wca.noremoveitem',
    },
    myorder: {
      productsNoLongerAvailable: 'wca.productsnolongeravailableOD',
      updateWithReplacement: 'wca.updatecartwithreplacementOD',
      itemsNoLongerAvailable: 'wca.itemsnolongeravailableOD',
      suggestReplacementConfirmationYes: 'wca.yesaddtocart',
      suggestReplacementConfirmationNo: 'wca.noskipthisitem',
    },
    webquote: {
      productsNoLongerAvailable: 'wca.productsnolongeravailableWQ',
      updateWithReplacement: 'wca.updatelistwithreplacementWQ',
      itemsNoLongerAvailable: 'wca.itemsnolongeravailableWQ',
      suggestReplacementConfirmationYes: 'wca.yesaddtolist',
      suggestReplacementConfirmationNo: 'wca.noskipthisitem',
    },
  },
}

export const CheckboxThemes = {
  default: {
    primary: '#3B3B3B',
    boxShadow: '#00000040',
    active: '#BFBFBF',
    background: '#FFFFFF',
    color: '#FFFFFF',
  },
}

export const DeliveryMethods = {
  PICKUP: 1,
  DELIVERY: 2,
}

export const CheckoutConstants = {
  apiDefaultDate: '0001-01-01T00:00:00',
  webQuoteId: 0,
  orderInfo: 0,
  deliveryMethod: 1,
  paymentMethod: 2,
  reviewAndPlaceOrder: 3,
  webQuoteStatus: {
    CHANGED: 1,
    INUSE: 2,
    NOTQUOTE: 3,
    NOTEXIST: 4,
    CANCELED: 5,
    INVALID: 6,
  },
  initialState: {
    activeIndex: 0,
    commonProps: {
      selectedBranchObj: null,
      selectedAddressObj: null,
    },
    sections: [
      {
        title: 'wca.orderinformation',
        orderInfo: {
          orderedBy: '',
          phoneNumber: '',
          phoneExt: '',
          cellphone: '',
          PONumber: '',
        },
        error: {
          orderedBy: null,
          phoneNumber: null,
          phoneExt: null,
          cellphone: null,
          PONumber: null,
        },
      },
      {
        title: 'wca.deliverymethod',
        deliveryMethod: {
          method: DeliveryMethods.PICKUP,
          prefferedBranchId: '',
          selectedBranchId: '',
          selectedBranchAddress: '',
          selectedAddress: '',
          selectedDeliveryFromBranchId: 0,
          pickupDate: '',
          deliveryDate: '',
          isExpressPickUp: false,
          pickupPreference: 0,
          deliveryPreference: 0,
          specialInstructionsPickup: '',
          specialInstructionsDelivery: '',
        },
        error: {
          requiredDate: null,
          specialInstructions: null,
        },
      },
      {
        title: 'wca.paymentmethod',
        paymentMethod: {
          selectedPaymentMethod: '',
          maskedCardNumber: '',
          selectedCCToken: '',
          newCardId: '',
          selectedCCValue: '',
          defaultPaymentType: '',
        },
        error: {
          creditCard: null,
          creditCardBox: null,
          nameOnCard: null,
        },
      },
      {
        title: 'wca.reviewAndPlaceOrder',
        reviewAndPlaceOrder: {
          subTotal: 0,
        },
        error: null,
      },
    ],
  },
}

export const CartItemSourceTypes = {
  ProductSearch: 1,
  ProductDetail: 2,
  DraftOrder: 3,
  WebQuote: 4,
  OrderHistory: 5,
  MyList: 6,
  BarcodeScanner: 7,
  ImportExcel: 8,
  FeaturedProduct: 9,
  ExpertEstimator: 10,
  ListDetail: 11,
  ThirdParty: 12, // Proflo and Silverline Web Tools
  MarketingBundles: 13,
  CrossBundle: 14,
  RecommendedProduct: 15,
  DigitalFlyer: 16,
  MyListCustom: 17,
  MyListExpress: 18,
  MyListShared: 19,
  ListDetailsCustom: 20,
  ListDetailsExpress: 21,
  ListDetailsShared: 22,
  ListDetailTop100: 23,
  AlternateProduct: 24,
  ProductListing: 25,
}

export const OrderDetailConstants = {
  itemsPerPage: 20,
  maxPaginationButtons: 5,
  /** Chart header titles should match the properties returned from the back-end for the chart data.
   *  This is required to ensure sorting for the headers will work.
   *  Any differences in naming where sorting is required need to be specified at the top of the Sortable Header component.
   *  Locale translation variables should match header titles as well, to ensure correct translations are displayed.
   **/
  chartHeaders: [
    { title: 'image', sortable: false },
    { title: 'orderDetailsProductName', sortable: true },
    { title: 'unitPrice', sortable: true },
    { title: 'newOrderedQty', sortable: true },
    { title: 'totalAmount', sortable: true },
    { title: 'bO_QTY', sortable: true },
    { title: 'allocatedQuantity', sortable: true },
    { title: 'invoicedQuantity', sortable: true },
    { title: 'invoiced_amount', sortable: true },
  ],
  orderStatus: {
    INVOICED: 1,
    SHIPPED: 2,
    CANCELLED: 3,
    CONTACT_BRANCH: 4,
    BACKORDERED: 5,
    IN_PROGRESS: 6,
  },
}

export const PAGE_BG = {
  orderDetails: '#fafafa',
  others: '#fff',
}

export const UIOperations = {
  downloadInvoice: 'downloadInvoice',
}

export const WebQuoteDetailConst = {
  webQuoteStatus: {
    READY: 0,
    SUBMITTED: 1,
    IN_REVIEW: 2,
    EXPIRED: 3,
  },
  webQuoteRequoteReason: {
    QuoteExpired: 'QuoteExpired',
    ChangeProductAndQty: 'ChangeProductAndQty',
    Other: 'Other',
  },
  buttonClickMethod: {
    HardHoldPopup: 'HardHoldPopup',
    CheckOut: 'CheckOut',
    RequestRequote: 'RequestRequote',
    PendingReview: 'PendingReview',
  },
  webQuoteButtonPresetConfigs: {},
}

WebQuoteDetailConst.webQuoteButtonPresetConfigs = {
  EXPIRED_WQ_REQUOTE_SUBMIT: { ButtonTextKey: 'wca.pendingreview', IsVisible: true, IsEnabled: false, OnClickMethod: WebQuoteDetailConst.buttonClickMethod.PendingReview },
  NONEXPIRED_WQ_REQUOTE_SUBMIT: { ButtonTextKey: 'wca.checkout', IsVisible: true, IsEnabled: true, OnClickMethod: WebQuoteDetailConst.buttonClickMethod.CheckOut },
}

export const AlertConstants = {
  Type: {
    WARNING: 'warning',
    DARK: 'dark',
  },
  ThemeMap: {
    warning: {
      background: '#fffbe5',
      border: '#e9cda3',
      color: '#3b3b3b',
    },
    dark: {
      background: '#0000000D',
      border: '#00000026',
      color: '#000000',
    },
  },
}

export const ModalOrigins = {
  webQuote: 'webQuote',
  cart: 'cart',
}

export const IntroPageKeys = {
  product: 'Product',
  search: 'PLPSRP',
  cart: 'Cart',
  checkout: 'Checkout',
  orderDetails: 'OrderDetails',
  webQuoteDetails: 'WebQuoteDetails',
}

export const LayoutSources = {
  myAccount: 'myaccount',
}

export const StatusIndicatorColorMap = {
  WHITE: '#ffffff',
  RED: 'var(--state-error)',
  GREEN: 'var(--state-success)',
  YELLOW: '#FFE455',
  ORANGE: '#F49E1D',
}
