import { useState, createContext, useCallback, useContext, memo, useMemo, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { useUrl } from 'nextjs-current-url'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Cookies from 'universal-cookie'
import { logoutAPI } from '../api-client/identity/logout'
import SwitchProfileModal from '../containers/switch-profile/switch-profile-modal'
import { logOutEvent } from '../utils/ga-events/gtm-events-common'
import { getActiveProfile, getActiveProfileKey } from '../api-client/profile/get-active-profile'
import MessageModal from '../containers/message/message-modal'
import { getAllProfiles, getAllProfilesKey } from '@/api-client/profile/get-all-profiles'
import { setProfile } from '@/api-client/profile/set-profile'
import { CommonPermissions, CookiesKey } from '@/constants'
import { getPermisisonsKey, getPermissions } from '@/api-client/permissions/get-permissions'
import { getSearchBoxCustomerData, getSearchBoxCustomerDataKey } from '@/api-client/customer/get-search-box-customer-data'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

const AuthContext = createContext()
const AuthDataContext = createContext()

const cookie = new Cookies()

function AuthProviderComponent({ initialState, children }) {
  const { query, reload } = useRouter()
  const queryClient = useQueryClient()
  const { pathname } = useUrl() ?? {}
  const [openHardAlert, setOpenHardAlert] = useState(false)
  const [openSwitchProfileModal, setOpenSwitchProfileModal] = useState({
    open: false,
    profiles: {},
    token: null,
  })
  const [callGetAllProfiles, setCallGetAllProfiles] = useState(false)

  const isNopTokenExistedInCookie =
    cookie.get(CookiesKey.TOKEN_KEY, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    }) !== undefined

  const [token, setToken] = useState(() => {
    if (initialState.token == null && isNopTokenExistedInCookie) {
      cookie.remove(CookiesKey.TOKEN_KEY, { path: '/' })
      cookie.remove(CookiesKey.SELECTED_PROFILE_ID, {
        path: '/',
      })
    }

    if (initialState.token != null && !isNopTokenExistedInCookie) {
      cookie.set(CookiesKey.TOKEN_KEY, initialState.token, { path: '/' })
    }

    return initialState.token || null
  })

  const isAuthenticated = token != null

  useQuery({
    queryKey: [getAllProfilesKey],
    queryFn: getAllProfiles,
    enabled: callGetAllProfiles,
    onSuccess: (data) => {
      if (!data || data.length === 0) {
        window.location.href = mainSite
      } else if (data.length === 1) {
        switchProfile({
          profileId: data[0].id,
        })
      }
    },
  })

  const { mutate: switchProfile } = useMutation(setProfile, {
    onSuccess: () => {
      const addToCartQty = sessionStorage?.getItem('addToCartQty')
      const addToCartRecoRail = sessionStorage?.getItem('addToCartRecoRail')
      const addToCartBundleProducts = sessionStorage?.getItem('addToCartBundleProducts')
      const saveAsListClicked = sessionStorage?.getItem('saveAsListClicked')
      if (/login/i.test(document?.referrer) && (addToCartQty || addToCartRecoRail || addToCartBundleProducts || saveAsListClicked)) {
        if (addToCartQty) {
          sessionStorage.setItem('singleProfileUserAddToCartQty', addToCartQty)
          sessionStorage.removeItem('addToCartQty')
        } else if (addToCartRecoRail) {
          sessionStorage.setItem('singleProfileUserAddToCartRecoRail', addToCartRecoRail)
          sessionStorage.removeItem('addToCartRecoRail')
        } else if (addToCartBundleProducts) {
          sessionStorage.setItem('singleProfileUserAddToCartBundleProducts', addToCartBundleProducts)
          sessionStorage.removeItem('addToCartBundleProducts')
        } else if (saveAsListClicked) {
          sessionStorage.setItem('singleProfileUserSaveAsListClicked', saveAsListClicked)
          sessionStorage.removeItem('saveAsListClicked')
        }
      }
      reload()
    },
    onError: () => {
      window.location.href = mainSite
    },
  })

  const { data: activeProfile, refetch: refetchActiveProfile } = useQuery({
    queryKey: [getActiveProfileKey],
    queryFn: getActiveProfile,
    enabled: isAuthenticated,
    onSuccess: (res) => {
      if (res == null || res == undefined) {
        setCallGetAllProfiles(true)
      }

      if (res?.id > 0) {
        updateRegion(res.region)
        if (/h/i.test(res?.creditStatus)) {
          if (sessionStorage.getItem('_hardAlertShown') == null) {
            setOpenHardAlert(true)
          }
        } else {
          sessionStorage.removeItem('_hardAlertShown')
        }
        updateProfile(res?.id)
        setCallGetAllProfiles(false)
      }
    },
  })
  const updateRegion = (selectedRegionId) => {
    cookie.set(CookiesKey.SELECTED_REGION_ID, selectedRegionId, { path: '/' })
  }

  const clearProfileData = () => {
    cookie.remove(CookiesKey.SELECTED_PROFILE_ID, { path: '/' })
  }

  const updateProfile = (selectedProfileId) => {
    cookie.set(CookiesKey.SELECTED_PROFILE_ID, selectedProfileId, { path: '/' })
  }

  const { data: customerDataForCoveo } = useQuery({
    queryKey: [getSearchBoxCustomerDataKey],
    queryFn: getSearchBoxCustomerData,
    enabled: isAuthenticated && !!activeProfile?.id,
  })

  const { data: permissions, refetch: refetchPermissions } = useQuery({
    queryKey: [getPermisisonsKey, CommonPermissions],
    queryFn: () => getPermissions({ systemNames: CommonPermissions }),
  })

  const { mutate: logoutMutate } = useMutation(logoutAPI, {
    onSuccess: () => {
      clearLoginData(queryClient)
      clearProfileData()
      if (!!activeProfile) {
        if (pathname?.toLowerCase().startsWith('/en')) window.location.href = `${mainSite}en`
        else window.location.href = `${mainSite}fr`
      }
    },
  })

  const { mutate: sessionTimeoutMutate } = useMutation(logoutAPI, {
    onSuccess: () => {
      clearLoginData(queryClient)
      clearProfileData()
    },
  })

  const logout = useCallback(
    ({ token = null }) => {
      logoutMutate({ token })
      logOutEvent()
    },
    [logoutMutate],
  )

  const clearLoginData = (queryClient) => {
    setToken(null)
    cookie.remove(CookiesKey.TOKEN_KEY, { path: '/' })
    queryClient.removeQueries(getActiveProfileKey)
  }

  const loadLoginData = (token) => {
    setToken(token)
    cookie.set(CookiesKey.TOKEN_KEY, token, { path: '/' })
  }

  const login = useCallback(
    ({ token }) => {
      loadLoginData(token)

      if (query?.authRedirect) {
        Router.push(query.authRedirect)
      }
    },
    [query?.authRedirect],
  )

  const handleSwitchProfileModalClose = useCallback(() => {
    setOpenSwitchProfileModal({ open: false, profiles: {}, token: null })
  }, [])

  const handleMessageModal = useCallback(() => {
    setOpenHardAlert(false)
  }, [])

  useEffect(() => {
    refetchPermissions()
  }, [isAuthenticated, activeProfile])

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          login,
          logout,
          setOpenSwitchProfileModal,
          refetchActiveProfile,
          sessionTimeoutMutate,
          refetchPermissions,
        }),
        [login, logout, setOpenSwitchProfileModal, refetchActiveProfile, sessionTimeoutMutate, refetchPermissions],
      )}
    >
      <AuthDataContext.Provider
        value={useMemo(
          () => ({
            isAuthenticated,
            activeProfile: {
              ...activeProfile,
            },
            permissions: {
              ...permissions,
            },
            customerDataForCoveo: {
              ...customerDataForCoveo,
            },
          }),
          [isAuthenticated, activeProfile, permissions, customerDataForCoveo],
        )}
      >
        {children}
        {openSwitchProfileModal.open && <SwitchProfileModal profiles={openSwitchProfileModal.profiles} token={openSwitchProfileModal.token} onClose={handleSwitchProfileModalClose} />}
        {openHardAlert && <MessageModal onClose={handleMessageModal} />}
      </AuthDataContext.Provider>
    </AuthContext.Provider>
  )
}

const useAuth = () => [useContext(AuthDataContext), useContext(AuthContext)]

const AuthProvider = memo(AuthProviderComponent)

export { AuthProvider, useAuth }
