import Media from 'react-media'
import useDeviceDetect from '../../hooks/useDeviceDetect'

export function Desktop({ children }) {
  const deviceType = useDeviceDetect()

  return (
    <Media
      query='(min-width: 1025px)'
      defaultMatches={!deviceType.isMobile}
      render={() => children}
    />
  )
}

export function DesktopTablet({ children }) {
  return (
    <Media
      query='(min-width: 768px)'
      render={() => children}
    />
  )
}

export function Mobile({ children }) {
  const deviceType = useDeviceDetect()

  return (
    <Media
      query='(max-width: 767px)'
      defaultMatches={deviceType.isMobile}
      render={() => children}
    />
  )
}
