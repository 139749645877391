import React from 'react'
import Modal from '../../components/modal'
import Regions from '.'

export default function RegionModal({ onClose }) {
  return (
    <>
      <Modal onClose={onClose} closeOnClickOutside={false} name='region'>
        <div className='wrapper'>
          <div className='topRedLine'></div>
          <Regions onClose={onClose} />
        </div>
      </Modal>
      <style jsx>{`
        .wrapper {
          padding: 3rem 4rem;
          border-top: 4px solid #ccc;
          position: relative;
          min-width: 52.2rem;
          min-height: 43rem;

          .topRedLine {
            height: 4px;
            position: absolute;
            left: 0;
            top: -4px;
            width: 12rem;
            background: var(--wolseleyRed);
          }
        }

        @media (max-width: 767px) {
          .wrapper {
            min-width: 100%;
            max-width: 100%;
            min-height: unset;
            padding: 2rem 1.8rem 4rem 1.8rem;
          }
        }

      `}</style>
    </>
  )
}
