import { useTranslation } from 'react-i18next'
import { useFeatureToggle } from '../../contexts/feature-toggle'
import { useRouter } from 'next/router'
import isNotEmptyObject from '@/utils/is-not-empty-object'
import dynamic from 'next/dynamic'

const SearchBoxWrapper = () => {
  const { t } = useTranslation('common')
  const [{ featureToggles }] = useFeatureToggle()
  const { pathname } = useRouter()
  const { locale } = useRouter()

  if (pathname === undefined || pathname === null || !isNotEmptyObject(featureToggles)) return null

  return (
    <>
      {pathname?.includes('/search') && (
        <atomic-external>
          <custom-search-box
            rec-type='popular_viewed'
            rec-title={featureToggles?.IsCoveoInstantSearchEnabled ? t('wca.TrendingProducts') : t('wca.recommendedproducts_search')}
            recent-searches-title={t('wca.recentsearchestitle')}
            recent-searches-clear={t('wca.clear')}
            is-coveo-instant-search-enabled={featureToggles?.IsCoveoInstantSearchEnabled ? 'true' : 'false'}
            minimum-query-length='3'
          ></custom-search-box>
        </atomic-external>
      )}

      {!pathname?.includes('/search') && (
        <>
          <atomic-external selector='#search-standalone'>
            <custom-search-box
              rec-type='popular_viewed'
              redirection-url={`/${locale}/search`}
              rec-title={featureToggles?.IsCoveoInstantSearchEnabled ? t('wca.TrendingProducts') : t('wca.recommendedproducts_search')}
              recent-searches-title={t('wca.recentsearchestitle')}
              recent-searches-clear={t('wca.clear')}
              is-coveo-instant-search-enabled={featureToggles?.IsCoveoInstantSearchEnabled ? 'true' : 'false'}
              minimum-query-length='3'
            ></custom-search-box>
          </atomic-external>
          <atomic-search-interface
            id='search-standalone'
            reflect-state-in-url='false'
            analytics='true'
            style={{ display: 'none' }}
            data-standalone='true'
            fields-to-include='["we_published", "ec_skus", "we_productid", "we_replacement_type_atlantic", "we_replacement_type_ontario", "we_promo_message", "we_alternate_sku_slugs", "we_replacement_skus_ontario", "we_replacement_slugs_ontario", "we_replacement_skus_atlantic", "we_replacement_slugs_atlantic", "ec_shortdesc", "we_featured", "we_replacement_slugs", "we_alternate_skus", "we_replacement_skus", "we_replacement_type", "last_orderedDate", "we_customers_that_ordered", "we_manufacturer", "we_slug", "ec_images", "we_unit_of_measure", "we_best_seller", "we_onsale"]'
          ></atomic-search-interface>
        </>
      )}
    </>
  )
}

export default dynamic(() => Promise.resolve(SearchBoxWrapper), {
  ssr: false,
})
