import client from '../client'

export async function logoutAPI({ token = null }) {
  const headers = {}
  if (!!token) {
    headers.Authorization = `Bearer ${token}`
  }

  return client(`${process.env.NEXT_PUBLIC_DOMAIN}/CustomerDetails/Logout`, {
    headers: { 'Content-Type': 'application/json', ...headers },
    method: 'POST',
  })
}
