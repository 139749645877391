import { useCallback, useEffect, useRef } from 'react'
import dynamic from 'next/dynamic'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import IconBreadcrumbArrow from '@/components/svg/icon-breadcrumb-arrow'
import classNames from 'classnames'

const BreadCrumbs = ({ breadcrumbData }) => {
  const { deviceTypeBasedOnWidth } = useDeviceDetect()

  const breadcrumbRef = useRef(null)

  const handleBreadCrumbScroll = (e) => {
    const bcContainer = breadcrumbRef.current
    if (!e) bcContainer.scrollLeft = bcContainer.scrollWidth
    if (bcContainer) {
      if (bcContainer.scrollLeft > 0 && bcContainer.offsetLeft + bcContainer.scrollLeft + bcContainer.offsetWidth < bcContainer.scrollWidth) {
        bcContainer.classList.add('lrGradient')
        bcContainer.classList.remove('rGradient')
        bcContainer.classList.remove('lGradient')
      } else if (bcContainer.scrollLeft > 0) {
        bcContainer.classList.add('lGradient')
        bcContainer.classList.remove('lrGradient')
        bcContainer.classList.remove('rGradient')
      } else if (bcContainer.offsetLeft + bcContainer.scrollLeft + bcContainer.offsetWidth < bcContainer.scrollWidth) {
        bcContainer.classList.add('rGradient')
        bcContainer.classList.remove('lrGradient')
        bcContainer.classList.remove('lGradient')
      } else {
        bcContainer.classList.remove('rGradient')
        bcContainer.classList.remove('lrGradient')
        bcContainer.classList.remove('lGradient')
      }
    }
  }

  const handleOnClick = useCallback((crumb, index) => {
    if (typeof crumb.onClick == 'function') {
      crumb.onClick({ crumb, index })
    }
  }, [])

  const renderBreadCrumb = useCallback(
    (crumb, index) => {
      if (crumb.hide) return

      const lastItem = index === breadcrumbData.length - 1

      const onClickHandler = () => {
        handleOnClick(crumb, index)
      }

      return (
        <div key={index} className={classNames('breadcrumb-item flex flexDirectionRow alignCenter', { 'crumb-last': lastItem })}>
          {index > 0 && <IconBreadcrumbArrow />}
          {lastItem ? (
            crumb.text
          ) : (
            <a onClick={onClickHandler} href={crumb.path} title={crumb.text}>
              {crumb.text}
            </a>
          )}
        </div>
      )
    },
    [handleOnClick],
  )

  useEffect(() => {
    if (breadcrumbRef.current) {
      if (deviceTypeBasedOnWidth.mobile) {
        handleBreadCrumbScroll()
        breadcrumbRef.current.addEventListener('scroll', handleBreadCrumbScroll)
      } else {
        breadcrumbRef.current.removeEventListener('scroll', handleBreadCrumbScroll)
      }
    }

    return () => {
      breadcrumbRef.current?.removeEventListener('scroll', handleBreadCrumbScroll)
      breadcrumbRef.current?.classList?.remove('rGradient')
      breadcrumbRef.current?.classList?.remove('lrGradient')
      breadcrumbRef.current?.classList?.remove('lGradient')
    }
  }, [deviceTypeBasedOnWidth])

  return (
    <>
      <div ref={breadcrumbRef} className='breadcrumb flex flexDirectionRow alignCenter'>
        {breadcrumbData.map((crumb, index) => renderBreadCrumb(crumb, index))}
      </div>
      <style jsx global>{`
        .breadcrumb {
          .breadcrumb-item {
            gap: 0.5rem;
            text-decoration: underline;
            text-wrap: nowrap;

            &.crumb-last {
              text-decoration: none;
              color: var(--wolseleyGrey);
            }

            a:hover {
              cursor: pointer;
              color: #006bff;
            }
          }
        }
      `}</style>
      <style jsx>
        {`
          .lGradient {
            -webkit-mask-image: linear-gradient(90deg, black 10%);
            mask-image: linear-gradient(90deg, transparent 0%, black 10%);
          }
          .rGradient {
            -webkit-mask-image: linear-gradient(90deg, black 90%, transparent 100%);
            mask-image: linear-gradient(90deg, black 90%, transparent 100%);
          }
          .lrGradient {
            -webkit-mask-image: linear-gradient(90deg, black 10%, black 90%, transparent 100%);
            mask-image: linear-gradient(90deg, transparent 0%, black 10%, black 90%, transparent 100%);
          }

          .breadcrumb {
            gap: 0.5rem;
            line-height: 1.61rem;
            font-size: 1.4rem;
            padding-right: 0.5rem;
          }

          @media (max-width: 767px) {
            .breadcrumb {
              overflow: scroll;

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        `}
      </style>
    </>
  )
}

export default dynamic(() => Promise.resolve(BreadCrumbs), {
  ssr: false,
})
