import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useAuth } from '../../contexts/auth'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { getAdminHeaderLinks, getAdminHeaderLinksKey } from '../../api-client/common/get-admin-header-links'
import dynamic from 'next/dynamic'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

const AdminHeaderLink = () => {
  const [{ isAuthenticated, activeProfile }] = useAuth()
  const { t } = useTranslation('header')
  const { locale } = useRouter()

  const { data: adminHeaderLink } = useQuery({
    queryKey: [getAdminHeaderLinksKey],
    queryFn: () => getAdminHeaderLinks(),
    enabled: isAuthenticated && !!activeProfile?.id,
  })

  if (!isAuthenticated || !!!adminHeaderLink || !!!activeProfile?.id || (!adminHeaderLink.DisplayAdminLink && !adminHeaderLink.IsCustomerImpersonated)) return null

  return (
    <>
      <div className='admin-header-links flex flexDirectionColumn'>
        {adminHeaderLink.DisplayAdminLink && !adminHeaderLink.IsCustomerImpersonated && <Link href={`${mainSite}admin/welcome`}>{t('account.administration')}</Link>}
        {adminHeaderLink.IsCustomerImpersonated && (
          <div className='impersonate'>
            <span>
              {t('wca.currentlyimpersonating')} {adminHeaderLink.ImpersonatedCustomerName}, {adminHeaderLink.CustomProperties.ImpersonatedCustomerEmail}
            </span>
            <a href={`${mainSite}${locale}/logout`} className='finish-impersonation' title={t('account.impersonatedas.finish.hint')}>
              {t('wca.endimpersonate')}
            </a>
          </div>
        )}
      </div>
      <style jsx>{`
        .admin-header-links {
          padding: 1rem;
          background-color: #333;
          text-align: center;
          color: #eee;
          font-size: 12px;
          :global(a) {
            font-size: 12px;
            font-weight: bold;
          }
          .impersonate {
            display: inline-block;
            font-weight: bold;
            a {
              background-color: #555;
              padding: 10px 10px 10px 10px;
              margin: 20px;
              :hover {
                background-color: #666;
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(AdminHeaderLink), {
  ssr: false,
})
