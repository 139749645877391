export const filterMenuByPermissions = (menu, permissions) => {
  if (Object.keys(permissions).length === 0) {
    return menu
  }

  return menu
    .map((menuItem) => {
      const filteredL2 = menuItem.l1.l2.filter((l2Item) => {
        // Check if all permissions for l2Item are true
        if (l2Item.permissions && Array.isArray(l2Item.permissions)) {
          return l2Item.permissions.every((permission) => permissions[permission])
        }
        // If no permissions field, include the item
        return true
      })

      // If no l2 items passed the filter, exclude this l1
      if (filteredL2.length === 0) return null

      // Return the l1 item with the filtered l2 items
      return {
        l1: {
          ...menuItem.l1,
          l2: filteredL2,
        },
      }
    })
    .filter(Boolean) // Remove null values
}
