export const elementIsVisibleInViewportByPercentage = (el, percentageVisibility = 0) => {
  const { top, left, bottom, right, height, width } = el?.getBoundingClientRect()
  const { innerHeight, innerWidth } = window

  // Calculate the intersection of the element with the viewport
  const visibleHeight = Math.min(bottom, innerHeight) - Math.max(top, 0)
  const visibleWidth = Math.min(right, innerWidth) - Math.max(left, 0)

  // Calculate the visible area
  const elementArea = height * width
  const visibleArea = Math.max(0, visibleHeight) * Math.max(0, visibleWidth)

  // Calculate the percentage of the element that is visible
  const visibilityPercentage = (visibleArea / elementArea) * 100

  if (percentageVisibility > 0) {
    // Check if the visible percentage is greater than or equal to the requested percentage
    return visibilityPercentage >= percentageVisibility
  }
}
