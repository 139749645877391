import { useRouter } from 'next/router'
import LinkAnchor from '../button/link-anchor'
import ShoppingCartIcon from './../svg/shopping-cart'
import useCartCount from '../../hooks/use-cart-count'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN

export default function CartHeader() {
  const [cartCount] = useCartCount()
  const { locale } = useRouter()
  return (
    <>
      <LinkAnchor
        href={`${mainSite}${locale}/cart`}
        onClick={() => (window.location.href = `${mainSite}${locale}/cart`)}
        className='cartItemIcon'
      >
        <ShoppingCartIcon />
        <span id='cartItemCount' className='cartQuantity'>
          {cartCount > 99 ? '99+' : cartCount}
        </span>
      </LinkAnchor>
      <style jsx>{`
        :global(.cartItemIcon) {
          margin: 2rem 1.6rem 0 1rem;
          position: relative;

          .cartQuantity {
            background-color: var(--primary-color);
            border-radius: 5rem;
            width: 2.8rem;
            height: 2.8rem;
            position: absolute;
            text-align: center;
            font-weight: bold;
            color: white;
            top: -3.1rem;
            right: -1.7rem;
            padding-top: 0.8rem;
            font-size: 1.3rem;
            padding-left: 0.2rem;
          }
        }
        :global(.cartItemIcon):hover {
          text-decoration: none;
        }
        @media (max-width: 382px) {
          :global(.cartItemIcon) {
            .cartQuantity {
              right: -3.9rem;
            }
          }
        }
        @media (max-width: 767px) {
          :global(.cartItemIcon) {
            margin: 0rem 0.6rem 0 3.5rem;
            right: 17px;
            position: absolute;

            .cartQuantity {
              top: -1.5rem;
              right: -1.6rem !important;
              padding-top: 0.8rem;
            }
          }
        }
        @media (max-width: 1077px) {
          :global(.cartItemIcon) {
            .cartQuantity {
              right: -4.1rem;
            }
          }
        }
      `}</style>
    </>
  )
}
