export const Quotes = (props) => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'    
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      {...props}
    >
      <path d='M7.5 6H12V5.41421C12 5.29283 11.9853 5.17296 11.9568 5.057C11.9525 5.03943 11.9479 5.02195 11.943 5.00458L11.9417 5C11.8721 4.75786 11.742 4.53488 11.5607 4.35355L7.64578 0.438675C7.46457 0.257691 7.24184 0.127815 7 0.0583218C6.8665 0.0199608 6.72717 0 6.58579 0H2C0.89543 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H4.22126L4.20776 15.9744C4.092 15.7508 4.01983 15.5018 4.00293 15.2392C3.99787 15.1606 3.99776 15.0807 4.00293 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1H6V4.5C6 5.32843 6.67157 6 7.5 6ZM7.5 5C7.22386 5 7 4.77614 7 4.5V1.20711L10.7929 5H7.5ZM10.8092 7.54776C11.5395 6.81741 12.7237 6.81741 13.454 7.54776C14.1843 8.27811 14.1843 9.46223 13.454 10.1926L8.62457 15.022C8.343 15.3036 7.99019 15.5033 7.60387 15.5999L6.10597 15.9744C5.45456 16.1372 4.86451 15.5472 5.02737 14.8958L5.40184 13.3979C5.49842 13.0116 5.69818 12.6588 5.97975 12.3772L10.8092 7.54776Z' />
    </svg>
  )
}
