import { LOCALE, ROUTES, ROUTE_NAME } from '../constants'

export const getProductRoute = (locale) =>  locale === "en" ? `/en${ROUTES.ProductDetails}` : `/fr${ROUTES.ProductDetailsFR}`

const getTranslateRoute = (currentRoute, locale) => {
  switch (currentRoute) {
    case ROUTE_NAME.PDP:
    case ROUTE_NAME.PDP_FR:
      return locale === LOCALE.EN ? ROUTE_NAME.PDP : ROUTE_NAME.PDP_FR
    case ROUTE_NAME.PLP:
    case ROUTE_NAME.PLP_FR:
      return locale === LOCALE.EN ? ROUTE_NAME.PLP : ROUTE_NAME.PLP_FR
    default:
      return currentRoute
  }
}

/**
 * Translate url to proper locale url
 * @param {*} url 
 * @param {*} locale 
 * @returns url in the locale correctly
 */
export const getTranslateUrl = (url, locale) => {
  const urlArr = url.split("://")
  const protocol = urlArr[0]
  const segmentArr = urlArr[1].split('/')

  const isLocaleInUrl = [LOCALE.EN, LOCALE.FR].includes(segmentArr[1])

  const currentRoute = isLocaleInUrl ? segmentArr[2] : segmentArr[1]
  const translateRoute = getTranslateRoute(currentRoute, locale)


  if (isLocaleInUrl) {
    segmentArr[1] = locale
  } else {
    segmentArr.unshift = locale
  }

  segmentArr[2] = translateRoute
  const translateUrl = `${protocol}://${segmentArr.join('/')}`

  return translateUrl
}

export const parseUrlHash = (hash) => {
  if (hash === "") return null

  const queryString = hash.charAt(0) === '#' ? hash.substring(1) : hash;
  const pairs = queryString.split('&');

  // Reduce the array of strings into a single object with key-value pairs
  const params = pairs.reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    // Decode URI components to handle special characters
    acc[decodeURIComponent(key)] = decodeURIComponent(value);
    return acc;
  }, {});

  return params;
}