import React from 'react'
import Modal from '../../components/modal'
import SwitchAccount from '.'

export default function SwitchAccountModal({ onClose }) {
  return (
    <>
      <Modal onClose={onClose} closeOnClickOutside={false}>
        <div className='wrapper'>
          <div className='topRedLine'></div>
          <SwitchAccount onClose={onClose} />
        </div>
      </Modal>
      <style jsx>{`
        .wrapper {
          padding: 3rem;
          border-top: 4px solid #ccc;
          position: relative;
          width: 90rem;

          .topRedLine {
            height: 4px;
            position: absolute;
            left: 0;
            top: -4px;
            width: 12rem;
            background: var(--wolseleyRed);
          }
        }
        
        @media (max-width: 768px) {
          .wrapper {
            padding: 3rem 1rem;
          }
        }
      `}</style>
    </>
  )
}
