import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@tanstack/react-query'
import { saveLanguage } from '../../api-client/language/set-language'
import LanguageSelector from '../language-selector'
import { useAuth } from '../../contexts/auth'
import { useRegion } from '@/contexts/region-context'
import { getTopics, getTopicsKey } from '../../api-client/topic/get-topic'
import Cookies from 'universal-cookie'
import { CookiesKey } from '../../constants'
import { useQuery } from '@tanstack/react-query'
import dynamic from 'next/dynamic'
import Loader from '../loader'
import classNames from 'classnames'

const ribbon = 'Header_Ribbon_Message'
const cookie = new Cookies()

const TopRibbon = ({ showLanguageOnly = false }) => {
  const [{ isAuthenticated, activeProfile }] = useAuth()
  const { selectedRegionId } = useRegion()
  const { locale } = useRouter()
  const languageIdFromClient = /en/i.test(locale) ? 1 : 2

  const regionIdFromClient = cookie.get(CookiesKey.SELECTED_REGION_ID)
  const { data: ribbonData, isFetching } = useQuery({
    queryKey: [getTopicsKey, ribbon],
    queryFn: () => getTopics({ systemNames: ribbon, regionIdFromClient, languageIdFromClient }),
    enabled: !showLanguageOnly,
  })

  const [showLanguageSelector, setShowLanguageSelector] = useState(false)

  const { mutate: mutateLanguage } = useMutation(saveLanguage, {
    onSuccess: (data) => {
      window.location.href = `${data.redirectUrl}`
    },
  })

  const handleLanguageChange = (language) => {
    mutateLanguage({ languageId: language == 'fr' ? 2 : 1, url: window.location.href })
  }

  useEffect(() => {
    const getRegionId = isAuthenticated && !!activeProfile?.region ? activeProfile?.region : selectedRegionId
    setShowLanguageSelector(getRegionId == 1 || getRegionId == 5)

    return () => {
      setShowLanguageSelector(false)
    }
  }, [activeProfile?.region, selectedRegionId])

  return (
    <>
      <div id={`${showLanguageOnly ? 'language-only-container' : 'main-wolseley-ribbon'}`} className='main-wolseley-ribbon-wrapper'>
        <div id={`${showLanguageOnly ? 'language-only' : 'main-wolseley-ribbon-bckground'}`} className='content flex alignCenter justifyBetween'>
          <div className='main-wolseley-ribbon-content'>
            {isFetching && <Loader width='20' height='20' />}
            {!!ribbonData && !showLanguageOnly && <div className='main-wolseley-ribbon-Message' dangerouslySetInnerHTML={{ __html: ribbonData?.Header_Ribbon_Message?.body }}></div>}

            <div
              className={classNames('main-wolseley-ribbon-btns', {
                hideInMobile: !showLanguageOnly,
              })}
            >
              {showLanguageSelector && <LanguageSelector selectedLanguage={locale} onLanguageChange={handleLanguageChange} />}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .main-wolseley-ribbon-wrapper {
          background-color: #ffffff;
        }

        .main-wolseley-ribbon-content {
          :global(.main-wolseley-ribbon-Message a) {
            font-size: 1.5rem !important;
            :hover {
              color: #000000 !important;
              text-decoration: none !important;
            }
          }
          :global(.main-wolseley-ribbon-Message a):hover {
            color: #000000 !important;
            text-decoration: none !important;
          }
        }

        #main-wolseley-ribbon {
          max-width: 128rem;
          min-height: 3.5rem;
          height: unset;
          max-height: unset;
          width: 100%;
          margin: 0 auto;
        }

        #language-only-container {
          min-height: unset;

          #language-only {
            :global(.language-selector) {
              color: var(--wolseleyDarkGrey);
              font-weight: normal;
              :global(a) {
                color: var(--wolseleyDarkGrey);
              }
              :global(.active),
              :global(a):hover {
                font-weight: 700;
              }
            }

            .main-wolseley-ribbon-btns {
              padding: 0.5rem 1rem;
            }
          }
        }

        #main-wolseley-ribbon-bckground {
          :has(div.we-green) {
            background-color: #008a00;
          }
          :has(div.we-red) {
            background-color: #de3134;
          }
          :has(div.we-gold) {
            background-color: #fcca2c;
            color: #000000;
          }
          :has(div.we-blue) {
            background-color: #2d5ecd;
          }
          :has(div.we-gold) .main-wolseley-ribbon-btns > .lang-buttons {
            color: #000000 !important;
          }
          :has(div.we-gold) .main-wolseley-ribbon-Message a:hover {
            text-decoration: underline !important;
          }
          :has(div.we-blue, div.we-red, div.we-green) .main-wolseley-ribbon-content .main-wolseley-ribbon-Message a:hover {
            color: #000000 !important;
          }

          background-color: #656565;
          position: relative;
          right: 0;
          left: 0;
          height: unset;
          min-height: 3.5rem;
          color: #fff;
          display: flex;
          width: 100%;
        }

        .main-wolseley-ribbon-content {
          width: 100%;
          font-size: 1.4rem;
          height: unset;
          margin: 0 auto;
          display: flex;
          place-content: space-between;
          align-content: center;
        }

        @media screen and (max-width: 767px) {
          .main-wolseley-ribbon-Message {
            margin: 0 auto;
          }

          #main-wolseley-ribbon {
            min-height: 4.5rem;
            height: unset;
            max-height: unset;
          }

          .main-wolseley-ribbon-btns {
            &.hideInMobile {
              display: none;
            }
          }
        }

        @media screen and (max-width: 415px) {
          #main-wolseley-ribbon {
            min-height: 6.5rem;
            height: unset;
            max-height: unset;
          }
        }

        .main-wolseley-ribbon-Message {
          max-width: 100%;
          float: none;
          padding: 0.5rem 2rem;
        }

        .main-wolseley-ribbon-btns a,
        .main-wolseley-ribbon-btns p {
          background-color: transparent;
          margin: 0;
        }

        .main-wolseley-ribbon-btns {
          width: auto;
          height: auto;
          float: none;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: baseline;
          margin-right: 0;
          margin-top: 0;
          padding: 0.5rem 2rem;
          text-align: center;
        }

        .main-wolseley-ribbon-btns > a:hover {
          text-decoration: underline !important;
          text-decoration-thickness: 0.3rem !important;
          text-underline-offset: 0.4rem !important;
        }

        @media screen and (max-width: 891px) {
          .main-wolseley-ribbon-btns:has(a.btnFrench.active) {
            display: none !important;
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(TopRibbon), {
  ssr: false,
})
