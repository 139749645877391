export const getNumericPrice = (formattedPrice, quantity = null) => {
  // Remove the dollar sign and space
  const cleanedPrice = formattedPrice.replaceAll(/\$/g, '')

  // Replace a comma with a dot only if a dot does not exist, or replace a comma with an empty string
  const dotFormattedString = cleanedPrice.includes('.')
    ? cleanedPrice.replaceAll(/,/g, '')
    : cleanedPrice.replace(/,/g, '.')

  // Convert the cleaned string to a number
  let price = parseFloat(dotFormattedString.replaceAll(/[\s]/g, ''))

  // Times price by quantity if quantity is passed
  price = quantity ? (price * quantity) : price

  return price
}
