import { useTranslation } from 'react-i18next'
import Button from '../../../components/button/button'
import { useQuery } from '@tanstack/react-query'
import { getCartModalData } from '../../../api-client/cart/get-cart-modal-data'
import { currencyFormatter } from '../../../utils/format-currency'
import { useAuth } from '../../../contexts/auth'
import { CARTMODELPRODUCTSCOUNT, ROUTES, mainSite } from '../../../constants'
import { useRouter } from 'next/router'
import Loader from '../../../components/loader'
import { getNumericPrice } from '@/utils/price'
import InvalidWarning from '@/components/svg/invalid-warning'

export default function AddToCart({ onClose, products, open, displayMoreButton, displayWarningMessage }) {
  const { t } = useTranslation('common')
  const [{ isAuthenticated, permissions }] = useAuth()
  const { locale } = useRouter()

  const { data, isFetching } = useQuery({
    queryKey: [getCartModalData],
    queryFn: getCartModalData,
    enabled: isAuthenticated && open,
    cacheTime: 0,
  })

  const handleProceedToCart = () => {
    window.location.href = `${mainSite}${locale}${ROUTES.ShoppingCart}`
  }

  return (
    <>
      <ul className='cart-list'>
        {products?.map((product, i) => {
          if (i < CARTMODELPRODUCTSCOUNT) {
            const { qty, prodDesc, picture, unitOfMeasure, price, subTotal } = product
            const priceFloat = price?.length > 0 ? (price === 'Call for pricing' || price === 'Appellez pour les prix' ? price : getNumericPrice(price)) : price

            return (
              <li className='cart-item' key={`item_${i}`}>
                <div className='image-container flex justifyCenter alignCenter'>
                  <img alt={prodDesc} src={picture} title={prodDesc} />
                </div>
                <div className='product-info'>
                  <p className='product-name'>{prodDesc}</p>
                  <p className='qnty'>
                    {permissions?.prices && !isNaN(priceFloat) && priceFloat > 0 ? currencyFormatter(priceFloat, locale) + ' |' : ''} {t('wca.qty')} {qty} {unitOfMeasure}.
                  </p>
                  {permissions?.prices && (
                    <p className='price'>{subTotal > -1 && price !== 'Call for pricing' && price !== 'Appellez pour les prix' ? currencyFormatter(subTotal, locale) : t('wca.callforpricing')}</p>
                  )}
                </div>
              </li>
            )
          } else {
            return <li key={`item_${i}`}></li>
          }
        })}
      </ul>
      {displayMoreButton && (
        <div className='cart-more'>
          +{products.length - CARTMODELPRODUCTSCOUNT} {t('wca.more')}
        </div>
      )}
      <hr />
      {isFetching && permissions?.prices && <Loader width='20' height='20' />}
      {!isFetching && (
        <div className='cart-total'>
          <span className='left-span subtotaltxt'>{data?.cartCount > 1 ? t('wca.cartsubtotals').replace('{0}', data?.cartCount) : t('wca.cartsubtotal').replace('{0}', data?.cartCount)}</span>
          {permissions?.prices && <span className='right-span total-price'>{data?.totalBeforeTax == 0 ? t('wca.pending') : currencyFormatter(data?.totalBeforeTax?.replace(',', '.'), locale)}</span>}
          {permissions?.prices && Math.ceil(data?.totalBeforeTax?.replace(',', '.')) > 0 && <span className='second-line-span excludingTaxes'>{t('wca.excludingtaxes')}</span>}
        </div>
      )}
      {displayWarningMessage && (
        <div className='cart-warning-message flex alignStart'>
          <InvalidWarning fill='#3B3B3B' height='16' width='16'></InvalidWarning>
          {t('wca.somepricesoritem')}
        </div>
      )}
      <div className='modal-footer'>
        <Button className='btn-proceed-to-cart' onClick={handleProceedToCart}>
          {t('wca.proceedtominishoppingcart')}
        </Button>
        <Button className='btn-continue-shopping' color='transparent' onClick={onClose}>
          {t('wca.minishoppingcartcontinueshopping')}
        </Button>
      </div>

      <style jsx>{`
        .close {
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 0.6rem;
        }

        .cart-list {
          overflow-y: hidden;
          .cart-item {
            margin: 0;
            padding-bottom: 1rem;
            display: flex;

            .image-container {
              width: 9.2rem;
              height: 9.2rem;
              padding: 0.5rem;
              overflow: hidden;
              border-radius: 0.7rem;
              margin-right: 1rem;
              background: #fff;
              border: 1px solid #d9d9d9;

              img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
              }
            }

            .product-info {
              width: 100%;
              flex: 1;

              .product-name {
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space: normal;
                font-size: 1.6rem;
                line-height: 2.4rem;
                color: #000;
              }
              .qnty {
                font-size: 1.6rem;
                line-height: 2.4rem;
                color: var(--wolseleyGrey);
              }
              .price {
                font-size: 1.6rem;
                font-weight: 700;
                text-align: right;
                line-height: 2.4rem;
                color: var(--wolseleyDarkGrey);
                width: 100%;
                display: flex;
                flex-direction: row-reverse;
              }
            }
          }
        }
        .cart-more {
          display: inline-block;
          width: 100%;
          text-align: center;
          padding: 10px;
          background-color: #f4f9ff;
          color: black;
          font-size: 14px;
          border-left: 3px solid #5f95df;
        }
        hr {
          margin: 8px 0;
          border: 1px solid #d9d9d9;
        }
        .cart-total {
          display: flex;
          flex-wrap: wrap;
          line-height: 2.4rem;
          color: var(--wolseleyDarkGrey);
        }
        .subtotaltext {
          order: 1;
          flex: 0 0 auto;
          font-size: 16px;
        }
        .subtotaltxt {
          font-size: 16px;
        }
        .total-price {
          font-size: 2rem;
          font-weight: 700;
        }
        .right-span {
          order: 2;
          margin-left: auto;
          flex: 0 0 auto;
        }
        .excludingTaxes {
          order: 3;
          width: 100%;
          text-align: right;
          line-height: 2.4rem;
          color: var(--wolseleyDarkGrey);
          font-size: 16px;
        }

        .modal-footer {
          display: grid;
          align-content: center;
          padding-top: 1rem;
          row-gap: 5px;
        }
        :global(.btn-proceed-to-cart) {
          font-size: 16px !important;
        }
        :global(.btn-proceed-to-cart:hover) {
          box-shadow: 0px 0px 0px 3px rgba(205, 46, 49, 0.5);
        }
        :global(.btn-proceed-to-cart:active) {
          background: #a80003;
          border: unset;
          box-shadow: unset;
        }
        :global(.btn-continue-shopping) {
          font-size: 16px !important;
        }

        :global(.btn-continue-shopping:hover) {
          box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.25);
        }
        :global(.btn-continue-shopping:active) {
          border: 1px solid #7d7d7d;
          background: #e6e6e6;
          box-shadow: unset;
        }
        .cart-warning-message {
          background-color: #FFFBE5;
          border: 0.1rem solid #E9CDA3;
          padding: 1rem;
          gap: 0.5rem;
          border-radius: 0.3rem;
          font-size: 1.4rem;
          margin: 1rem 0 1rem 0;
        }
      `}</style>
    </>
  )
}
