import CloseIcon from '@/components/svg/close'

export const CloseButton = ({ onClose }) => {
  return (
    <>
      <button className='btn-close' onClick={onClose} aria-label='close'>
        <CloseIcon />
      </button>
      <style jsx>{`
        .btn-close {
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 7px;
          background-color: transparent;

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }

          &:active {
            background-color: rgba(0, 0, 0, 0.25);
          }
        }
      `}</style>
    </>
  )
}
