import { getCustomParameters } from '../api-client/medallia/get-custom-parameters'

export const setCustomParameters = async () => {
  const language = window.location.href.toLowerCase().includes('/fr') ? 'fr' : 'en'
  window.dataLayer = window.dataLayer || []

  if (window.dataLayer.length > 0 && window.dataLayer[0].email !== undefined) {
    window.dataLayer.shift()
  }

  const customParameters = await getCustomParameters()
  localStorage.setItem('customParameters', btoa(JSON.stringify(customParameters)))

  customParameters.language = language

  const foundIndex = window.dataLayer.findIndex((item) => isEqual(item, customParameters))

  // If found, replace the item; otherwise, add the custom parameters to dataLayer
  if (foundIndex === -1) {
    window.dataLayer.unshift(customParameters)
  }
}

function isEqual(obj1, obj2) {
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null)
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  else return false
}
