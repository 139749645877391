import client from '../client'

const apiUrl = `${process.env.NEXT_PUBLIC_DOMAIN}/MyAccount/SetMyAccountMenuState`

export const setMyAccountMenuState = async (status) => {
  const headers = {
    'Content-Type': 'application/json',
  }
  const params = new URLSearchParams({ status })
  const fullUrl = `${apiUrl}?${params.toString()}`

  return await client(fullUrl, {
    method: 'POST',
    headers,
  })
}
