import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { filterMenuByPermissions } from '@/utils/menu-permission-filter'
import getBackgroundColor from '@/utils/get-bg-color'
import { getMenuSelection } from '@/utils/get-selected-menuitem-details'
import { LayoutSources, ROUTES } from '@/constants'
import { MyAccountConstants } from '@/containers/myaccount/constants'
import { useSideNav } from '@/contexts/side-nav'
import { useAuth } from '@/contexts/auth'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import Breadcrumbs from '../breadcrumbs'
import SideNav from '../side-nav'
import classNames from 'classnames'

const TwoColumnLayout = ({ sideNav, children }) => {
  const router = useRouter()
  const { deviceTypeBasedOnWidth } = useDeviceDetect()
  const [{ permissions }] = useAuth()
  const { sideNavExpanded, activeNavItem, handleNavClick } = useSideNav()
  const { t } = useTranslation(['sidenav'])

  const [menu, setMenu] = useState([])

  const bgColor = getBackgroundColor(router?.pathname)
  const showBreadcrumb = !!!sideNav?.excludeBreadcrumbs && !!activeNavItem?.breadcrumb

  const getMenuItems = () => {
    switch (sideNav?.source) {
      case LayoutSources.myAccount:
        return filterMenuByPermissions(MyAccountConstants.menu, permissions)
      default:
        return []
    }
  }

  const renderBreadCrumbs = () => {
    if (showBreadcrumb) {
      return <Breadcrumbs breadcrumbData={[{ text: t('wca.home'), path: ROUTES.Home }, ...(activeNavItem?.breadcrumb ?? [])]} />
    }
  }

  useEffect(() => {
    if (sideNav?.source && Object.keys(permissions)?.length > 0 && !!!activeNavItem) {
      const menuItems = getMenuItems().map((menuItem) => {
        // Translate title for l1
        const updatedL1 = { ...menuItem.l1, title: t(menuItem.l1.title) }

        // Translate titles for l2 if present
        if (updatedL1.l2 && Array.isArray(updatedL1.l2)) {
          updatedL1.l2 = updatedL1.l2.map((l2Item) => ({
            ...l2Item,
            l1: updatedL1.id,
            title: t(l2Item.title),
          }))
        }

        return { l1: updatedL1 }
      })

      setMenu(menuItems)
      handleNavClick(getMenuSelection(menuItems, router?.pathname?.toLowerCase()))
    }
  }, [permissions])

  return (
    <>
      <div className={classNames('main-content flex justifyEnd', { flexDirectionColumnReverse: deviceTypeBasedOnWidth.mobile, flexDirectionRowReverse: !deviceTypeBasedOnWidth.mobile })}>
        <div className='content flex flexDirectionColumn'>
          {!deviceTypeBasedOnWidth.mobile && renderBreadCrumbs()}
          <main>{children}</main>
        </div>
        {!!sideNav?.source && <SideNav menuItems={menu} />}
        {deviceTypeBasedOnWidth.mobile && renderBreadCrumbs()}
      </div>

      <style jsx global>{`
        body {
          background-color: ${bgColor};
        }

        .mainWrapper {
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;
          background-color: ${bgColor};

          .main-content {
            padding: 2rem 0 5rem 0;
            gap: max(calc(50% - 47.45rem - ${sideNavExpanded ? '27.1rem' : '4.8rem'}), 3rem); /*Adjusting the gap for the content to fit at center with a minimum gap as 3rem */

            .content {
              width: 100%;
              max-width: 94.9rem;
              main {
                min-height: 50vh;
              }
            }
          }
        }

        @media (max-width: 1440px) and (min-width: 1280px) {
          .mainWrapper {
            .main-content {
              gap: max(calc(50% - 47.45rem - ${sideNavExpanded ? '27.1rem' : '4.8rem'}), 2rem); /*Adjusting the gap for the content to fit at center with a minimum gap as 2rem */
            }
          }
        }

        @media (max-width: 1280px) and (min-width: 1280px) {
          .mainWrapper {
            .main-content {
              margin: 0 2rem;
            }
          }
        }

        @media (max-width: 1279px) and (min-width: 1025px) {
          .mainWrapper {
            .main-content {
              gap: max(calc(50% - 47.45rem - 4.8rem), 2rem); /*Adjusting the gap for the content to fit at center with a minimum gap as 2rem */
              margin: 0 2rem;
            }
          }
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          .mainWrapper {
            .main-content {
              gap: 2rem;
              margin: 0 1rem;
            }
          }
        }

        @media (max-width: 767px) {
          .mainWrapper {
            .main-content {
              margin: 0 1rem;
              gap: 1rem;
              .side-nav {
                width: 100%;
              }

              .content {
                max-width: 100%;
                main {
                  padding: 0;
                  width: 100%;
                }
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(TwoColumnLayout), {
  ssr: false,
})
