import client from '../client'

export async function setProfile({ profileId, token }) {
  const params = new URLSearchParams({ profileId })
  const headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return client(`${process.env.NEXT_PUBLIC_DOMAIN}/Profile/SetProfile?${params.toString()}`, {
    headers: { ...headers },
    method: 'POST',
  })
}
