import classnames from 'classnames'

export default function FormField({ adornment, error, ...inputProps }) {
  return (
    <div
      className={classnames(
        'inputContainer flex alignCenter',
        { error },
        inputProps.className
      )}
    >
      <input {...inputProps} />
      {!!adornment && <div className='adornment'>{adornment}</div>}

      <style jsx>{`
        .inputContainer {
          position: relative;
          background: #fff;
          height: 4.2rem;

          input {
            width: 100%;
            height: 100%;
            padding: 0.8rem;
            outline: none;
            background: transparent;
            color: var(--primary-text-color);
            font-size: 1.4rem;
            transition: border-color 0.2s;
            border: 1px solid #ddd;
            border-radius: 0.6rem;
            -webkit-box-shadow: inset 0 0 0 1000px #E9E9E9;

            &:focus {
              border-color: var(--primary-color);
            }

            &::placeholder {
              color: var(--secondary-text-color);
            }
          }

          .adornment {
            position: absolute;
            top: 50%;
            right: 1.9rem;
            transform: translate(0, -50%);
          }

          &.error {
            input {
              border-color: var(--alert-color);
            }
          }
        }

        @media (max-width: 768px) {
          .inputContainer {
            height: 3.5rem;
            border-radius: 1.8rem;

            input {
              font-size: 1.2rem;
            }
          }
        }
      `}</style>
    </div>
  )
}
