import { useCallback, useEffect, useMemo, useState } from 'react'
import equal from 'fast-deep-equal/es6'

export default function useValidation(values, validateFn) {
  const [errorsVisible, setErrorsVisible] = useState(false)
  const [validationState, setValidationState] = useState(() =>
    validateFn(values)
  )

  useEffect(() => {
    const newValidationState = validateFn(values)

    if (!equal(validationState, newValidationState)) {
      setValidationState(newValidationState)
    }
  }, [validationState, values, validateFn])

  const showErrors = useCallback(() => {
    setErrorsVisible(true)
  }, [])

  return [
    showErrors,
    useMemo(
      () => ({
        isFormValid: validationState.isValid,
        errors: errorsVisible ? validationState.errors : {},
      }),
      [validationState, errorsVisible]
    ),
  ]
}
