export const currency = 'CAD'
export const affiliation = 'Wolseley Express'

export const CART_ITEM_SOURCE_TYPE = {
  1: 'Product Search',
  2: 'Product Detail',
  3: 'Draft Order',
  4: 'Web Quote',
  5: 'Order History',
  6: 'My List',
  7: 'Barcode Scanner',
  8: 'Import Excel',
  9: 'Featured Product',
  10: 'Expert Estimator',
  11: 'List Detail',
  12: 'Third Party',
  13: 'Marketing Bundles',
  14: 'Cross-Sell Bundles',
  15: 'Product Recommendations',
  16: 'Digital Flyer',
  17: 'MyList-Custom',
  18: 'MyList-Express',
  19: 'MyList-Shared',
  20: 'ListDetails-Custom',
  21: 'ListDetails-Express',
  22: 'ListDetails-Shared',
  23: 'ListDetails-Top100',
  24: 'Alternate Product(s)',
  25: 'Product Listing',
}

export const CART_SOURCE_PROMOTION_NAMES={
    16: 'Digital Flyer',
}
