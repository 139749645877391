export default function InvalidWarning(props) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='#CE3F00'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.95 10.15C5.95 9.5701 6.4201 9.1 7 9.1C7.5799 9.1 8.05 9.5701 8.05 10.15C8.05 10.7299 7.5799 11.2 7 11.2C6.4201 11.2 5.95 10.7299 5.95 10.15ZM6.31128 3.37417C6.37055 3.04763 6.65636 2.8 7 2.8C7.34364 2.8 7.62945 3.04763 7.68872 3.37417L7.7 3.5V7L7.68872 7.12583C7.62945 7.45237 7.34364 7.7 7 7.7C6.65636 7.7 6.37055 7.45237 6.31128 7.12583L6.3 7V3.5L6.31128 3.37417ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM12.6 7C12.6 3.90721 10.0928 1.4 7 1.4C3.90721 1.4 1.4 3.90721 1.4 7C1.4 10.0928 3.90721 12.6 7 12.6C10.0928 12.6 12.6 10.0928 12.6 7Z'
      />
    </svg>
  )
}
