import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getTopics, getTopicsKey } from './../../api-client/topic/get-topic'
import { acceptCookieLaw } from './../../api-client/common/accept-cookie-law'
import { TOPICS } from '../../constants'
import Button from '../../components/button/button'
import {
  getDisplayCookieLaw,
  getDisplayCookieLawKey,
} from './../../api-client/common/get-display-cookie-law'
import { useRouter } from 'next/router'

const CookieTermsAndConditions = () => {
  const [showCookieNotification, setShowCookieNotification] = useState(false)
  const { locale } = useRouter()
  const languageIdFromClient = /en/i.test(locale) ? 1 : 2
  const { mutate } = useMutation(acceptCookieLaw, {
    onSuccess: () => setShowCookieNotification((prev) => !prev),
  })
  const { t } = useTranslation('footer')

  useQuery({
    queryKey: [getDisplayCookieLawKey],
    queryFn: getDisplayCookieLaw,
    onSuccess: (data) => {
      if (typeof data === 'boolean') setShowCookieNotification(data)
    },
  })

  const handleAcceptButtonClick = useCallback(() => {
    mutate()
  }, [mutate])

  const { data } = useQuery({
    queryKey: [getTopicsKey, TOPICS.CookieTermsAndConditions],
    queryFn: () =>
      getTopics({
        systemNames: TOPICS.CookieTermsAndConditions,
        languageIdFromClient: languageIdFromClient,
      }),
  })

  if (!showCookieNotification) return null

  return (
    <>
      <div className='flex justifyBetween alignCenter cookie-bar-notification'>
        {data && (
          <>
            <div
              className='cookie-terms-and-conditions'
              dangerouslySetInnerHTML={{ __html: data?.FooterCookieBannerText?.body }}
            ></div>
            <Button className='accept-button' onClick={handleAcceptButtonClick}>
              {t('wca.okthanks')}
            </Button>
          </>
        )}
      </div>
      <style jsx>{`
        .cookie-bar-notification {
          background-color: #2e2e2e;
          color: #fff;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0;
          padding: 1.4rem;
          border: 0;
          cursor: default;
          -webkit-tap-highlight-color: #000;
          border-style: solid;
          border-width: 0;
          box-sizing: border-box;
          line-height: 1.5em;
          overflow: hidden;
          position: fixed;
          opacity: 0.9;
          transition: opacity 8s ease;
          z-index: 40001;

          .cookie-terms-and-conditions {
            font-size: 1.6rem;
          }

          :global(.accept-button) {
            background-color: #ce0c0f;
            display: block;
            min-width: unset;
            height: unset;
            cursor: pointer;
            font-weight: 700;
            padding: 1rem 1.2rem;
            text-align: center;
            white-space: nowrap;
            -webkit-transition: background-color 0.2s ease;
            box-sizing: border-box;
            outline: none;
            text-decoration: none;
            border: 0;
            border-radius: 4%;
          }
        }
      `}</style>
    </>
  )
}

export default CookieTermsAndConditions
