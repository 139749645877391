const CloseV2X = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36'
    height='36'
    viewBox='0 0 36 36'
    version='1.1'
    fill='#8c8c8c'
    fillRule='evenodd'
    {...props}
  >
    <path
      d='M 8.922 3.274 C -5.716 13.138, 0.539 35, 18 35 C 35.461 35, 41.716 13.138, 27.078 3.274 C 22.362 0.096, 13.638 0.096, 8.922 3.274 M 12 12.686 C 12 13.063, 12.978 14.413, 14.174 15.686 C 16.349 18, 16.349 18, 14.004 20.496 C 10.949 23.748, 12.252 25.051, 15.504 21.996 C 18 19.651, 18 19.651, 20.496 21.996 C 23.748 25.051, 25.051 23.748, 21.996 20.496 C 19.651 18, 19.651 18, 21.996 15.504 C 25.051 12.252, 23.748 10.949, 20.496 14.004 C 18 16.349, 18 16.349, 15.686 14.174 C 13.345 11.975, 12 11.432, 12 12.686'
      stroke='none'
    />
  </svg>
)

export default CloseV2X
