import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { CloseButton } from '@/components/button/close-button'

export const AlertModal = ({
  children,
  type = 'success',
  shouldCloseOnOutsideClick = true,
  onClose,
}) => {
  const [status, setStatus] = useState('init')

  const handleModalClick = (event) => {
    event.stopPropagation()
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setStatus('close')
    setTimeout(() => onClose(), 500)
  }

  useEffect(() => {
    setTimeout(() => setStatus('open'), 100)

    if (shouldCloseOnOutsideClick) {
      document.addEventListener('click', handleClose)
    }

    return () => {
      if (shouldCloseOnOutsideClick) {
        document.removeEventListener('click', handleClose)
      }
    }
  }, [])

  return (
    <>
      <div
        className={classNames('container flex flexDirectionRow justifyBetween', {
          init: status === 'init',
          open: status === 'open',
          close: status === 'close',
          success: type === 'success',
        })}
        onClick={handleModalClick}
      >
        <div className='content'>{children}</div>
        <div className='actions'>
          <CloseButton onClose={handleClose} />
        </div>
      </div>
      <style jsx>{`
        .container {
          position: fixed;
          top: 2.5rem;
          gap: 20px;
          border: 1px solid;
          border-bottom-width: 2px;
          border-radius: 4px;
          padding: 0.8rem 1rem 0.8rem 1.5rem;
          font-size: 14px;
          box-shadow: 0px 31px 31.2px -23px #00662480;
          transition: all 0.5s ease-out;
          width: calc(100% - 2rem);

          &.init {
            right: -38rem;
          }

          &.open {
            right: 1rem;
          }

          &.close {
            right: 1rem;
            opacity: 0;
          }

          .content {
            padding: 1rem 0;
          }
        }

        .success {
          background-color: #f2fff4;
          border-color: #12a244;
        }

        .actions {
          position: relative;
        }

        @media only screen and (min-width: 768px) {
          .container {
            top: 3rem;
            width: 33.2rem;

            &.open {
              right: 2rem;
            }

            &.close {
              right: 2rem;
            }
          }
        }
      `}</style>
    </>
  )
}
