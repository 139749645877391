import { useEffect } from 'react'

export default function useClickOutside(
  ref,
  callback,
  off,
  mouseDownTarget
) {
  useEffect(() => {
    const listener = (event) => {
      if (event?.target?.nodeName === 'HTML') return

      const isUserMenu = document.querySelector(".accountLink")?.contains(event?.target)
      if (mouseDownTarget) {
        if (
          ref.current &&
          !(event.path || event.composedPath()).includes(ref.current) &&
          event.target === mouseDownTarget?.current
        )
          callback()
      } else {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target) && !isUserMenu) {
          return
        }
        if(!isUserMenu){
          callback(event)
        }
      }
    }

    if (!off) {
      document.addEventListener('mouseup', listener)
      document.addEventListener('touchstart', listener)
    }

    return () => {
      document.removeEventListener('mouseup', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, callback, off, mouseDownTarget])
}
