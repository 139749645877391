import { useCallback, useRef } from 'react'
import Avatar from './../../../components/svg/avatar'
import AuthModal from '../../../containers/auth/modals'
import useClickOutside from '../../../hooks/use-clickoutside'
import { useTranslation } from 'react-i18next'
import useDeviceDetect from '../../../hooks/useDeviceDetect'

export default function SignInMenu({
  toggleSiginginDropdownIsOpen,
  handleForgotPasswordModal,
  siginginDropdownIsOpen,
}) {
  const dropdownRef = useRef()
  const { t } = useTranslation('header')
  const deviceType = useDeviceDetect()

  const handleAuthModalClose = useCallback(() => {
    toggleSiginginDropdownIsOpen()
  }, [toggleSiginginDropdownIsOpen])

  useClickOutside(dropdownRef, toggleSiginginDropdownIsOpen, !siginginDropdownIsOpen)

  return (
    <div className='wrapper'>
      <>
        <div
          className='flex alignCenter column-gap5 signInWrapperClass'
          onClick={() => {
            toggleSiginginDropdownIsOpen()
          }}
        >
          <Avatar alt='user avatar' width={24} height={24} />
          <span className='signInLink'>
            {t('wca.signin')} / {t('wca.register')}
          </span>
          {!deviceType.isMobile && (
            <span
              className={`signinnavlinkArrow arrow ${siginginDropdownIsOpen ? 'up' : 'down'}`}
            ></span>
          )}
        </div>
        {siginginDropdownIsOpen && (
          <div className='menuWrapper' ref={dropdownRef}>
            <AuthModal
              onClose={handleAuthModalClose}
              handleForgotPasswordModal={handleForgotPasswordModal}
            />
          </div>
        )}
      </>

      <style jsx>{`
        .wrapper {
          position: relative;

          .menuWrapper {
            position: absolute;
            right: 0;
            background: #fff;
            margin-top: 1rem;
            z-index: 999999;
          }

          .signInLink {
            font-weight: bold;
            font-size: 1.4rem;
            color: var(--wolseleyRed);
            margin-right: 5px;
            cursor: pointer;
          }

          .arrow {
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
          }

          .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }

          .up {
            transform: rotate(223deg);
            -webkit-transform: rotate(223deg);
          }
          .signinnavlinkArrow {
            margin-left: 2px;
          }
          .column-gap5 {
            gap: 0px 5px;
          }
        }
      `}</style>
    </div>
  )
}
