const CloseIcon = (props) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.2784 0.295367C10.6722 -0.0984557 11.3107 -0.0984557 11.7046 0.295367C12.0984 0.68919 12.0984 1.3277 11.7046 1.72153L7.42615 5.99996L11.7046 10.2785C12.0985 10.6723 12.0985 11.3108 11.7046 11.7046C11.3108 12.0985 10.6723 12.0985 10.2785 11.7046L6 7.42612L1.72151 11.7046C1.32769 12.0985 0.689185 12.0985 0.295365 11.7046C-0.0984549 11.3108 -0.0984552 10.6723 0.295365 10.2785L4.57385 5.99996L0.295447 1.72153C-0.0983735 1.3277 -0.0983735 0.68919 0.295447 0.295367C0.689267 -0.0984557 1.32778 -0.0984557 1.7216 0.295367L6 4.5738L10.2784 0.295367Z'
      fill='#3B3B3B'
    />
  </svg>
)

export default CloseIcon
