import { CommonPermissionsMap, ROUTES, StatusIndicatorColorMap } from '@/constants'
import { MenuItemType } from '@/components/side-nav/constants'

export const MyAccountConstants = {
  menuIcons: {
    quickTools: 'QuickTools',
    accountOverview: 'AccountOverview',
    orders: 'Orders',
    quotes: 'Quotes',
    billing: 'Billing',
    invoiceGateway: 'InvoiceGateway',
    manageAccount: 'ManageAccount',
    myBusiness: 'MyBusiness',
    resources: 'Resources',
    lists: 'Lists',
    myorder: 'MyOrder',
  },
  actions: {
    downloadOrderedProducts: 'downloadOrderedProducts',
    uploadSpreadSheet: 'uploadSpreadSheet',
    myOrderedProducts: 'myOrderedProducts',
  },
}

MyAccountConstants.menu = [
  {
    l1: {
      id: 'L1_1',
      title: 'wca.quickTools',
      icon: MyAccountConstants.menuIcons.quickTools,
      l2: [
        {
          id: 'L2_1_1',
          title: 'wca.downloadOrderedProducts',
          type: MenuItemType.linkAction,
          action: MyAccountConstants.actions.downloadOrderedProducts,
          avoidActiveState: true,
        },
        {
          id: 'L2_1_2',
          title: 'wca.uploadSpreadSheet',
          type: MenuItemType.link,
          action: MyAccountConstants.actions.uploadSpreadSheet,
          route: ROUTES.UploadSpreadsheet,
          avoidActiveState: true,
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_2',
      title: 'wca.accountoverview',
      icon: MyAccountConstants.menuIcons.accountOverview,
      l2: [
        {
          id: 'L2_2_1',
          title: 'wca.myaccountdashboard',
          type: MenuItemType.action,
          route: ROUTES.MyWolseley,
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_3',
      title: 'wca.orders',
      icon: MyAccountConstants.menuIcons.orders,
      l2: [
        {
          id: 'L2_3_1',
          title: 'wca.myOrders',
          type: MenuItemType.route,
          route: ROUTES.MyOrders,
          permissions: [CommonPermissionsMap.orderStatus],
        },
        {
          id: 'L2_3_2',
          title: 'wca.myDraftOrders',
          type: MenuItemType.route,
          route: ROUTES.DraftOrders,
          permissions: [CommonPermissionsMap.cart],
        },
        {
          id: 'L2_3_3',
          title: 'wca.myOrderedProducts',
          type: MenuItemType.action,
          action: MyAccountConstants.actions.myOrderedProducts,
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_4',
      title: 'wca.quotes',
      icon: MyAccountConstants.menuIcons.quotes,
      l2: [
        {
          id: 'L2_4_1',
          title: 'wca.myquotes',
          type: MenuItemType.route,
          route: ROUTES.AllWebQuote,
          permissions: [CommonPermissionsMap.webQuote],
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_5',
      title: 'wca.lists',
      icon: MyAccountConstants.menuIcons.lists,
      l2: [
        {
          id: 'L2_5_1',
          title: 'wca.myorderedproductstop',
          type: MenuItemType.route,
          route: ROUTES.MyOrderedProductsTop100,
        },
        {
          id: 'L2_5_2',
          title: 'wca.mylists',
          type: MenuItemType.route,
          route: ROUTES.MyLists,
        },
        {
          id: 'L2_5_3',
          title: 'wca.expresslists',
          type: MenuItemType.route,
          route: ROUTES.ExpressLists,
          permissions: [CommonPermissionsMap.expressLists],
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_6',
      title: 'wca.manageaccount',
      icon: MyAccountConstants.menuIcons.manageAccount,
      l2: [
        {
          id: 'L2_6_1',
          title: 'wca.mysettings',
          type: MenuItemType.route,
          route: ROUTES.Mysettings,
        },
        {
          id: 'L2_6_2',
          title: 'wca.paymentmethods',
          type: MenuItemType.route,
          route: ROUTES.Paymentmethods,
          permissions: [CommonPermissionsMap.cart],
        },
        {
          id: 'L2_6_3',
          title: 'wca.usermanagement',
          type: MenuItemType.route,
          route: ROUTES.Usermanagement,
          permissions: [CommonPermissionsMap.user],
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_7',
      title: 'wca.billing',
      icon: MyAccountConstants.menuIcons.billing,
      l2: [
        {
          id: 'L2_7_1',
          title: 'wca.spendtracker',
          type: MenuItemType.route,
          route: ROUTES.SpendTracker,
          permissions: [CommonPermissionsMap.invoiceGateway],
        },
        {
          id: 'L2_7_2',
          title: 'wca.invoicegateway',
          type: MenuItemType.route_NewWindow,
          route: ROUTES.InvoiceGateway,
          icon: MyAccountConstants.menuIcons.invoiceGateway,
          permissions: [CommonPermissionsMap.invoiceGateway],
          avoidActiveState: true,
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_8',
      title: 'wca.mybusiness',
      icon: MyAccountConstants.menuIcons.myBusiness,
      l2: [
        {
          id: 'L2_8_1',
          title: 'wca.createaquote',
          type: MenuItemType.route,
          route: ROUTES.CreateQuote,
          permissions: [CommonPermissionsMap.myBusiness],
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_9',
      title: 'wca.resources',
      icon: MyAccountConstants.menuIcons.resources,
      l2: [
        {
          id: 'L2_9_1',
          title: 'wca.websiteTutorials',
          type: MenuItemType.route,
          route: ROUTES.WebsiteTutorials,
        },
        {
          id: 'L2_9_2',
          
          title: 'wca.support',
          type: MenuItemType.route,
          route: ROUTES.Support,
        },
      ],
    },
  },
]

export const RowItemStatus = {
  IN_PROGRESS: 0,
  CONTACT_BRANCH: 1,
  SHIPPED: 2,
  SUBMITTED: 3,
  IN_REVIEW: 4,
  EXPIRED: 5,
}

export const OrderStatusMap = {
  [RowItemStatus.IN_PROGRESS]: {
    text: 'wca.statusInProgress',
    color: StatusIndicatorColorMap.WHITE,
  },
  [RowItemStatus.CONTACT_BRANCH]: {
    text: 'wca.statusContactBranch',
    color: StatusIndicatorColorMap.RED,
  },
  [RowItemStatus.SHIPPED]: {
    text: 'wca.statusShipped',
    color: StatusIndicatorColorMap.GREEN,
  },
  [RowItemStatus.SUBMITTED]: {
    text: 'wca.statusSubmitted',
    color: StatusIndicatorColorMap.ORANGE,
  },
  [RowItemStatus.IN_REVIEW]: {
    text: 'wca.statusInReview',
    color: StatusIndicatorColorMap.YELLOW,
  },
  [RowItemStatus.EXPIRED]: {
    text: 'wca.statusExpired',
    color: StatusIndicatorColorMap.RED,
  },
}
