import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import LinkAnchor from '../button/link-anchor'
import { useAuth } from '../../contexts/auth'
import { useQuery } from '@tanstack/react-query'
import { getTopics, getTopicsKey } from '../../api-client/topic/get-topic'
import isNotEmptyObject from '../../utils/is-not-empty-object'
import { useRouter } from 'next/router'
import Medallia from '../medallia'
import Cookies from 'universal-cookie'
import { CookiesKey } from '../../constants'
import dynamic from 'next/dynamic'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN
const footer = 'Footer'
const cookie = new Cookies()

const Footer = () => {
  const [{ isAuthenticated, activeProfile }, {}] = useAuth()
  const { t } = useTranslation('footer')
  const regionIdFromClient = cookie.get(CookiesKey.SELECTED_REGION_ID)
  const { locale } = useRouter()
  const languageIdFromClient = /en/i.test(locale) ? 1 : 2

  const { data } = useQuery({
    queryKey: [getTopicsKey, footer],
    queryFn: () => getTopics({ systemNames: footer, regionIdFromClient, languageIdFromClient }),
  })

  return (
    <>
      <div className='footer-topband'></div>
      <footer className={`flex flexWrapNone justifyBetween footer`}>
        <div className='col1' dangerouslySetInnerHTML={{ __html: data?.Footer_AboutWolseley?.body }}></div>
        <div className='col2'>
          {!isAuthenticated ? (
            <h5 className='content-title'>
              <LinkAnchor href={`${mainSite}${locale}/login`}>{t('wca.signin')}</LinkAnchor> <LinkAnchor href={`${mainSite}${locale}/UserRegistration/AccountPage`}>/ {t('wca.register')}</LinkAnchor>{' '}
            </h5>
          ) : (
            <h5 className='content-title'>
              <Link href={`${mainSite}${locale}/MyWolseley/Home/`}>{t('wca.mywolseley')}</Link>
            </h5>
          )}
          <div className='content-title'>
            <Link href={`${mainSite}${locale}`}>{t('wca.shop')}</Link>
          </div>
          <div className='content-title'>
            <LinkAnchor disabled={false} href={`${mainSite}${locale}/ServicePage/GetServicePage/`} aria-label='Services' navigationEvent={{ elementName: 'Services', elementLocation: footer }}>
              {t('wca.services')}
            </LinkAnchor>
          </div>
          <div className='content-title'>
            <LinkAnchor disabled={false} href={`${mainSite}${locale}/SupportPage/GetSupportPage/`} aria-label='Support' navigationEvent={{ elementName: 'Support', elementLocation: footer }}>
              {t('wca.support')}
            </LinkAnchor>
          </div>
          <p>
            <LinkAnchor
              disabled={false}
              href={`${mainSite}${locale}/termsandconditions/`}
              aria-label='Terms and Conditions'
              navigationEvent={{ elementName: 'Terms and Conditions', elementLocation: footer }}
              target='_blank'
            >
              {t('wca.termscondition')}
            </LinkAnchor>
          </p>
          <p>
            <LinkAnchor
              disabled={false}
              href={`${mainSite}${locale}/privacy-policy/`}
              target='_blank'
              aria-label='Privacy Policy'
              navigationEvent={{ elementName: 'Privacy Policy', elementLocation: footer }}
            >
              {t('wca.privacypolicy')}
            </LinkAnchor>
          </p>
          <p>
            <LinkAnchor
              disabled={false}
              href={`${mainSite}${locale}/TermsOfUse/GetTermsOfUsePage/`}
              aria-label='Terms of Use'
              target='_blank'
              navigationEvent={{ elementName: 'Terms of Use', elementLocation: footer }}
            >
              {t('wca.termsofuse')}
            </LinkAnchor>
          </p>
        </div>
        <div className='col3' dangerouslySetInnerHTML={{ __html: data?.Footer_ContactUs?.body }}></div>
        {!isAuthenticated && <div className='col4' dangerouslySetInnerHTML={{ __html: data?.Footer_Branch_UnAuthenticated?.body }}></div>}
        {isAuthenticated && (
          <div className='col4'>
            <h5 className='content-title'>{t('wca.mybranch')}</h5>
            <div className='content-mybranch'>
              {isNotEmptyObject(activeProfile) && (
                <p className='mbtm-10'>
                  {activeProfile?.branchName}
                  <br />
                  {`${activeProfile?.branchAddressLineOne}, ${activeProfile?.branchAddressLineTwo ?? ''}`}
                  <br />
                  {`${activeProfile?.city}, ${activeProfile?.stateAbbrevation} ${activeProfile?.postalCode}`}
                </p>
              )}
              {!!activeProfile?.branchHours?.length && (
                <table className='table tblBranchHours'>
                  <tbody>
                    <tr>
                      <td colSpan='3'>{t('wca.hours')}:</td>
                    </tr>
                    {activeProfile?.branchHours?.map((branch, i) => {
                      return (
                        <tr key={i}>
                          <td>{branch?.daysOfWeek}</td>
                          <td> - </td>
                          <td className='alignRight'>{branch?.hours}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              <div className='footer-arrows'>
                <Link href={`${mainSite}${locale}/BranchLocations/Show/`} target='_blank'>
                  <a>{t('wca.viewmap')}</a>
                </Link>
                <div className='arrow-icon'> </div>
              </div>
              {(!!activeProfile?.branchPhone?.length || !!activeProfile?.branchFax?.length) && (
                <div className='footer-phone-fax'>
                  {!!activeProfile?.branchPhone?.length && (
                    <div>
                      {t('wca.phone')}: {activeProfile?.branchPhone}
                    </div>
                  )}
                  {!!activeProfile?.branchFax?.length && (
                    <div>
                      {t('wca.fax')}: {activeProfile?.branchFax}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <Medallia />
      </footer>

      <style jsx global>
        {`
          .footer-topband {
            display: flex;
            margin: 0;
            padding: 0 0 0 0;
            height: 37px;
            background: #cccccc;
            width: 100%;
          }

          .footer {
            padding: 4rem;
            padding-bottom: 7rem;
            background: #3b3b3b;
            color: #cccccc;
            font-size: 1.5rem;

            .content-title {
              font-size: 1.8rem;
              color: white;
              padding: 0 0 1.4rem 0;
            }

            .footer-arrows {
              a {
                color: white;
                font-size: 1.4rem;
                letter-spacing: 1px;
                padding-right: 10px;
                text-decoration: none;
              }

              .arrow-icon {
                width: 13px;
                height: 14px;
                background: url(/images/sprite-icons.png) no-repeat;
                background-position: -152px -200px;
                display: inline-block;
                vertical-align: baseline;
              }
            }

            .footer-phone-fax,
            .footer-phone-fax > div {
              padding: 2px 0;
            }

            p {
              font-size: 1.5rem;
              line-height: 2.5rem;
            }

            .social-icons {
              padding: 3rem 0 0 0;

              a {
                text-decoration: none;
              }
            }

            .facebook {
              background: url(/images/sprite-icons.png) no-repeat;
              background-position: -10px -143px;
              width: 38px;
              height: 38px;
              float: left;
            }

            .twitter {
              background: url(/images/sprite-icons.png) no-repeat;
              background-position: -10px -85px;
              width: 38px;
              height: 38px;
              float: left;
            }

            .linkedin {
              background: url(/images/sprite-icons.png) no-repeat;
              background-position: -210px -10px;
              width: 38px;
              height: 38px;
              float: left;
            }

            .facebook:hover {
              background: url(/images/sprite-icons.png) no-repeat;
              background-position: -68px -143px;
            }

            .twitter:hover {
              background: url(/images/sprite-icons.png) no-repeat;
              background-position: -126px -143px;
            }

            .linkedin:hover {
              background: url(/images/sprite-icons.png) no-repeat;
              background-position: -152px -68px;
            }

            .col1,
            .col3,
            .col4 {
              p {
                padding-bottom: 2.5rem;
              }
            }

            .tblBranchHours {
              padding-bottom: 2.5rem;
              td {
                padding: 0 10px 0 0;
                border: 0;
              }
            }
          }

          @media (min-width: 768px) and (max-width: 1024px) {
            .footer {
              padding: 3rem 0rem 8rem 3rem;
              .content-title {
                font-size: 1.6rem;
              }
              p {
                font-size: 1.4rem;
                padding-bottom: 1rem;
              }

              .tblBranchHours {
                td {
                  font-size: 1.4rem;
                }
              }

              .col1,
              .col2,
              .col3,
              .col4 {
                flex: 1;
                padding-right: 1rem;
              }
            }
          }

          @media (width: 768px) and (height: 1024px) {
            .footer {
              padding: 3rem 0rem 24rem 3rem;
            }
          }
        `}
      </style>
      <style jsx>{`
        .col1,
        .col2,
        .col3,
        .col4 {
          max-width: 30rem;
          padding-right: 2rem;
          padding-bottom: 3rem;
        }

        @media (max-width: 767px) {
          .footer {
            .col4 {
              padding-bottom: 11rem;
            }

            flex-wrap: wrap;

            .content-title {
              font-size: 1.6rem;
              padding: 0 0 1.4rem 0;
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(Footer), {
  ssr: false,
})
