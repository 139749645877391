export default function PhoneIcon(props) {
  return (
    <svg
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill="#212121"
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d="M2.43055 0.0640824C3.112 -0.141323 3.83749 0.160778 4.1763 0.774007L4.23198 0.885414L4.72819 1.9892C5.03771 2.67771 4.9024 3.47976 4.39637 4.02778L4.29716 4.12746L3.51447 4.85733C3.3736 4.99054 3.47934 5.50728 3.98957 6.39102C4.4486 7.18607 4.82196 7.55781 5.00575 7.57815L5.03796 7.57786L5.07772 7.57008L6.61611 7.09974C7.04126 6.96976 7.49925 7.10284 7.78885 7.43127L7.85762 7.51741L8.87531 8.92785C9.28924 9.50152 9.24353 10.2801 8.78174 10.8006L8.69028 10.8951L8.2831 11.2807C7.53753 11.9868 6.4488 12.1934 5.49639 11.8097C4.04463 11.2247 2.7259 9.88746 1.52781 7.81231C0.327199 5.73279 -0.171089 3.91884 0.0516923 2.36693C0.189569 1.40652 0.844648 0.604845 1.74821 0.274363L1.89291 0.226143L2.43055 0.0640824Z"/>
    </svg>
  )
}
