export default function Button({ children, size, color, type, ...buttonProps }) {
  return (
    <button type={type ? type : 'button'} {...buttonProps} aria-label={children}>
      {children}

      <style jsx>{`
        button {
          transition: opacity 0.2s;
          color: var(--primary-button-color);
          font-weight: 500;
        }
      `}</style>

      <style jsx>{`
        button {
          padding: ${size === 'sm' ? '0 1rem' : '0 1.6rem'};
          height: ${size === 'sm' ? 2.2 : size === 'lg' ? 4.4 : 4}rem;
          border-radius: ${size === 'sm' ? 0.4 : size === 'lg' ? 1 : 0.6}rem;
          font-size: ${size === 'sm' ? 0.8 : size === 'lg' ? 1 : 1.4}rem;
          background: ${color === 'secondary'
            ? 'var(--secondary-button-bg)'
            : color === 'alert'
            ? 'var(--alert-color)'
            : color === 'success'
            ? 'var(--success-color)'
            : color === 'transparent'
            ? 'transparent'
            : 'var(--primary-button-bg)'};
          border: 1px solid
            ${color === 'secondary'
              ? 'var(--secondary-button-bg)'
              : color === 'alert'
              ? 'var(--alert-color)'
              : color === 'success'
              ? 'var(--success-color)'
              : color === 'transparent'
              ? '#000'
              : 'var(--primary-button-bg)'};
          color: ${color === 'transparent' ? '#000' : '#fff'};
        }

        button:disabled {
          color: rgba(0, 0, 0, 0.75);
          border: 1px solid rgba(0, 0, 0, 0.5);
          background: rgba(0, 0, 0, 0.3);
          box-shadow: none;
        }

        button:disabled:hover {
          background: rgba(0, 0, 0, 0.3);
          box-shadow: none;
        }

        @media (max-width: 767px) {
          button {
            padding: ${size === 'sm' ? '0 2.8rem' : '0 3rem'};
            border-radius: ${size === 'sm' ? 0.4 : 0.6}rem;
            font-size: 1.3rem;
          }
        }
      `}</style>
    </button>
  )
}
