import Cookies from 'universal-cookie'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { CookiesKey } from '@/constants'
import { getTopics, getTopicsKey } from '@/api-client/topic/get-topic'

const warningMessage = 'Header_Warning_Message'
const cookie = new Cookies()

const WarningBannerTopic = () => {
  const { locale } = useRouter()
  const [showBanner, setShowBanner] = useState(true)

  const languageIdFromClient = /en/i.test(locale) ? 1 : 2
  const regionIdFromClient = cookie.get(CookiesKey.SELECTED_REGION_ID)

  const hideBanner = () => setShowBanner(false)

  const { data: ribbonData } = useQuery({
    queryKey: [getTopicsKey, warningMessage],
    queryFn: () => getTopics({ systemNames: warningMessage, regionIdFromClient, languageIdFromClient }),
  })

  const warningMessageData = ribbonData ? ribbonData[warningMessage]?.body : null

  if (!showBanner || !warningMessageData) return

  return (
    <>
      <div className='warning-banner-topic'>
        <div className='icon' />
        <div className='content' dangerouslySetInnerHTML={{ __html: warningMessageData }} />
        <a className='close-button cursor-pointer' onClick={hideBanner}>
          X
        </a>
      </div>
      <style jsx>{`
        .warning-banner-topic {
          background-color: #f0f0f0;
          position: relative;
          min-height: 3.8rem;
          padding-bottom: 0.8rem;

          .icon {
            background: url(${window.location.origin}/images/hard_hold_alert.png) no-repeat center;
            background-size: contain;
            height: 3.8rem;
            width: 3.8rem;
            float: left;
            color: transparent;

            :global(svg) {
              flex-shrink: 0;
            }
          }

          .content {
            text-align: center;
            width: 88%;
            margin-left: 6%;
            margin-right: 0;
            padding-bottom: 0.8rem;

            :global(p) {
              background-color: #f0f0f0;
              color: black;
              font-size: 1.4rem;
              text-align: left !important;
              margin-bottom: -2%;
              width: 75%;
            }
          }

          .close-button {
            position: absolute;
            right: 2.7rem;
            top: 0.8rem;
            font-weight: bold;
            color: inherit;
            text-decoration: none;
            font-size: 13px;
          }
        }

        @media (max-width: 767px) {
          .warning-banner-topic {
            padding-bottom: 0;
            .content {
              margin-left: 14%;
              width: 78%;
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(WarningBannerTopic), { ssr: false })
