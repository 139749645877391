export default function CheckMarkIcon(props) {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='#F49E1D'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M13 0.5C19.9036 0.5 25.5 6.09644 25.5 13C25.5 19.9036 19.9036 25.5 13 25.5C6.09644 25.5 0.5 19.9036 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5ZM18.3962 9.01007C18.1114 8.72531 17.6615 8.70633 17.3548 8.95312L17.2913 9.01007L10.9688 15.3326L8.70868 13.0726C8.40358 12.7675 7.90892 12.7675 7.60382 13.0726C7.31907 13.3573 7.30008 13.8072 7.54687 14.114L7.60382 14.1774L10.4163 16.9899C10.7011 17.2747 11.151 17.2937 11.4577 17.0469L11.5212 16.9899L18.3962 10.1149C18.7013 9.80983 18.7013 9.31517 18.3962 9.01007Z' />
    </svg>
  )
}
