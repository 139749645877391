import dynamic from 'next/dynamic'
import MenuItemL2 from './menu-item-l2'
import classNames from 'classnames'

const SubMenuPopup = ({ l1Item, state, referenceElement = null, menuPopupRef }) => {
  const popupWidth = Math.min(300, document.body.offsetWidth - 20)
  const { id, title, l2 = [] } = l1Item
  const { left, top, height } = referenceElement?.getBoundingClientRect() ?? {}

  return (
    <>
      <div ref={menuPopupRef} id={`menu-item-l1-popup_${id}`} className={classNames('menu-item-l1-popup flex flexDirectionColumn', { open: state === 'open' })}>
        <span className='l1-title'>{title}</span>
        {l2?.map((subMenuItem) => (
          <MenuItemL2 key={subMenuItem.id} item={subMenuItem} markSelected={true} isPopup />
        ))}
      </div>
      <style jsx>{`
        .menu-item-l1-popup {
          width: ${popupWidth}px;
          padding: 0.5rem;
          background: #ffffff;
          box-shadow: 0 3.4rem 3.6rem -2.2rem #00000066;
          border-radius: 0.7rem;
          position: absolute;
          border: 0.1rem solid #dddddd;
          left: ${!!left ? (left + popupWidth < document.body.offsetWidth ? `${left}px` : 'auto') : 'calc(100% + 2rem)'};
          top: ${!!top ? `${top + height + 15}px` : '0'};
          right: 1rem;
          opacity: 0;
          transition: opacity 0.3s ease;

          .l1-title {
            font-size: 1rem;
            font-weight: 700;
            padding: 1rem;
            padding-bottom: 0.5rem;
            color: #757575;
            text-transform: uppercase;
          }

          &.open {
            opacity: 1;
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(SubMenuPopup), { ssr: false })
