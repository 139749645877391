import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CloseButton } from '../button/close-button'
import classNames from 'classnames'

const animationDelay = 600

const PopupModal = ({ onClose, headerTitle, children, dismissable, animation = 'fade-in-out', ...rest }) => {
  const modalContent = useRef(null)
  const [isOpen, setIsOpen] = useState(null)
  const [overFlow, setOverFlow] = useState(false)

  const handleClose = useCallback((modalCloseCallback = null, ...args) => {
    setIsOpen(false)
    setTimeout(() => {
      onClose()

      // close from the modal content
      if (modalCloseCallback instanceof Function) {
        modalCloseCallback(...args)
      }
    }, animationDelay)
  })

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
      setOverFlow(modalContent?.current?.scrollHeight > modalContent?.current?.clientHeight)
    }, 100)
  }, [])

  return (
    <div className='modal-container flex' onClick={() => dismissable && onClose()}>
      <div
        ref={modalContent}
        className={classNames('modal-content flex flexDirectionColumn', {
          open: isOpen,
          close: !isOpen,
          'fade-in-out': animation === 'fade-in-out',
          'h-100': overFlow,
        })}
      >
        <div className='modal-title flex alignCenter justifyBetween'>
          <div className='icon-title flex alignStart justifyBetween'>
            {!!rest?.icon && rest.icon}
            <div className='title'>{headerTitle}</div>
          </div>
          {dismissable && <CloseButton onClose={onClose} />}
        </div>
        <div className='modal-body'>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onClose: (...args) => handleClose(child.props.onClose, ...args),
            }),
          )}
        </div>
      </div>

      <style jsx>{`
        :global(body) {
          overflow: ${rest?.allowBackgroundInteraction ? 'auto' : 'hidden'};
        }

        .modal-container {
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #00000040;

          .modal-content {
            min-width: calc(min(300px, 100%));
            max-width: calc(min(450px, 100%));
            max-height: min(63.7rem, calc(100% - 2rem));
            height: auto;
            width: 450px;
            background: #ffffff;
            overflow: hidden;
            margin: 0 auto;
            align-self: center;
            border-radius: 13px;

            &.h-100 {
              height: 63.7rem;
            }

            &.fade-in-out {
              opacity: 0;
              transition: opacity ${animationDelay}ms ease;

              &.open {
                opacity: 1;
              }
            }

            .modal-title {
              padding: 0 1.3rem 0 1.8rem;
              flex: 0 0 6.2rem;
              background: ${rest.background ?? '#F4F9FF'};
              border-bottom: 2px solid ${rest.border ?? 'var(--ui-accessible-blue)'};

              .icon-title {
                gap: 1.2rem;

                .title {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 18.4px;
                  padding-bottom: 0.1rem;
                }
              }
            }

            .modal-body {
              flex: 1 1 auto;
              height: calc(100% - 6.2rem);
            }
          }
        }

        @media (max-width: 450px) {
          .modal-container {
            .modal-content {
              max-width: 300px;
            }
          }
        }
      `}</style>
    </div>
  )
}

export default React.memo(PopupModal)
