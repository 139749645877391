import client from '../client'

export async function forgotPassword({ customerLastName, customerEmail }) {
  const params = new URLSearchParams({ customerLastName, customerEmail})
  const headers = { 'Content-Type': 'application/json' }
  return client(`${process.env.NEXT_PUBLIC_DOMAIN}/CustomerDetails/ForgetPassword?${params.toString()}`, {
    headers: { ...headers },
    method: 'POST',
  })
}
