const InfoIconBlue = (props) => (
  <svg width='50' height='50' viewBox='0 0 50 50' version='1.1' {...props}>
    <path d='' stroke='none' fill='#0c64bc' fillRule='evenodd' />
    <path
      d='M 14.500 2.900 C 5.815 6.930, 0.986 14.926, 1.022 25.218 C 1.072 39.537, 10.738 49.029, 25.218 48.978 C 39.315 48.929, 49.065 39.180, 49.133 25.066 C 49.180 15.228, 44.033 6.859, 35.500 2.900 C 30.132 0.409, 19.868 0.409, 14.500 2.900 M 25.667 11.667 C 25.300 12.033, 25 13.190, 25 14.236 C 25 17.090, 29.402 16.189, 29.820 13.250 C 30.055 11.593, 29.638 11, 28.236 11 C 27.190 11, 26.033 11.300, 25.667 11.667 M 23.500 22.569 C 19.465 24.662, 18.175 26.029, 20.908 25.314 C 22.805 24.818, 23.030 25.063, 22.560 27.120 C 19.733 39.499, 20.068 40.567, 25.846 37.579 C 29.495 35.693, 30.779 33.984, 28.001 34.710 C 25.662 35.322, 25.655 35.240, 27.431 27.812 C 29.179 20.502, 28.704 19.869, 23.500 22.569'
      stroke='none'
      fill='#0c64b4'
      fillRule='evenodd'
    />
  </svg>
)
export default InfoIconBlue
