import { useTranslation } from 'react-i18next'

export default function Message() {
  const { t } = useTranslation('header')
  sessionStorage.setItem('_hardAlertShown', true)

  return (
    <>
      <div className='wrapper'>
        <div className='title'>{t('Wca.YouCannotTransact')}</div>

        <div className='contact flex alignEnd flexDirectionColumn justifyBetween'>
          <div className='col'>
            <div className='province'>
              {t('wca.ontario')} & {t('wca.atlantic')}
            </div>
            <div>1-888-419-9969</div>
          </div>
          <div className='col'>
            <div className='province'>{t('wca.quebec')}</div>
            <div>1-800-557-7331</div>
          </div>
          <div className='col'>
            <div className='province'>{t('wca.westerncanada')}</div>
            <div>1-855-214-6456</div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .wrapper {
          text-align: center;
          padding: 5rem 3rem;
        }
        .title {
          font-size: 2.2rem;
          color: var(--wolseleyGrey);
          padding-bottom: 3rem;
        }
        .contact {
          position: relative;
          left: 25%;
          font-size: 2.2rem;
          color: var(--wolseleyRed);
          width: 36rem;
          gap: 1rem;
          .province {
            text-align: right;
            font-weight: 700;
          }
          .col {
            display: flex;
          }
        }

        @media (max-width: 768px) {
          .wrapper {
            padding: 4rem 2rem;
          }
          .title {
            font-size: 1.8rem;
            padding-bottom: 1.5rem;
            line-height: 3.1rem;
          }
          .contact {
            font-size: 1.3rem;
            left: unset;
            width: 100% !important;
            align-items: center;
            .province {
              text-align: center;
              font-weight: 700;
            }
            .col {
              flex-direction: column;
            }
          }
        }
      `}</style>
    </>
  )
}
