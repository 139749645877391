import dynamic from 'next/dynamic'
import { Tooltip } from 'react-tooltip'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getMenuIcon from '@/utils/get-menu-icon'
import useDebouncedCallback from '@/hooks/use-debounced-callback'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import { useSideNav } from '@/contexts/side-nav'
import MenuItemL2 from './menu-item-l2'
import classNames from 'classnames'

const MenuItemL1 = ({ item }) => {
  const { t } = useTranslation('sideNav')
  const { sideNavExpanded, openL1, renderMenuPopup, activeNavItem } = useSideNav()
  const { deviceTypeBasedOnWidth, isTouchDevice } = useDeviceDetect()
  const toggleMenuPopup = useDebouncedCallback(toggleMenuPopupRaw, 250, [toggleMenuPopupRaw])
  const setHoveredTrue = useDebouncedCallback(setHoveredTrueRaw, 250, [setHoveredTrueRaw])
  const setHoveredFalse = useDebouncedCallback(setHoveredFalseRaw, 250, [setHoveredFalseRaw])

  const itemL1Header = useRef(null)

  const [hovered, setHovered] = useState(false)

  const { id, title, icon, l2 = [] } = item.l1
  const subMenuOpen = openL1?.item?.id === id

  function toggleMenuPopupRaw() {
    setHoveredFalse()
    if (!sideNavExpanded) {
      renderMenuPopup(item.l1, deviceTypeBasedOnWidth.mobile ? document.body : itemL1Header.current, deviceTypeBasedOnWidth.mobile ? itemL1Header.current : null)
    }
  }

  function setHoveredTrueRaw() {
    !isTouchDevice && !sideNavExpanded && setHovered(!subMenuOpen)
  }

  function setHoveredFalseRaw() {
    setHovered(false)
  }

  return (
    <>
      <div className={classNames('menu-item-l1 flex flexDirectionColumn', { expanded: sideNavExpanded })} key={id}>
        <div
          ref={itemL1Header}
          id={`l1_${id}`}
          className={classNames('header flex flexDirectionRow alignCenter', {
            'cursor-pointer': !sideNavExpanded,
            active: !sideNavExpanded && activeNavItem?.l1 === id,
            'menu-open': subMenuOpen,
          })}
          onMouseEnter={setHoveredTrue}
          onMouseLeave={setHoveredFalse}
          onClick={toggleMenuPopup}
        >
          {!!icon && <span className='icon flex alignCenter justifyCenter alignSelfStart'>{getMenuIcon(icon)}</span>}
          {(sideNavExpanded || deviceTypeBasedOnWidth.mobile) && <span className='title'>{t(title)}</span>}
        </div>
        {hovered && !subMenuOpen && (
          <Tooltip
            anchorSelect={`#l1_${id}`}
            opacity={1}
            noArrow={true}
            delayShow={0}
            place='right'
            style={{
              maxWidth: '30rem',
              fontSize: '1.4rem',
              padding: '0.5rem 1rem',
              background: '#3B3B3B',
              boxShadow: '0 1.5rem 1.5rem -0.6rem #00000040',
              textTransform: 'none',
              fontWeight: '400',
              lineHeight: '1.61rem',
              borderRadius: '0.7rem',
              textAlign: 'left',
            }}
            isOpen={hovered}
          >
            {t(title)}
          </Tooltip>
        )}
        {deviceTypeBasedOnWidth.desktop && sideNavExpanded && l2.length && (
          <div className='flex flexDirectionColumn'>
            {l2?.map((subMenuItem) => (
              <MenuItemL2 key={subMenuItem.id} item={subMenuItem} />
            ))}
          </div>
        )}
      </div>

      <style jsx>{`
        .menu-item-l1 {
          position: relative;

          .header {
            padding: 0.6rem;
            border-radius: 0.7rem;
            color: var(--wolseleyDarkGrey);

            .icon {
              height: 2rem;
              width: 2rem;
              flex-shrink: 0;
            }

            &.menu-open {
              background: #0000001c;
            }

            &.active {
              background: var(--wolseleyDarkGrey);
              color: #ffffff;

              .title {
                color: inherit;
              }
            }

            &:not(.active):not(.menu-open) {
              &:hover {
                background: #00000012;
              }

              &:active {
                background: #0000001c;
              }
            }
          }

          &.expanded {
            &:not(:last-child) {
              padding-bottom: 1rem;
              margin-bottom: 1rem;
              border-bottom: 1px solid #dddddd;
            }

            .header {
              padding: 0.5rem 0;
              color: var(--wolseleyDarkGrey);

              .icon {
                height: 2rem;
                width: 2rem;
                flex-shrink: 0;
              }

              .title {
                margin-left: 0.7rem;
                font-size: 1.6rem;
                font-weight: 700;
                line-height: 1.84rem;
              }

              &:not(.active):not(.menu-open) {
                &:hover,
                &:active {
                  background: inherit;
                }
              }
            }
          }
        }

        @media (max-width: 767px) {
          .menu-item-l1 {
            .header {
              padding: 0.75rem 0.8rem;
              gap: 0.2rem;
              color: var(--wolseleyGrey);

              .title {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.61rem;
                text-wrap: nowrap;
              }
            }
          }
        }

        @media (hover: none), (pointer: coarse) {
          .menu-item-l1 {
            .header:not(.active):not(.menu-open) {
              &:hover {
                background: inherit;
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(MenuItemL1), { ssr: false })
