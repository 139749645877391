import client from '../client'

export async function saveRegion({ regionId }) {
  const params = new URLSearchParams({ regionId })
  return client(
    `${process.env.NEXT_PUBLIC_DOMAIN}/Location/SetCurrentRegionForGuest?${params.toString()}`,
    {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    },
  )
}
