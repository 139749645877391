import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'

const LiveChat = () => {
  const { locale } = useRouter()

  const [isWindowObjectLoaded, setIsWindowObjectLoaded] = useState(false)

  useEffect(() => {
    window.liveChat = {
      language: locale == 'en' ? 1 : 2,
      url: process?.env?.NODE_ENV !== 'development' ? 'wolseleyexpress.com' : 'localhost',
      translations: {
        livechatsupport: locale == 'en' ? 'Live Chat Support' : 'Aide en discussion directe',
        loading: locale == 'en' ? 'Loading...' : 'Chargement...',
        contactus: locale == 'en' ? 'Contact Us' : 'Contactez-nous',
        notethatfor:
          locale == 'en'
            ? "For Quotations or Product Technical Support please contact a branch near you. Texting now available at participating branches, click <a style='text-decoration: underline; color:blue;' href='https://www.wolseleyexpress.com/en/BranchLocations/Show'>here</a> for Branch Locations and contact info."
            : "Pour les soumissions ou le soutien technique des produits, veuillez communiquer avec une succursale près de chez vous. Il est maintenant possible d’utiliser la messagerie texte pour les succursales participantes, cliquez <a style='text-decoration: underline; color:blue;' href='https://www.wolseleyexpress.com/fr/BranchLocations/Show'>ici</a> pour les coordonnées et les emplacements des succursales",
        liveChatName: locale == 'en' ? 'Chat_EnglighTest' : 'Chat_FrenchTest',
        liveChatButtonId: locale == 'en' ? '5736g0000009hsH' : '5736g0000009pDS',
      },
    }
    setIsWindowObjectLoaded(true)
  }, [])

  return isWindowObjectLoaded && <Script src='/medallia/live-chat.js' type='module' />
}

export default dynamic(() => Promise.resolve(LiveChat), {
  ssr: false,
})
