import { useReducer, useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import FormField from './../../../components/inputs/form-field'
import Button from './../../../components/button/button'
import TextButton from '../../../components/button/text-button'
import useValidation from '../../../hooks/use-validation'
import Link from 'next/link'
import Eye from '../../../components/svg/eye'
import EyeSlash from '../../../components/svg/eye-slash'
import { useTranslation } from 'react-i18next'
import { logInFailureEvent } from '../../../utils/ga-events/gtm-events-common'
import { validateEmail } from '../../../utils/validate-email'
import { useFeatureToggle } from '../../../contexts/feature-toggle'
import { getSSODomainsKey, getSSoDomains } from '../../../api-client/sso/get-sso-domains'
import { logInClickInteractionEvent } from '../../../utils/ga-events/gtm-events-common'
import Router from 'next/router'
import Loader from '../../../components/loader'

const mainSite = process.env.NEXT_PUBLIC_MAIN_SITE_DOMAIN
const isEmpty = (entry) => (entry.value === '' ? true : false)

const initialState = {
  email: { value: '' },
  password: { value: '' },
}

const FormFields = {
  Email: 'email',
  Password: 'password',
}

const reducer = (state, action) => ({
  ...state,
  [action.payload.field]: { value: action.payload.value },
})

export default function Login({
  onSubmit,
  disabled,
  onClose,
  handleSSOLogin,
  handleForgotPasswordModal,
  validationMessage,
  rememberMeCallBack,
}) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showPassword, setShowPassword] = useState(false)
  const [showErrors, { isFormValid, errors }] = useValidation(state, validate)
  const [formValidationError, setFormValidationError] = useState({})
  const [showServerValidationMessage, setShowServerValidationMessage] = useState(true)
  const { t } = useTranslation('header')
  const [{ featureToggles }] = useFeatureToggle()
  const [ssoDomains, setSsoDomains] = useState([])
  const [showSSO, setShowSSO] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [isLoading, setIsloading] = useState(false)

  useQuery({
    queryKey: [getSSODomainsKey],
    queryFn: () => getSSoDomains(),
    enabled: featureToggles.SSO_Enabled,
    onSuccess: (data) => {
      setSsoDomains(data || [])
    },
  })

  const extractEmailDomain = (email) => {
    const [, domain] = email.split('@')
    return domain
  }

  const isEmailDomainAllowed = (email) => {
    const domain = extractEmailDomain(email)

    let isAllowed = false

    ssoDomains.split(',').forEach((allowedDomain) => {
      if (allowedDomain === domain) {
        isAllowed = true
      }
    })

    return isAllowed
  }

  const handleChange = ({ target: { name, value } }) => {
    // Additional condition based on ssoFlag
    if (featureToggles?.SSO_Enabled && ssoDomains?.length > 0) {
      if (isEmailDomainAllowed(value)) {
        setShowSSO(true)
      } else {
        setShowSSO(false)
      }
    }

    // Dispatch the action
    dispatch({ payload: { field: name, value } })
  }
  const handleRememberMeClick = (event) => {
    if (event.currentTarget.checked) {
      setRememberMe(true)
      rememberMeCallBack(true)
      logInClickInteractionEvent('Remember Me', 'checked')
    } else {
      rememberMeCallBack(false)
      setRememberMe(false)
      logInClickInteractionEvent('Remember Me', 'unchecked')
    }
  }

  const handleLoginClick = (e) => {
    e.preventDefault()

    if (isFormValid && validateEmail(state.email.value)) {
      setIsloading(true)
      setFormValidationError('')
      setShowServerValidationMessage(true)
      onSubmit({ email: state.email.value, password: state.password.value, rememberMe: rememberMe })
    } else {
      showErrors()
      setShowServerValidationMessage(false)
      const error = {}
      const errors = []
      if (!state.email.value.trim()) {
        error.email = t('account.login.fields.email.required')
        errors.push(error.email)
      } else {
        if (!validateEmail(state.email.value)) {
          error.email = t('account.login.fields.email.wrongemail')
          errors.push(error.email)
        }
      }
      if (!state.password.value.trim()) {
        error.password = t('account.login.fields.password.required')
        errors.push(error.password)
      }
      if (errors.length > 0) {
        logInFailureEvent(errors)
      }
      setFormValidationError(error)
    }
  }
  useEffect(() => {
    const escapeListener = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if(document.activeElement.id === 'sigininForgotPassword'){
          handleForgotPasswordModal()
          logInClickInteractionEvent('forgot password')
        }
        else if(document.activeElement.id === 'sigininRegister'){
          logInClickInteractionEvent('register')
          Router.push(`${mainSite}UserRegistration/AccountPage`)
        }
        else if(document.activeElement.id === 'sigininCreditApplication'){
          logInClickInteractionEvent('credit application')
          Router.push(`${mainSite}UserRegistration/AccountPage`)
        }
        else{
          handleLoginClick(event)
        }
      }
      else if (event.key === ' ') {
        if(document.activeElement.id === 'siginInTabRememberMe'){
          event.preventDefault()
          if(rememberMe)
          {
            rememberMeCallBack(false)
            setRememberMe(false)
          }
          else{
            rememberMeCallBack(false)
            setRememberMe(true)
          }
        }
      }
    }
    document.addEventListener('keydown', escapeListener)
    return () => document.removeEventListener('keydown', escapeListener)
  }, [handleLoginClick])

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <form className='wrapper' onSubmit={handleLoginClick}>
      <div className='flex alignCenter justifyBetween pb-dot3' tabIndex="-1">
        <div className='title'>{t('wca.signin')}</div>
        <div>
          <button tabIndex="-1" className='close' onClick={() => onClose()} aria-label='close'>
            x
          </button>
        </div>
      </div>
      {showServerValidationMessage && validationMessage && (
        <div className='message-error warning-message'>
          <div className='validation-summary-errors'>
            <ul>{<li> {validationMessage}</li>}</ul>
          </div>
        </div>
      )}
      <div className='formFieldItem'>
        <label htmlFor='Email'>{t('Wca.Email')}:</label>
        <FormField
          placeholder={t('Wca.Email')}
          type='text'
          name={FormFields.Email}
          error={errors.email}
          value={state.email.value}
          onChange={handleChange}
          tabIndex="0"
          className='siginInTab'
        />
      </div>
      {formValidationError.email && (
        <span  tabIndex="-1" className='field-validation-error'>{formValidationError.email}</span>
      )}
      {!showSSO && (
        <div className='formFieldItem'>
          <label htmlFor='Email'>{t('Wca.Password')}:</label>
          <FormField
            placeholder={t('Wca.Password')}
            type={`${showPassword ? 'text' : 'password'}`}
            name={FormFields.Password}
            tabIndex="0"
            className='siginInTab'
            error={errors.password}
            value={state.password.value}
            onChange={handleChange}
            adornment={
              showPassword ? (
                <Eye width='24' onClick={togglePassword} />
              ) : (
                <EyeSlash width='24' onClick={togglePassword} />
              )
            }
          />
        </div>
      )}
      {formValidationError.password && (
        <span  tabIndex="-1" className='field-validation-error'>{formValidationError.password}</span>
      )}

      <div className='formFieldItem flex justifyBetween alignCenter'  tabIndex="-1" id='rememberMeTabIndex'>
        <div id='siginInTabRememberMe' className='flex siginInTab' tabIndex="0">
          <input
            className='checkbox'
            type='checkbox'
            name='rememberMe'
            id='rememberMe'
            onChange={handleRememberMeClick}
            value={rememberMe}
            checked={rememberMe}
            tabIndex="-1"
          />
          <label tabIndex="-1" htmlFor='rememberMe'>{t('Wca.RememberMe')}</label>
        </div>
        {!showSSO && (
          <div id='sigininForgotPassword' className='siginInTab' tabIndex="0">
            <TextButton
              tabIndex="-1"
              className='btnForgotPassword'
              onClick={() => {
                handleForgotPasswordModal()
                logInClickInteractionEvent('forgot password')
              }}
            >
              {t('Wca.ForgotPassword')}
            </TextButton>
          </div>
        )}
      </div>
      {isLoading && <Loader width='20' height='20' />}
      {!showSSO && (
        <div className='formFieldItem flex siginInTab' tabIndex="0">
          <Button type='submit' disabled={disabled} className='login ' tabIndex="-1">
            {t('wca.signin')}
          </Button>
        </div>
      )}
      {/* Hidden the SSO login button by commenting out; enabling it is dependent on the feature toggle. */}
      {featureToggles?.SSO_Enabled && ssoDomains?.length > 0 && showSSO && (
        <div className='formFieldItem flex siginInTab' tabIndex="-1">
          <Button className='btnForgotPassword siginInTab' tabIndex="0" onClick={handleSSOLogin}>
            {t('wca.ssologin')}
          </Button>
        </div>
      )}

      <div className='separator'></div>

      <div className='flex mbtm-20'>
        <div className='title'>{t('Wca.NewToWolseleyExpress')}</div>
      </div>

      <div className='flex alignCenter justifyBetween mbtm-20'>
        <div className='f13 pr1'>{t('wca.ihavean')}</div>
        <div className='txt'>
          <Link href={`${mainSite}UserRegistration/Registration`}>
            <a
              tabIndex="0"
              id='sigininRegister'
              className='btn-primary bold'
              onClick={(event) => {
                event.preventDefault()
                logInClickInteractionEvent('register')
                Router.push(`${mainSite}UserRegistration/Registration`)
              }}
            >
              {t('wca.register')}
            </a>
          </Link>
        </div>
      </div>
      <div className='flex alignCenter justifyBetween'>
        <div className='txt'>{t('wca.iamanew')}</div>
        <div>
          <Link href={`${mainSite}UserRegistration/AccountPage`} passHref>
            <a
              tabIndex='0'
              id='sigininCreditApplication'
              className='btn-primary bold'
              onClick={(event) => {
                event.preventDefault()
                logInClickInteractionEvent('credit application')
                Router.push(`${mainSite}UserRegistration/AccountPage`)
              }}
            >
              {t('Wca.CreditApplication')}
            </a>
          </Link>
        </div>
      </div>

      <style jsx>{`
        *:focus {
          outline: 2px solid blue; 
        }
        .siginInTab:focus {
          outline: 1px dashed  var(--wolseleyRed) !important;
          outline-offset: 2px;
        }  
        
        .wrapper {
          position: relative;
          padding: 1rem 0.6rem;
        }
        .bold {
          font-weight: bold;
        }
        .f13 {
          font-size: 13px;
        }
        .pr1 {
          padding-right: 1px;
        }

        .close {
          font-size: 2.4rem;
          // font-weight: 400;
          background: none;
          color: grey;
        }

        .pb-2 {
          padding-bottom: 2rem;
        }
        .pb-dot3 {
          padding-bottom: 0.3rem;
        }

        .mbtm-20 {
          margin-bottom: 2rem;
        }

        .title {
          font-size: 1.6rem;
          font-weight: 600;
          color: #000;
        }

        .signUpInstead {
          padding: 0 0 2.3rem;
          line-height: 2.1rem;
          font-size: 1.6rem;

          button {
            color: var(--primary-color);
          }
        }

        .signInErrorText {
          padding: 2.5rem 0 0;
          color: var(--alert-color);
          line-height: 2.1rem;
          font-size: 1.6rem;
        }

        .formFieldItem {
          padding-bottom: 1rem;

          label {
            font-weight: 600;
            font-size: 1.3rem;
            padding: 1rem 0;
            display: block;
          }

          .checkbox {
            -webkit-appearance: auto;
            cursor: unset;
            margin-right: 0.8rem;
          }
        }

        :global(.btnForgotPassword) {
          font-weight: 600;
          font-size: 1.3rem !important;
        }

        :global(button.login) {
          width: 100%;
        }

        .separator {
          border-top: 1px solid #8b8787;
          padding: 2rem 0 0;
          margin-top: 1rem;
        }

        .txt {
          font-size: 1.3rem;
        }

        .resetPassword {
          text-align: center;
          padding: 2.5rem 0 1.4rem;
          line-height: 1.9rem;
          font-size: 1.4rem;

          button {
            color: var(--primary-color);
          }
        }

        @media (max-width: 786px) {
          .wrapper {
            width: 100%;
            padding: 1.6rem 2rem 4.1rem;
          }

          .title {
            padding: 0 0 0.5rem;
            line-height: 2.4rem;
            font-size: 1.8rem;
          }
        }
        .field-validation-error {
          display: block;
          text-align: center;
          font-size: 13px;
          color: #cd2e31 !important;
          margin-top: 5px;
        }
        .warning-message {
          color: #8a6d3b;
          background-color: #fcf8e3;
          border-color: #faebcc;
          padding: 10px;
          margin-bottom: 5px;
          border: 1px solid transparent;
          text-align: left;
          border-radius: 6px;
        }
        .warning-message .validation-summary-errors {
          margin: 0px;
          padding: 0px;
        }

        .warning-message .validation-summary-errors ul {
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 56 50" version="1.1" fill="%23fcac0c" fillRule="evenodd" alt="warning icon"><path d="M 25.303 2.202 C 21.579 6.934, 1 44.421, 1 46.473 C 1 49, 1 49, 28 49 C 55 49, 55 49, 54.947 46.250 C 54.915 44.617, 49.933 34.970, 42.682 22.500 C 30.395 1.373, 28.082 -1.329, 25.303 2.202 M 26.348 24.587 C 26.701 28.759, 27.323 32.697, 27.731 33.337 C 28.565 34.645, 29.812 27.777, 29.926 21.250 C 29.990 17.547, 29.724 17, 27.853 17 C 25.790 17, 25.731 17.295, 26.348 24.587 M 26.336 37.567 C 25.502 39.739, 27.618 41.782, 29.133 40.267 C 30.413 38.987, 29.620 36, 28 36 C 27.415 36, 26.667 36.705, 26.336 37.567" stroke="none"></path></svg>');
          background-repeat: no-repeat;
          background-position: center;
          width: 30px !important;
          height: 30px !important;
          display: inline-block;
        }

        .warning-message .validation-summary-errors ul li {
          margin: 0 0 0 40px;
          width: 232px !important;
          display: inline-block;
        }
        .message-error {
          display: block;
          text-align: left;
          font-size: 13px;
          color: #cd2e31 !important;
          margin-top: 5px;
        }
      `}</style>
    </form>
  )
}

function validate(state) {
  const errors = {}
  let isValid = true

  for (const field of Object.keys(state)) {
    if (isEmpty(state[field])) {
      errors[field] = true
      isValid = false
    }
  }

  return { isValid, errors }
}
