export default function getPageInfo() {
  const urlPath = window.location.pathname

  const pageMap = [
    { pattern: /\/List\//, page_name: 'My List' },
    { pattern: /\/DraftOrder\/MyDraftOrder/, page_name: 'Draft Order' },
    { pattern: /FAQ/i, page_name: 'FAQ', page_type: 'Support' },
    { pattern: /ManagePayment\/Preferences/i, page_name: 'Payment Preferences' },
    { pattern: /Quotation\/QuoteList/i, page_name: 'Quotation', page_type: 'My Business' },
    { pattern: /cart/i, page_name: 'Cart', page_type: 'Checkout' },
    { pattern: /promotions/i, page_name: 'All Promotions', page_type: 'Promotions' },
    { pattern: /OrderHistory\/MyOrderHistory/, page_name: 'Order History' },
    {
      pattern: /creditpro\/\?wol=promotions-banner/i,
      page_name: 'NonTransactional.CreditPro',
      page_type: 'Promotions',
    },
    { pattern: /(en|fr)(\/$|$)/i, page_name: 'Home', page_type: 'Wolseley Express' },
    { pattern: /MySettings/i, page_name: 'Settings', page_type: 'My Wolseley' },
    { pattern: /MyWolseley\/Upload/, page_name: 'Upload' },
    { pattern: /AccountManagement\//, page_name: 'Account Management' },
    {
      pattern: /Quotation\/AddCustomerQuote/,
      page_name: 'Quotation.Add',
      page_type: 'My Business',
    },
    {
      pattern: /Quotation\/EditCustomerQuote/,
      page_name: 'Quotation.Edit',
      page_type: 'My Business',
    },
    { pattern: /MyWolseley\/AccountSummaryInfo/, page_name: 'Account Summary' },
    { pattern: /OrderHistory\/OrderDetails/, page_name: 'Order History.Order Details' },
    { pattern: /DraftOrder\/DraftDetails/, page_name: 'Draft Order.Draft Details' },
    { pattern: /WebQuote\/GetAllWebQuote/, page_name: 'Web Quotes' },
    { pattern: /WebQuote\/GetWebQuote/, page_name: 'Web Quotes.Quote Details' },
    { pattern: /BranchLocations\/Show/, page_name: 'Branch Locations', page_type: 'Support' },
    { pattern: /help-page/, page_name: 'Help', page_type: 'Support' },
    { pattern: /ContactUs\/GetContactUsPage/, page_name: 'Contact Us', page_type: 'Support' },
    {
      pattern: /termsandconditions/,
      page_name: 'Terms and Conditions',
      page_type: 'Terms and Conditions',
    },
    { pattern: /privacy-policy/, page_name: 'Privacy Policy', page_type: 'Privacy Policy' },
    {
      pattern: /TermsOfUse\/GetTermsOfUsePage\//,
      page_name: 'Terms of Use',
      page_type: 'Terms of Use',
    },
    { pattern: /wolseley-knowledge-hub/, page_name: 'Knowledge Hub', page_type: 'Services' },
    { pattern: /ServicePage/, page_name: 'Services', page_type: 'Services' },
    { pattern: /how-to/, page_name: 'How-to-use Guides', page_type: 'Services' },
    { pattern: /SupportPage\/GetSupportPage\//, page_name: 'Support', page_type: 'Support' },
    {
      pattern: /InternalCustomerDraft\/GetInternalCustomerDraftPage/,
      page_name: 'Customer Draft',
      page_type: 'My Wolseley',
    },
    {
      pattern: /(product|produit)\//i,
      page_name: urlPath.substring(urlPath.lastIndexOf('/') + 1),
      page_type: 'Product Details',
    },
    { pattern: /pro/, page_name: 'Pro', page_type: 'Promotions' },
    { pattern: /Reports\//, page_name: 'Reports', page_type: 'My Wolseley' },
    {
      pattern: /UserRegistration\/AccountPage/,
      page_name: 'UserRegistration',
      page_type: 'Wolseley',
    },
  ]

  const matchedPage = pageMap.find(({ pattern }) => pattern.test(urlPath))

  if (matchedPage) {
    return { page_name: matchedPage.page_name, page_type: matchedPage.page_type }
  }

  return { page_name: urlPath.replace(/\//gi, '.'), page_type: 'Wolseley' }
}
