import React, { createContext, useContext, useMemo, useState } from 'react'
import SlideInModal from '@/components/modal/slideInModal'

const SlideInDrawerContext = createContext()

const initDrawer = {
  title: '',
  content: null,
  props: {},
  isOpen: false,
}

export const SlideInDrawerProvider = ({ children }) => {
  const [drawer, setDrawer] = useState({})

  const openDrawer = (title, content, props = {}) => {
    setDrawer({
      title,
      content,
      props,
      isOpen: true,
    })
  }

  const closeDrawer = (callBackFunc = null) => {
    setDrawer(initDrawer)

    // Callback function is called after a 0.3s delay so as to the animation to complete
    if (callBackFunc instanceof Function) {
      setTimeout(callBackFunc, 300)
    }

    if (typeof props?.closeCallBack === 'function') {
      props.closeCallBack()
    }
  }

  const contextValue = useMemo(() => {
    return { openDrawer, closeDrawer }
  }, [openDrawer, closeDrawer])

  const { title, content, props, isOpen } = drawer

  return (
    <SlideInDrawerContext.Provider value={contextValue}>
      {children}
      <SlideInModal onClose={closeDrawer} closeOnClickOutside={false} headerTitle={title} open={isOpen} {...props}>
        {isOpen && content}
      </SlideInModal>
    </SlideInDrawerContext.Provider>
  )
}

export const useSlideInDrawer = () => {
  const context = useContext(SlideInDrawerContext)

  if (context === undefined) {
    throw new Error('useSlideInDrawer must be used within a SlideInDrawerProvider')
  }

  return context
}
