import { CartItemSourceTypes } from '@/constants'
import client from '../client'

export async function addProductToCart({ productId, orderedQuantity, profileId, deviceType, cartSource = CartItemSourceTypes.ProductDetail }) {
  const params = new URLSearchParams({ productId, orderedQuantity, profileId, deviceType,cartSource })
  return client(`${process.env.NEXT_PUBLIC_DOMAIN}/ShoppingCart/AddToCart?${params.toString()}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  })
}
