const IconBreadcrumbArrow = (props) => (
  <svg
    width='4'
    height='8'
    viewBox='0 0 4 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.103998 0.832818C-0.0404625 0.679866 -0.0335784 0.438765 0.119374 0.294305C0.272326 0.149845 0.513426 0.156729 0.657886 0.309682L3.896 3.73815C4.03466 3.88496 4.03467 4.11445 3.89602 4.26126L0.657907 7.69028C0.51346 7.84325 0.27236 7.85015 0.119396 7.7057C-0.0335674 7.56126 -0.0404711 7.32016 0.103977 7.16719L3.09509 3.99974L0.103998 0.832818Z'
      fill='#656565'
    />
  </svg>
)
export default IconBreadcrumbArrow
