import { memo } from 'react'
import { zIndex } from '../../constants'
import LoaderSvg from '../svg/loader'

export default memo(function Loader({ fullPage, ...restProps }) {
  return fullPage ? (
    <div className='loaderBackdrop'>
      <div className='aligner'>
        <LoaderSvg {...restProps} />
      </div>

      <style jsx>{`
        .loaderBackdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 120vh;
          padding: 0 0 20vh;
          background: rgba(0, 0, 0, 0.45);
          cursor: progress;

          .aligner {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8rem;
            height: 8rem;
          }

          :global(svg) {
            width: 100%;
            height: 100%;
            animation: rotate 1s infinite;

            @keyframes rotate {
              from {
                transform: rotate(0);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      `}</style>

      <style jsx>{`
        .loaderBackdrop {
          z-index: ${zIndex.zMax};
        }
      `}</style>
    </div>
  ) : (
    <>
      <LoaderSvg {...restProps} className='loaderSvg' />
      <style jsx>{`
        :global(.loaderSvg) {
          animation: rotate 1s infinite;

          @keyframes rotate {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      `}</style>
    </>
  )
})
