export const Lists = (props) => {
  return (
    <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.26758 1C2.61339 0.402199 3.25973 0 4.00001 0H8.50001C10.433 0 12 1.567 12 3.5V13.5C12 13.6844 11.8985 13.8538 11.7359 13.9408C11.5734 14.0278 11.3761 14.0183 11.2227 13.916L11 13.7676V3.5C11 2.11929 9.88073 1 8.50001 1H2.26758ZM2 2C0.89543 2 0 2.89543 0 4V15.5C0 15.6844 0.101492 15.8538 0.264071 15.9408C0.42665 16.0278 0.623922 16.0183 0.77735 15.916L5 13.1009L9.22265 15.916C9.37608 16.0183 9.57335 16.0278 9.73593 15.9408C9.89851 15.8538 10 15.6844 10 15.5V4C10 2.89543 9.10457 2 8 2H2ZM1 4C1 3.44772 1.44772 3 2 3H8C8.55228 3 9 3.44772 9 4V14.5657L5.27735 12.084C5.1094 11.972 4.8906 11.972 4.72265 12.084L1 14.5657V4Z'
        fill='#3B3B3B'
      />
    </svg>
  )
}
