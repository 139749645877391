import PopupModal from '@/components/popup-modal'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { SlideInDrawerProvider } from '@/contexts/slide-in-drawer-context'
import { useRouter } from 'next/router'

const PopupModalContext = createContext()
const initPopup = {
  title: '',
  content: null,
  props: {},
  isOpen: false,
}

export const PopupModalProvider = ({ children }) => {
  const router = useRouter()

  const [popup, setPopup] = useState(initPopup)

  const openPopup = (title, content, props = {}) => {
    setPopup({
      title,
      content,
      props,
      isOpen: true,
    })
  }

  const closePopup = () => {
    setPopup(initPopup)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      closePopup()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  const contextValue = useMemo(() => {
    return { openPopup, closePopup }
  }, [openPopup, closePopup])

  const { title, content, props, isOpen } = popup

  return (
    <SlideInDrawerProvider>
      <PopupModalContext.Provider value={contextValue}>
        {children}
        {isOpen &&
          createPortal(
            <PopupModal headerTitle={title} onClose={closePopup} {...props}>
              {content}
            </PopupModal>,
            document.body,
          )}
      </PopupModalContext.Provider>
    </SlideInDrawerProvider>
  )
}

export const usePopModal = () => {
  const context = useContext(PopupModalContext)

  if (context === undefined) {
    throw new Error('usePopupModal must be used within a PopupModalProvider')
  }

  return context
}
