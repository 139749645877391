import getPageInfo from '../get-page-info'

export const navigationEvent = (elementName, elementLocation) => {
  if (typeof window !== 'undefined') {
    let pageInfo = getPageInfo()
    let event = {
      event: 'navigation',
      page_name: pageInfo.page_name,
      page_type: pageInfo.page_type,
      nav_location: elementLocation,
      click_text: elementName,
    }
    datalayerPush(event)
  }
}

export const logOutEvent = () => {
  let event = {
    event: 'logout',
    gtm_tag_name: 'GA4 - Logout',
    interaction_type: 'Logout',
  }
  datalayerPush(event)
}

export const logInCompleteEvent = () => {
  let event = {
    event: 'login_complete',
    gtm_tag_name: 'GA4 - Login Complete',
  }
  datalayerPush(event)
}

export const logInFailureEvent = (error_message) => {
  let item = {
    event: 'login_failure',
    gtm_tag_name: 'GA4 - Login Failure',
    failure_reason: error_message,
  }
  datalayerPush(item)
}

export const logInClickInteractionEvent = (click_text, checkbox_status) => {
  if (typeof window !== 'undefined') {
    let pageInfo = getPageInfo()
    let item = {
      event: 'click_interaction',
      page_name: pageInfo.page_name,
      page_type: pageInfo.page_type,
      click_text: click_text,
    }
    if (checkbox_status != undefined) {
      item.checkbox_status = checkbox_status
    }
    datalayerPush(item)
  }
}

export const logInFormSubmissionEvent = (form_status, form_error) => {
  if (typeof window !== 'undefined') {
    let pageInfo = getPageInfo()
    let item = {
      event: 'form_submission',
      page_name: pageInfo.page_name,
      page_type: pageInfo.page_type,
      form_name: 'forgot password',
      form_status: form_status,
    }
    if (form_error != undefined) {
      item.form_error = form_error
    }
    datalayerPush(item)
  }
}

export const AVAILABILITY_STATUSES = [
  {
    status_id: 1,
    status_text: 'Available at your selected store location', //Wca.StockAvailableAtYourBranch
  },
  {
    status_id: 2,
    status_text: 'In stock at other location(s)', //Wca.InStockAtOtherLocation
  },
  {
    status_id: 3,
    status_text: "This item will be ordered in for you. We'll be in touch with availability", //Wca.BackorderToolTip
  },
]

export const checkAvailabilityEvent = (productData, availability, originPage, pageName) => {
  var data = {
    event: 'check_availability',
    page_name: pageName? pageName : productData.sku,
    page_type: originPage, //'Product Details',
    click_text: 'Check Availability',
    product_name: productData.shortDescription,
  }
  if (typeof availability !== 'undefined') {
    data.stock_status = AVAILABILITY_STATUSES.find(
      (status) => status.status_id === availability,
    ).status_text
  }
  datalayerPush(data)
}

export const getItemCategory = (categoryManufacturerInfo, itemDetails, productId) => {
  if (categoryManufacturerInfo) {
    if (categoryManufacturerInfo.Categories) {
      let categoryBreadcrumbs = categoryManufacturerInfo.Categories[productId]
      let categories = categoryBreadcrumbs.split('/')
      categories.forEach((value, index) => {
        if (value === '' || value.length <= 0) {
          value = undefined
        }
        const key = index === 0 ? 'item_category' : `item_category${index + 1}`
        itemDetails[key] = value
      })
    }
  }

  return itemDetails
}

export const getCoveoItemCategoryAndManufacturer = (
  categoryManufacturerInfo,
  productDetails,
  productId,
) => {
  if (categoryManufacturerInfo) {
    if (categoryManufacturerInfo.Categories) {
      let categoryBreadcrumbs = categoryManufacturerInfo.Categories[productId]
      productDetails.category =
        categoryBreadcrumbs !== null &&
        categoryBreadcrumbs !== undefined &&
        categoryBreadcrumbs !== ''
          ? categoryBreadcrumbs
          : 'Uncategorized'
    }
    if (categoryManufacturerInfo.Manufacturers) {
      let productBrand = categoryManufacturerInfo.Manufacturers[productId]
      productDetails.brand =
        productBrand !== null && productBrand !== undefined && productBrand !== ''
          ? productBrand
          : 'Unbranded'
    }
  }
  return productDetails
}

export const getGA360ItemCategoryAndManufacturer = (
  categoryManufacturerInfo,
  productDetails,
  productId,
) => {
  if (categoryManufacturerInfo) {
    if (categoryManufacturerInfo.Categories) {
      let categoryBreadcrumbs = categoryManufacturerInfo.Categories[productId]
      if (
        categoryBreadcrumbs !== null &&
        categoryBreadcrumbs !== undefined &&
        categoryBreadcrumbs !== ''
      ) {
        productDetails.category = categoryBreadcrumbs
      }
    }
    if (categoryManufacturerInfo.Manufacturers) {
      let productBrand = categoryManufacturerInfo.Manufacturers[productId]
      productDetails.brand = productBrand
    }
  }
  return productDetails
}

export const converPrice = (items) => {
  items.forEach((item) => {
    if (item?.price != undefined) {
      let price = item.price.toLocaleString()
      if (price === '0.00' || price.includes('Appellez pour les prix')) {
        item.price = 'Call for pricing'
      }
    }
  })
  return items
}

export const convertGA360Price = (price) => {
  if (window.location.href.indexOf('/fr/') > -1) {
    price = '$' + price.replace('$', '').replace(',', '.').replace(/\s/g, '')
    price = price.split(',').join('').trim()
    return price
  } else {
    price = price.replace(',', '').replace(/\s/g, '')
    return price
  }
}

export const retrieveSelectedItemListTypeDataFromLocalStorage = () => {
  var itemListTypeDataString = localStorage.getItem('selectedItemListTypeData')

  if (itemListTypeDataString) {
    var itemListTypeData = JSON.parse(itemListTypeDataString)
    return itemListTypeData
  }
  return null
}

export const removeSelectedItemListTypeDataInLocalStorage = () => {
  if (localStorage.getItem('selectedItemListTypeData')) {
    localStorage.removeItem('selectedItemListTypeData')
  }
}

export const retrieveIndexFromLocalStorage = (sku) => {
  var SelectedRecoRailData = JSON.parse(localStorage.getItem('SelectedRecoRailData'))
  var IndexNumber
  if (SelectedRecoRailData && SelectedRecoRailData.length > 0) {
    for (let i = 0; i < SelectedRecoRailData.length; i++) {
      if (SelectedRecoRailData[i]['item_id'] == sku) {
        IndexNumber = i
        break
      }
    }
  }
  if (localStorage.getItem('SelectedRecoRailData')) {
    localStorage.removeItem('SelectedRecoRailData')
  }
  return IndexNumber
}

export const currencyFormatterWithoutSymbol = (value) => {
  const culture = extractLocaleFromPath() || 'en'

  const options = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  return typeof value === 'number' ? value.toLocaleString(culture, options) : value
}

const extractLocaleFromPath = () => {
  const path = window.location.pathname // Get the path part of the URL
  const parts = path.split('/') // Split the path into parts
  return parts[1] || 'en' // Assuming the locale is the second part of the path, defaulting to 'en'
}

export const datalayerPush = (data) => {
  // Ensure that the dataLayer array exists or create it
  window.dataLayer = window.dataLayer || []

  // Push the data to the dataLayer array
  window.dataLayer.push(data)
}

export const generateCategoryFromUrl = () => {
  let url = sessionStorage.getItem('_currenturl')
  if (!!!url) return ''

  let parts = []
  if (window.coveoEnvSettings.languageId != 2) {
    parts = url.split('/category/')
  } else {
    parts = url.split('/categorie/')
  }
  let categoryPart = parts[1].split('#')[0]
  let categoryParts = categoryPart.split('/')
  let firstPart = categoryParts[0]
  let lastPart = categoryParts[categoryParts.length - 1]

  firstPart = firstPart.replace(/-/g, ' ')
  lastPart = lastPart.replace(/-/g, ' ')
  if (firstPart == lastPart) {
    return firstPart
  }
  return firstPart + '.' + lastPart
}
