import { useCallback, useEffect, useRef } from 'react'

export default function useDebouncedCallback(callback, wait, deps = []) {
  const argsRef = useRef()
  const timeoutRef = useRef()

  const cleanup = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }

  useEffect(() => cleanup, [])

  return useCallback(
    (...args) => {
      argsRef.current = args
      cleanup()

      timeoutRef.current = setTimeout(() => {
        if (argsRef.current) {
          callback(...argsRef.current)
        }
      }, wait)
    },
    [...deps, wait],
  )
}
