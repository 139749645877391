import { getCategoryManufacturerPathInfo } from '../../api-client/gtm/get-category-manufacturer'
import { affiliation, currency } from './ga-constants'
import {
  AVAILABILITY_STATUSES,
  converPrice,
  convertGA360Price,
  datalayerPush,
  getCoveoItemCategoryAndManufacturer,
  getGA360ItemCategoryAndManufacturer,
  getItemCategory,
} from './gtm-events-common'
import {
  retrieveIndexFromLocalStorage,
  removeSelectedItemListTypeDataInLocalStorage,
  retrieveSelectedItemListTypeDataFromLocalStorage,
} from './gtm-events-common'

export const addToCartGAEvent = async (productData, price, quantity) => {
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productData.id)
  addToCartGA4(productData, price, quantity, categoryManufacturerInfo)
  addToCartCoveo(productData, price, quantity, categoryManufacturerInfo)
  addToCartGA360(productData, price, quantity)
}

export const recommendationProductClickGAEvents = async (productData, index) => {
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productData.id)
  recommendationRailselectItemGA4(productData, index)
  productClickedGA360(productData, categoryManufacturerInfo, index, 'PDP Recommendation Rail')
}

const addToCartGA4 = (productData, price, quantity, categoryManufacturerInfo) => {
  const productId = productData.id
  let masterData = {
    event: 'add_to_cart',
    gtm_tag_name: 'GA4 - Ecommerce - Add to Cart',
    ecommerce: {},
  }
  let items = []
  let itemDetails = {
    item_id: productData.sku,
    item_name: productData.shortDescription,
    price: price?.replace('$', '') || undefined,
    quantity: Number(quantity).toFixed(2),
    currency: currency,
    affiliation: affiliation,
    WolseleyChoice: productData.isWolseleyChoiceManufacturer,
    item_variant: '',
    item_list_name: 'Product Detail',
    index: 1,
  }
  itemDetails = getItemCategory(categoryManufacturerInfo, itemDetails, productId)
  if (categoryManufacturerInfo?.Manufacturers) {
    itemDetails.item_brand = categoryManufacturerInfo.Manufacturers[productId]
  }

  items.push(itemDetails)
  items = converPrice(items)
  masterData.ecommerce.items = items
  datalayerPush(masterData)
}

const addToCartCoveo = (productData, price, quantity, categoryManufacturerInfo) => {
  const productId = productData.id
  var masterData = {
    event: 'add_to_cart_coveo',
    gtm_tag_name: 'Coveo - Ecommerce - Add to Cart',
    item_list_name: 'Product Details',
    item_list_id: undefined,
    search_term: undefined,
    num_search_results: undefined,
    ecommerce: {},
  }
  let products = []
  let productDetails = {
    name: productData.shortDescription,
    id: productData.sku,
    price: price?.replace('$', '') || undefined,
    group: undefined,
    variant: '',
    quantity: Math.round(quantity),
    siteName: affiliation,
    WolseleyChoice: productData.isWolseleyChoiceManufacturer,
  }
  productDetails = getCoveoItemCategoryAndManufacturer(
    categoryManufacturerInfo,
    productDetails,
    productId,
  )
  products.push(productDetails)
  masterData.ecommerce.products = products
  datalayerPush(masterData)
}

const addToCartGA360 = (productData, price, quantity) => {
  let masterData = {
    event: 'addToCart',
    ecommerce: {},
  }
  masterData.ecommerce = {
    currencyCode: currency,
    add: {
      actionField: { list: 'Product Details' },
      products: [
        {
          name: `${productData.shortDescription.substring(0, 60).trim()}...`,
          id: productData.sku,
          price:
            price != null && price != 0 && price != ''
              ? convertGA360Price(price.toString())
              : undefined,
          brand: '',
          variant: '',
          quantity: quantity,
          WolseleyChoice: productData.isWolseleyChoiceManufacturer,
        },
      ],
    },
  }
  datalayerPush(masterData)
}

export const addToCartFromCrossSellBundleGAEvent = async (
  selectedProducts,
  title,
  isAuthenticated,
) => {
  var selectedProductids = selectedProducts.map((item) => {
    return item.id
  })
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(selectedProductids)
  let items = []
  let products = []
  let ga360Products = []
  let totalPrice = 0
  selectedProducts.forEach((product, index) => {
    if (product.checked) {
      totalPrice += parseFloat(product.price)
      let itemDetails = {
        item_id: product.sku,
        item_name: product.shortDesciption,
        price:
          product.price != null && product.price != 0 && product.price != ''
            ? parseFloat(product.price).toFixed(2)
            : undefined,
        quantity: product.quantity.toFixed(2),
        currency: currency,
        affiliation: affiliation,
        item_list_id: 'Cross-Sell Bundles',
        item_list_name: title,
        index: ++index,
      }

      itemDetails = getItemCategory(categoryManufacturerInfo, itemDetails, product.id)
      if (categoryManufacturerInfo?.Manufacturers) {
        itemDetails.item_brand = categoryManufacturerInfo.Manufacturers[product.id]
      }
      items.push(itemDetails)
      items = converPrice(items)

      let productDetails = {
        name:
          product.shortDesciption != '' &&
          product.shortDesciption != undefined &&
          product.shortDesciption != null
            ? product.shortDesciption
            : 'MissingName',
        id:
          product.sku != '' && product.sku != undefined && product.sku != null
            ? product.sku
            : 'MissingId',
        price:
          product.price != undefined && product.price != null && product.price != ''
            ? parseFloat(product.price).toFixed(2)
            : undefined,
        group: undefined,
        variant: undefined,
        quantity: Math.round(product.quantity),
        siteName: affiliation,
      }
      productDetails = getCoveoItemCategoryAndManufacturer(
        categoryManufacturerInfo,
        productDetails,
        product.id,
      )
      products.push(productDetails)

      let ga360ProductDetails = {
        name: product.shortDesciption,
        id: product.sku,
        price:
          product.price != null && product.price != 0 && product.price != ''
            ? convertGA360Price(parseFloat(product.price).toFixed(2).toString())
            : undefined,
        variant: '',
        quantity: product.quantity,
      }
      ga360ProductDetails = getGA360ItemCategoryAndManufacturer(
        categoryManufacturerInfo,
        ga360ProductDetails,
        product.id,
      )
      ga360Products.push(ga360ProductDetails)
    }
  })
  addToCartFromCrossSellBundleGA4(items, totalPrice, isAuthenticated)
  addToCartFromCrossSellBundleCoveo(products, totalPrice, isAuthenticated)
  addToCartFromCrossSellBundleGA360(ga360Products)
}

const addToCartFromCrossSellBundleGA4 = (items, totalPrice, isAuthenticated) => {
  let masterData = {
    event: 'add_to_cart',
    gtm_tag_name: 'GA4 - Ecommerce - Add to Cart Cross sell Bundle',
    ecommerce: {
      items: items,
    },
    totalPrice: isAuthenticated ? Number(totalPrice).toFixed(2) : undefined,
    number_of_items_added_to_cart: items?.length,
  }
  datalayerPush(masterData)
}

const addToCartFromCrossSellBundleCoveo = (products, totalPrice, isAuthenticated) => {
  let masterData = {
    event: 'add_to_cart_coveo',
    gtm_tag_name: 'Coveo - Ecommerce - Add to Cart Cross sell Bundle',
    item_list_name: 'Product Details - Cross Bundle',
    item_list_id: undefined,
    search_term: undefined,
    num_search_results: undefined,
    ecommerce: {
      cross_sell_items: products,
    },
    totalPrice: isAuthenticated ? Number(totalPrice).toFixed(2) : undefined,
    number_of_items_added_to_cart: products.length,
  }
  datalayerPush(masterData)
}

const addToCartFromCrossSellBundleGA360 = (products) => {
  let data = {
    masterData: {
      gtm_tag_name: 'GA Event - Add To Cart Cross sell Bundle',
      item_list_name: undefined,
      item_list_id: undefined,
    },
    event: 'addToCartFromCrossSellBundle',
    ecommerce: {
      currencyCode: currency,
      add: {
        actionField: { list: 'CrossSell Bundle' },
        products: products,
      },
    },
  }
  datalayerPush(data)
}

const productClickedGA360 = (
  productData,
  categoryManufacturerInfo,
  index,
  origin,
  isWolseleyChoice = undefined,
) => {
  let masterData = {
    event: 'productClick',
    ecommerce: {},
  }
  let recommendedProduct = []
  let productDetails = {
    name: `${productData.shortDesciption.substring(0, 60).trim()}...`,
    id: productData.sku,
    price:
      productData.price != null && productData.price != 0 && productData.price != ''
        ? '$' + convertGA360Price(parseFloat(productData.price).toFixed(2).toString())
        : undefined,
    variant: '',
    brand: '',
    position: index,
  }
  if (isWolseleyChoice != undefined) {
    productDetails.WolseleyChoice = isWolseleyChoice
  }
  getGA360ItemCategoryAndManufacturer(categoryManufacturerInfo, productDetails, productData.id)
  recommendedProduct.push(productDetails)
  masterData.ecommerce = {
    click: {
      actionField: { action: 'click', list: origin },
      products: recommendedProduct,
    },
  }
  datalayerPush(masterData)
}

const recommendationRailselectItemGA4 = (recommendedProduct, index) => {
  var data = {
    event: 'select_item',
    gtm_tag_name: 'GA4 - Ecommerce - Product Click PDP Recommended',
    ecommerce: {
      items: [
        {
          item_id: recommendedProduct.sku,
          item_name: recommendedProduct.shortDesciption,
          price:
            typeof recommendedProduct?.price === 'undefined'
              ? undefined
              : parseFloat(recommendedProduct.price.toFixed(2)),
          item_brand: undefined,
          currency: currency,
          quantity: undefined,
          item_variant: undefined,
          affiliation: affiliation,
          index: index,
        },
      ],
    },
  }
  datalayerPush(data)
}

export const recommendationProductPDPAddToCartGAEvent = async (productData, index) => {
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productData.id)
  recommendationProductPDPAddToCartGA4(productData, index, categoryManufacturerInfo)
  recommendationProductPDPAddToCartCoveo(productData, index)
  recommendationProductPDPAddToCartGA360(productData, index)
}

const recommendationProductPDPAddToCartGA4 = (productData, index, categoryManufacturerInfo) => {
  let masterData = {
    event: 'add_to_cart',
    ecommerce: {},
  }
  let items = []
  let itemDetails = {
    item_id: productData.sku,
    item_name: productData.shortDesciption,
    price:
      productData.price != undefined && productData.price != null
        ? parseFloat(productData.price)
            .toFixed(2)
            .toString()
            .replace('$', '')
            .replace(',', '.')
            .trim()
        : undefined,
    quantity: 1,
    currency: currency,
    affiliation: affiliation,
    index: index,
    item_list_id: 'Product Recommendations',
    item_list_name: 'Product Recommendation(s):',
  }
  itemDetails = getItemCategory(categoryManufacturerInfo, itemDetails, productData.id)
  if (categoryManufacturerInfo?.Manufacturers) {
    itemDetails.item_brand = categoryManufacturerInfo.Manufacturers[productData.id]
  }
  items.push(itemDetails)
  masterData.ecommerce.items = items
  datalayerPush(masterData)
}

const recommendationProductPDPAddToCartCoveo = (productData, index) => {
  let masterData = {
    event: 'add_to_cart_coveo',
    gtm_tag_name: 'Coveo - Ecommerce - Add to Cart',
    item_list_name: 'PDP Recommendation Rail',
    item_list_id: undefined,
    search_term: undefined,
    num_search_results: undefined,
    ecommerce: {},
  }
  let products = []
  let productDetails = {
    name:
      productData.shortDesciption != '' &&
      productData.shortDesciption != undefined &&
      productData.shortDesciption != null
        ? productData.shortDesciption
        : 'MissingName',
    id:
      productData.sku != '' && productData.sku != undefined && productData.sku != null
        ? productData.sku
        : 'MissingId',
    price:
      productData.price != undefined && productData.price != null
        ? parseFloat(productData.price)
            .toFixed(2)
            .toString()
            .replace('$', '')
            .replace(',', '.')
            .trim()
        : '0.00',
    brand: 'Unbranded',
    group: undefined,
    category: 'Uncategorized',
    variant: undefined,
    quantity: 1,
    siteName: affiliation,
    position: index,
  }
  products.push(productDetails)
  masterData.ecommerce.products = products
  datalayerPush(masterData)
}

const recommendationProductPDPAddToCartGA360 = (productData, index) => {
  const origin = 'PDP Recommendation Rail'
  let gaData = {
    masterData: {
      gtm_tag_name: 'GA Event - Add To Cart PDP Recommendation Rail',
      item_list_name: origin,
      item_list_id: undefined,
    },
    event: 'addToCartFromPDPRecoRail',
    ecommerce: {},
  }
  let producs = []
  let productDetails = {
    name: productData.shortDesciption.substring(0, 60).trim(this) + '...',
    id: productData.sku,
    price:
      productData.price != null && productData.price != 0 && productData.price != ''
        ? convertGA360Price(parseFloat(productData.price).toFixed(2).toString())
        : undefined,
    brand: '',
    variant: '',
    quantity: 1,
    position: index,
  }
  producs.push(productDetails)
  gaData.ecommerce = {
    currencyCode: currency,
    add: {
      actionField: { list: origin },
      products: producs,
    },
  }
  datalayerPush(gaData)
}

export const saveAsListGAEvents = async (productDetails, productId, price) => {
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productId)
  savedToListGA4(productDetails, categoryManufacturerInfo, productId, price)
}

const savedToListGA4 = (productDetails, categoryManufacturerInfo, productId, price) => {
  let items = []
  let itemDetails = {
    item_id: productDetails.sku,
    item_name: productDetails.shortDescription,
    price: price?.replace('$', '') || undefined,
    quantity: '1.00',
    currency: currency,
    discount: undefined,
    affiliation: affiliation,
    item_brand: undefined,
    item_variant: '',
    index: 1,
    WolseleyChoice: productDetails.isWolseleyChoiceManufacturer,
  }
  if (categoryManufacturerInfo?.Manufacturers) {
    itemDetails.item_brand = categoryManufacturerInfo.Manufacturers[productId]
  }
  itemDetails = getItemCategory(categoryManufacturerInfo, itemDetails, productId)
  items.push(itemDetails)
  var data = {
    event: 'add_to_wishlist',
    gtm_tag_name: 'GA4 - Ecommerce - Add to List',
    item_list_id: undefined,
    item_list_name: 'Product Details',
    list_type: undefined,
    num_search_results: undefined,
    search_term: undefined,
    ecommerce: {
      items: items,
    },
  }
  datalayerPush(data)
}

export const productDetailsViewEventsGA = async (
  productData,
  priceAndAvailability,
  isAuthenticated,
) => {
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productData.id)
  productDetailsViewEventGA4(productData, priceAndAvailability, categoryManufacturerInfo)
  productDetailsViewEventGACoveo(productData, priceAndAvailability, categoryManufacturerInfo)
  isAuthenticated && productDetailsProductViewGA360(productData, categoryManufacturerInfo)
}
const productDetailsViewEventGA4 = async (
  productData,
  priceAndAvailability,
  categoryManufacturerInfo,
) => {
  const items = []
  const item = {
    item_id: productData?.sku,
    item_name: productData?.shortDescription,
    price: getPrice(priceAndAvailability?.netPriceWcaFormatted),
    quantity: productData?.shippingMultiple?.toFixed(2),
    item_brand: productData?.manufacturer[0]?.name, // Use optional chaining here as well
    currency: 'CAD',
    affiliation: 'Wolseley Express',
    index: undefined,
  }

  getItemCategory(categoryManufacturerInfo, item, productData.id)

  if (typeof priceAndAvailability !== 'undefined' && priceAndAvailability.availability !== -1) {
    item.stock_status =
      AVAILABILITY_STATUSES.find((status) => status.status_id === priceAndAvailability.availability)
        ?.status_text || 'Unknown'
  }

  item.index = retrieveIndexFromLocalStorage(item.item_id)
  var itemListTypeData = retrieveSelectedItemListTypeDataFromLocalStorage()
  if (itemListTypeData) {
    item.item_list_name = itemListTypeData.itemListName
    item.item_list_id = itemListTypeData.itemListType
    if (typeof item.index === 'undefined') {
      item.index = itemListTypeData.index
    }
    removeSelectedItemListTypeDataInLocalStorage()
  }
  items.push(item)

  const masterData = {
    event: 'view_item',
    gtm_tag_name: 'GA4 - Ecommerce - Product Detail View',
    ecommerce: { items },
  }
  datalayerPush({ ecommerce: null })
  datalayerPush(masterData)
}

const productDetailsViewEventGACoveo = async (
  productData,
  priceAndAvailability,
  categoryManufacturerInfo,
) => {
  const products = []
  const product = {
    id: !productData?.sku ? 'MissingId' : productData?.sku,
    name: !productData?.shortDescription ? 'MissingName' : productData?.shortDescription,
    price: getPrice(priceAndAvailability?.netPriceWcaFormatted),
    brand: !productData?.manufacturer[0]?.name ? 'Unbranded' : productData?.manufacturer[0]?.name,
    group: undefined,
    siteName: 'WolseleyExpress',
    variant: undefined,
  }

  getCoveoItemCategoryAndManufacturer(categoryManufacturerInfo, product, productData.id)

  products.push(product)

  const masterData = {
    event: 'view_item_coveo',
    gtm_tag_name: 'Coveo - Ecommerce - Product Detail View',
    ecommerce: { products },
    item_list_id: undefined,
    item_list_name: undefined,
    num_search_results: undefined,
    search_term: undefined,
  }
  datalayerPush({ ecommerce: null })
  datalayerPush(masterData)
}
const getPrice = (netPriceWcaFormatted) => {
  if (!netPriceWcaFormatted || netPriceWcaFormatted === 'Call for pricing') {
    return undefined
  }
  return netPriceWcaFormatted.replace('$', '').trim()
}

const productDetailsProductViewGA360 = async (productData, categoryManufacturerInfo) => {
  const origin = 'Product Details'
  let masterData = {
    event: 'productView',
    ecommerce: {},
  }

  let productDetails = {
    name: `${productData?.shortDescription?.substring(0, 60).trim()}...`,
    id: productData?.sku,
    price:
      productData?.price != null && productData?.price != 0 && productData?.price != ''
        ? '$' + convertGA360Price(parseFloat(productData.price).toFixed(2).toString())
        : '',
    variant: '',
    brand: '',
  }

  getGA360ItemCategoryAndManufacturer(categoryManufacturerInfo, productDetails, productData.id)
  masterData.ecommerce = {
    detail: {
      actionField: { list: origin },
      products: [productDetails],
    },
  }
  datalayerPush(masterData)
}

export const productDetailsViewEventsWithCrossSellGA = async (
  productId,
  priceAndAvailability,
  crossSellBundle,
) => {
  productDetailsViewEventsWithCrossSellGA4AndCoveo(productId, priceAndAvailability, crossSellBundle)
}

const createItem = (value, categoryManufacturerInfo) => {
  return {
    item_id: value.sku,
    item_name: value.shortDesciption,
    price: value.price ? parseFloat(value.price).toFixed(2) : undefined,
    quantity: value.quantity.toFixed(2),
    item_brand: categoryManufacturerInfo?.Manufacturers[value.id],
    currency: 'CAD',
    item_variant: undefined,
    affiliation: 'Wolseley Express',
  }
}

const createProduct = (value, categoryManufacturerInfo) => {
  return {
    name: value.shortDesciption || 'MissingName',
    id: value.sku || 'MissingId',
    price: value.price ? parseFloat(value.price).toFixed(2) : undefined,
    brand: categoryManufacturerInfo.Manufacturers[value.id] || 'Unbranded',
    group: undefined,
    category: categoryManufacturerInfo?.Categories[value.id] || 'Uncategorized',
    variant: undefined,
    siteName: 'WolseleyExpress',
  }
}

const createProductUA = (value, categoryManufacturerInfo) => {
  return {
    name: value.shortDesciption,
    id: value.sku,
    price: value.price ? parseFloat(value.price).toFixed(2) : undefined,
    brand: categoryManufacturerInfo.Manufacturers[value.id],
    category: categoryManufacturerInfo?.Categories[value.id],
    variant: '',
    quantity: value.quantity,
  }
}

const createItemRecoRail = (value, categoryManufacturerInfo, index) => {
  return {
    item_id: value.sku,
    item_name: value.shortDesciption,
    affiliation: 'Wolseley Express',
    index: index,
    quantity: parseFloat(value.quantity).toFixed(2),
    currency: 'CAD',
    item_brand: categoryManufacturerInfo.Manufacturers[value.id],
    item_list_id: 'Product Recommendations',
  }
}

const productDetailsViewEventsWithCrossSellGA4AndCoveo = async (
  productId,
  priceAndAvailability,
  crossSellBundle,
) => {
  const items = []
  const crosssellItems = []
  const products = []
  const crosssellProducts = []
  const productsUA = []

  const selectedProductIds = crossSellBundle?.products?.map((item) => item.id) || []
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(selectedProductIds)

  crossSellBundle.products.forEach((value) => {
    const item = createItem(value, categoryManufacturerInfo)
    const product = createProduct(value, categoryManufacturerInfo)
    const productUA = createProductUA(value, categoryManufacturerInfo)

    productsUA.push(productUA)
    if (productId === value.id) {
      if (
        priceAndAvailability?.availability !== undefined &&
        priceAndAvailability.availability !== -1
      ) {
        item.stock_status =
          AVAILABILITY_STATUSES.find(
            (status) => status.status_id === priceAndAvailability.availability,
          )?.status_text || 'Unknown'
        item.price = getPrice(priceAndAvailability.netPriceWcaFormatted)
      }
      item.index = retrieveIndexFromLocalStorage(item.item_id)
      var itemListTypeData = retrieveSelectedItemListTypeDataFromLocalStorage()
      if (itemListTypeData) {
        item.item_list_name = itemListTypeData.itemListName
        item.item_list_id = itemListTypeData.itemListType
        if (typeof item.index === 'undefined') {
          item.index = itemListTypeData.index
        }
        removeSelectedItemListTypeDataInLocalStorage()
      }
      items.push(item)
      products.push(product)
    } else {
      crosssellItems.push(item)
      crosssellProducts.push(product)
    }
  })

  const masterDataCoveo = {
    event: 'view_item_coveo',
    gtm_tag_name: 'Coveo - Ecommerce - Product Detail View Cross sell Bundle',
    ecommerce: { products, cross_sell_products: crosssellProducts },
    item_list_id: undefined,
    item_list_name: undefined,
    num_search_results: undefined,
    search_term: undefined,
  }

  datalayerPush({ ecommerce: null })
  datalayerPush(masterDataCoveo)

  const masterData = {
    event: 'view_item',
    gtm_tag_name: 'GA4 - Ecommerce - Product Detail View Cross sell Bundle',
    ecommerce: { items, cross_sell_items: crosssellItems },
  }

  datalayerPush({ ecommerce: null })
  datalayerPush(masterData)

  const origin = 'Product Details with Cross Sell'
  let masterDataUA = {
    event: 'productView',
    ecommerce: {},
  }

  masterDataUA.ecommerce = {
    detail: {
      actionField: { list: origin },
      products: productsUA,
    },
  }
  datalayerPush({ ecommerce: null })
  datalayerPush(masterDataUA)
}

export const productRecommendationViewItemListInteractionGA4 = async (productRecommendations) => {
  let items = []
  const productIds = productRecommendations?.products?.map((item) => item.id) || []
  if (productIds && productIds.length > 0) {
    const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productIds)

    productRecommendations?.products?.forEach((value, index) => {
      const item = createItemRecoRail(value, categoryManufacturerInfo, index)

      item.item_list_name = productRecommendations?.title

      getItemCategory(categoryManufacturerInfo, item, value.id)

      items.push(item)
    })

    const masterData = {
      event: 'view_item_list',
      ecommerce: { items },
    }
    datalayerPush({ ecommerce: null })
    datalayerPush(masterData)
  }
}

export const wolseleychoiceClickGA4 = async (sku, name, productId, price, quantity) => {
  const categoryManufacturerInfo = await getCategoryManufacturerPathInfo(productId)
  let masterData = {
    event: 'Click',
    ecommerce: {},
  }
  let items = []
  let itemDetails = {
    item_id: sku,
    item_name: name,
    price: price?.replace('$', '') || undefined,
    quantity: Number(quantity).toFixed(2),
    currency: currency,
    affiliation: affiliation,
    item_list_name: 'PDP',
    index: 1,
    item_list_id: "Wolseley's Choice",
  }
  itemDetails = getItemCategory(categoryManufacturerInfo, itemDetails, productId)
  if (categoryManufacturerInfo?.Manufacturers) {
    itemDetails.item_brand = categoryManufacturerInfo.Manufacturers[productId]
  }

  items.push(itemDetails)
  items = converPrice(items)
  masterData.ecommerce.items = items
  datalayerPush(masterData)
}
