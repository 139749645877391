import client from '../client'

export const getTopicsKey = `${process.env.NEXT_PUBLIC_DOMAIN}/Topic/GetTopics`

export async function getTopics({
  systemNames = null,
  regionIdFromClient = '',
  languageIdFromClient = null,
}) {
  const params = new URLSearchParams({ systemNames, regionIdFromClient, languageIdFromClient })
  return client(`${getTopicsKey}?${params.toString()}`).then((res) => res)
}
