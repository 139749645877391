import * as Msal from 'msal';

let msalInstance;

if (typeof window !== 'undefined') {
  const msalConfig = {
    auth: {
      clientId: `${process.env.NEXT_PUBLIC_AZURE_AD_CLIENT_ID}`,
      authority: `https://login.microsoftonline.com/${process.env.NEXT_PUBLIC_AZURE_AD_TENENT_ID}`,
      redirectUri:`${process.env.NEXT_PUBLIC_AZURE_AD_REDIRECT_URI}`
    },
  };

  msalInstance = new Msal.UserAgentApplication(msalConfig);
}

export { msalInstance };
