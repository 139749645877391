import { DeviceTypeBasedOnWidth } from '@/constants'
import { useEffect, useState } from 'react'

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false)
  const [isTouchDevice, setIsTouchDevice] = useState(false)
  const [deviceTypeBasedOnWidth, setDeviceTypeBasedOnWidth] = useState(DeviceTypeBasedOnWidth)

  const determineDeviceType = () => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    const IOS =
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

    const mobileDevice = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPadPro|iPod|Opera Mini|IEMobile|WPDesktop/i))

    const Surface = window.matchMedia('screen and (width: 912px) and (height: 1368px)').matches
    const widthForMobile = window.matchMedia('screen and (max-width: 1024px)').matches

    const mobile = (mobileDevice && widthForMobile) || (IOS && widthForMobile) || Surface
    setMobile(mobile)
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia('(pointer: coarse)').matches)
    setDeviceTypeBasedOnWidth({
      mobile: window.matchMedia('screen and (max-width: 767px)').matches,
      tablet: window.matchMedia('screen and (min-width: 768px) and (max-width: 1024px)').matches,
      desktop: window.matchMedia('screen and (min-width: 1025px)').matches,
    })
  }

  useEffect(() => {
    determineDeviceType()
    window.addEventListener('resize', determineDeviceType)

    return () => {
      window.removeEventListener('resize', determineDeviceType)
    }
  }, [])

  return { isMobile, deviceTypeBasedOnWidth, isTouchDevice }
}
