const Loader = (props) => (
  <svg width='109.523' height='109.524' viewBox='0 0 109.523 109.524' {...props}>
    <g id='Group_471' data-name='Group 471' transform='translate(-4625 -2380)'>
      <path
        id='Path_126'
        data-name='Path 126'
        d='M538.571,431.694a44.027,44.027,0,0,1,0-88.055V332.9a54.762,54.762,0,1,0,54.762,54.762H582.6A44.028,44.028,0,0,1,538.571,431.694Z'
        transform='translate(4141.19 2047.095)'
        fill='#cd2e31'
      />
      <path
        id='Path_127'
        data-name='Path 127'
        d='M538.571,332.9v10.734A44.028,44.028,0,0,1,582.6,387.667h10.734A54.761,54.761,0,0,0,538.571,332.9Z'
        transform='translate(4141.19 2047.095)'
        fill='#cd2e31'
      />
    </g>
  </svg>
)

export default Loader
