import { useQuery, useMutation } from '@tanstack/react-query'
import { useCallback, useRef, useState } from 'react'
import {
  getLinkedAccounts,
  getLinkedAccountsKey,
} from '../../api-client/profile/get-linked-accounts'
import { switchAccount } from '../../api-client/profile/switch-account'
import { navigationEvent } from '../../utils/ga-events/gtm-events-common'
import LinkAnchor from '../../components/button/link-anchor'
import Button from '../../components/button/button'
import SearchIcon from '../../components/svg/search-icon'
import Paging from '../../components/paging'
import useDebouncedCallback from '../../hooks/use-debounced-callback'
import useClickOutside from '../../hooks/use-clickoutside'
import { useTranslation } from 'react-i18next'
import { DesktopTablet, Mobile } from '../../components/media'
import Loader from '../../components/loader'

const pageSize = 8

export default function SwitchAccount({ onClose }) {
  const { t } = useTranslation('header')

  const [searchBy, setSearchByValue] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [skip, setSkip] = useState(0)
  const [searchBySelect, setSearchBySelect] = useState(t('Wca.SearchBy'))
  const searchDropdownref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [searchWith, setSearchWith] = useState('')
  const [isLoading, setIsloading] = useState(false)

  const { mutate: setAccount } = useMutation(switchAccount, {
    onSuccess: (data) => {
      if (data) {
        if (data.Switched) {
          window.location.reload()
        }
        onClose()
      } else {
      }
    },
  })

  let dataRows = useState([])

  const { refetch: reFetchAccounts, isLoading: isAccountsLoading } = useQuery({
    queryKey: [getLinkedAccountsKey],
    queryFn: () => getLinkedAccounts({ searchBy, searchWith, pageSize, skip }),
    onSuccess: (d) => {
      loadAccounts(d)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  function loadAccounts(d) {
    dataRows.length = 0
    d.linkedCustomers.map((recs) => {
      const num = recs?.CustomerNumber.trim()
      const custNum = num.length < 5 ? String(num).padStart(5, '0') : num
      return dataRows.push({
        CustomerNumber: (
          <LinkAnchor
            href=''
            disabled={true}
            className='customerNumber'
            onClick={() => handleAccountClick(recs?.Id)}
          >
            {custNum}
          </LinkAnchor>
        ),
        CustomerName: recs.CustomerName,
        AddressLine2: recs.AddressLine2,
        AddressLine1: recs.AddressLine1,
        CityProvince: recs.CityProvince,
      })
    })
    setRecords(dataRows)
    setTotalPages(d.total ? Math.ceil(d.total / pageSize) : 1)
    setIsloading(false)
  }

  const handleAccountClick = useCallback(
    (selectedAccount) => {
      setAccount({
        selectedAccount: selectedAccount,
      })
      navigationEvent('Switch Account', 'Header')
    },
    [setAccount],
  )

  const [records, setRecords] = useState(dataRows)

  const onPageChangeDebounced = useDebouncedCallback(reFetchAccounts, 500, [reFetchAccounts])

  const handlePageChange = useCallback(
    (page) => {
      setIsloading(true)
      setPageIndex(page)
      setSkip(page * pageSize)
      onPageChangeDebounced({
        searchBy,
        searchWith,
        pageSize,
        skip,
      })
    },
    [onPageChangeDebounced, searchBy, searchWith, skip],
  )

  function handleInputChange(event) {
    setSearchWith(event.target.value)
  }

  const handleSearch = (event) => {
    event.preventDefault()
    reFetchAccounts()
  }

  const handleClear = (event) => {
    event.preventDefault()
    setSearchWith('')
    setSearchByValue('')
    reFetchAccounts()
  }

  const handleDropDown = (event) => {
    event.preventDefault()
    setIsOpen((isOpen) => !isOpen)
  }
  const handleSearchByChange = (event) => {
    event.preventDefault()
    //Set the the search by and search criteria
    setSearchBySelect(event.target.innerHTML)

    setSearchByValue(event.target.id)
  }

  const handleDropDownCallBack = useCallback(() => {
    setIsOpen((isOpen) => !isOpen)
  }, [setIsOpen])

  useClickOutside(searchDropdownref, handleDropDownCallBack, !isOpen)

  return (
    <>
      <div className='wrapper'>
        <h4 className='title'>{t('wca.switchaccount')}</h4>
        <div className='account-search flex'>
          <div className='custom-search-box'>
            <div className='dropdown' ref={searchDropdownref} onClick={handleDropDown}>
              <button
                type='button'
                id='swa-searchBy'
                className='btn btn-default dropdown-toggle'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                {searchBySelect} <span className='caret'></span>
              </button>
              {isOpen && (
                <ul className='dropdown-menu'>
                  <li className='swa-drp-search'>
                    <a href='#' id='accountNumber' onClick={handleSearchByChange}>
                      {t('Wca.AccountNumber')}
                    </a>
                  </li>
                  <li className='swa-drp-search'>
                    <a href='#' id='companyName' onClick={handleSearchByChange}>
                      {t('Wca.CompanyName')}
                    </a>
                  </li>
                </ul>
              )}
            </div>
            {/* </div> */}
            <label htmlFor='searchWith'><span style={{display:'none'}}>search</span></label>
            <input
              className='search-textbox'
              id='searchWith'
              name='searchWith'
              type='text'
              placeholder=''
              value={searchWith}
              onChange={handleInputChange}
            />
            <div className='search-icon flex alignCenter' onClick={handleSearch}>
              <SearchIcon />
            </div>
          </div>
          <div className='custom-search-btns flex'>
            <Button className='searchBtn' onClick={handleSearch}>
              {t('wca.search')}
            </Button>{' '}
            &nbsp;
            <Button className='clearBtn' color='secondary' onClick={handleClear}>
              {t('Wca.Clear')}
            </Button>
          </div>
        </div>

        <table className='grid'>
          <thead>
            <tr className='accountHeaderTr'>
              <th className='customer-number-col'>
                <label>
                  <DesktopTablet>{t('Wca.AccountNumber')}</DesktopTablet>
                  {/* {deviceType.isMobile && ( */}
                  <Mobile>{t('Wca.AccountNumberMobile')}</Mobile>
                  {/* )} */}
                </label>
              </th>
              <th className='customer-name-col'>
                <label>
                  <DesktopTablet>{t('Wca.CompanyName')}</DesktopTablet>
                  <Mobile>{t('Wca.CompanyNameMobile')}</Mobile>
                </label>
              </th>
              <th className='address-col'>
                <label>{t('Wca.Address')}</label>
              </th>
              <th className='city-col'>
                <label>{t('Wca.City')}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isAccountsLoading &&
              !!records?.length &&
              records?.map((account, i) => {
                return (
                  <tr key={`profile_${i}`}>
                    <td className='customer-number'>{account?.CustomerNumber}</td>
                    <td className='customer-name'>{account?.CustomerName}</td>
                    <td className='address'>
                      {!!account?.AddressLine1 ? account?.AddressLine1 : account?.AddressLine2}
                    </td>
                    <td className='city'>{account?.CityProvince}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        {totalPages > 1 && (
          <Paging
            onFirst={() => setPageIndex(0)}
            onLast={() => setPageIndex(totalPages - 1)}
            onPrevious={() => handlePageChange(pageIndex - 1)}
            onNext={() => handlePageChange(pageIndex + 1)}
            onChangePageNumber={handlePageChange}
            pageIndex={pageIndex}
            totalPages={totalPages}
            skip={skip}
            pageSize={pageSize}
          />
        )}

        {(isAccountsLoading || isLoading) && (
          <div className='loader-backdrop-popup'>
            <Loader delay={300} width='50' height='50' />
          </div>
        )}
      </div>
      <style jsx>{`
        .accountHeaderTr:hover {
          background-color: unset !important;
          color: unset !important;
        }
        .customer-number-col label,
        .customer-name-col label,
        .address-col label,
        .city-col label {
          font-size: 1.3rem;
          letter-spacing: 0.025rem;
        }
        :global(.content) {
          overflow: hidden !important;
        }
        :global(.modal:has(h4):has(.title)) {
          top: 3rem !important;
          transform: translate(-50%, 0) !important;
          left: 50% !important;
          .close {
            right: 4rem;
          }
        }
        .modal {
          width: 60%;
        }
        .search-textbox {
          height: 4.2rem;
        }
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 4px dashed;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
        .dropdown-menu {
          position: fixed;
          z-index: 1000;
          display: block;
          min-width: 160px;
          padding: 5px 0;
          margin: 43px 0 0;
          font-size: 14px;
          text-align: left;
          list-style: none;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ccc;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          li > a {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: 400;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;
          }
        }

        .title {
          font-size: 2.4rem;
          font-weight: bold;
          color: var(--wolseleyGrey);
          padding-bottom: 3rem;
        }
        .grid {
          width: 100%;
          border-spacing: 0;
          border-bottom: 2px solid #ccc;
          font-size: 1.4rem;

          tr {
            th {
              font-weight: 600;
              border-bottom: 2px solid #ccc;
              label {
                text-transform: uppercase;
              }
            }
            td {
              border-top: 1px dashed #ccc;

              :global(.customerNumber) {
                color: var(--wolseleyRed);
                text-decoration: none;
              }
            }
            td,
            th {
              text-align: left;
              color: var(--wolseleyGrey);
              padding: 1.5rem 1rem;
            }
            .customer-name,
            .address,
            .city {
              width: 30%;
              word-wrap: break-word;
            }
            .customer-number {
              width: 20%;
              word-wrap: break-word;
            }
          }
          tr:hover {
            background: #f7f7f7;
          }
        }

        .account-search {
          gap: 10px;
          padding-bottom: 8px;
        }
        .custom-search-box {
          display: flex;
          font-size: 1.5rem;
          border-radius: 6px;
          border: 1px solid lightgray;
          position: relative;
          width: 75%;
          height: 4.2rem !important;
          :global(Button) {
            min-width: 12rem;
            padding: 4px;
            color: #333;
            background-color: #fff;
            border-radius: 6px 0 0 6px;
            height: 100%;
          }
        }
        .custom-search-btns {
          width: 25%;
          :global(Button) {
            min-width: 10rem;
            height: 4.2rem;
          }
          .clearBtn {
            background-color: var(--wolseleyGrey);
          }
        }
        :global(.customerNumber) {
          color: var(--wolseleyRed);
        }
        .search-dropdown {
          text-align: center;
        }

        input {
          width: 100%;
          padding: 0px 36px 0px 12px;
          height: 100%;
          background: #fff;
        }
        .search-icon {
          padding: 1rem;
          cursor: pointer;
          border-left: 1px solid lightgray;
          background-color: var(--primary-button-bg);
          border-radius: 0 6px 6px 0;
          height: 4.2rem;
        }

        .dropdown {
          position: relative;
          top: 0;
          min-width: 11rem;
          max-width: 14rem;
          width: inherit;
          height: 4.2rem;
          background-color: white;
          border-right: 1px solid lightgray;
          border-left: 1px solid transparent;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          border-radius: 6px 0 0 6px;
          background-color: #fff;
          z-index: 500;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow: hidden;
          &.hide {
            display: none;
          }
        }
        .dropdown:hover {
          background-color: #e6e6e6;
          border: 1px solid var(--wolseleyRed);
        }
        .dropdown:focus,
        .dropdown:active {
          background-color: #d4d4d4;
          border: 1px solid var(--wolseleyRed);
        }
        #swa-searchBy,
        .dropdown-toggle {
          font-size: 1.4rem;
          background-color: white;
          width: 100%;
        }
        .dropdown-toggle:hover {
          background-color: #e6e6e6;
        }
        .dropdown-toggle:focus,
        .dropdown-toggle:active {
          background-color: #d4d4d4;
        }
        input:hover {
          border: 1px solid var(--wolseleyRed);
        }
        .dropdown-menu > li:hover {
          background-color: #f5f5f5;
        }
        .dropdown-menu > li > a:hover {
          text-decoration: none;
        }
        .loader-backdrop-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        @media (max-width: 768px) {
          :global(.modal:has(h4):has(.title)) {
            width: 100% !important;
          }
          .wrapper {
            max-width: 30rem;
          }
          .title {
            padding-bottom: 1.5rem;
          }
          .custom-search-btns {
            display: none;
          }
          .custom-search-box {
            width: 90%;
          }
          .dropdown,
          .dropdown-toggle {
            min-width: 11rem !important;
          }
          .grid {
            max-width: 20rem !important;
            width: 100% !important;
            td,
            th {
              width: 15% !important;
              padding: 0.25rem !important;
              font-size: 1.2rem !important;
              text-overflow: ellipsis !important;
            }
            .address-col,
            .address {
              display: none;
            }
          }
        }
      `}</style>
    </>
  )
}
